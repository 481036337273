import React from 'react'
import Catalogo from '../components/Catalogo/Catalogo'

const Catalogoview = () => {
  return (
    <div  style={{display: 'flex', flexDirection: 'column'}}>
      <header className="c-header w-100 pb-0 position-fixed">
            <div className="container position-relative">
                <nav className="c-nav d-flex align-items-center justify-content-between w-100">
                    <div className="c-nav__left d-flex align-items-center h-100">
                        <figure className="c-brand m-0">
                            <a href="/home" aria-current="page" className="c-brand__link">
                                <img src="https://storange-images.s3.amazonaws.com/img/storangelogo_white.webp" alt="" className='h-100' />
                            </a>
                        </figure>
                    </div>
                    <div className="c-nav__right">
                        <button className='c-button c-button--burguer opacity-0'>
                            <span data-icon="mingcute:menu-fill" className='iconify'></span>
                        </button>
                    </div>
                </nav>
            </div>
          </header>
      <Catalogo />
    </div>
  )
}

export default Catalogoview