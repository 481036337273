// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faUser, faMapLocationDot, faMapPin, faLocationDot, faTrash } from '@fortawesome/free-solid-svg-icons'
// import React, { useState } from 'react'
// import { Button, TextField } from '@mui/material'
// import { makeStyles, withStyles } from '@mui/styles'
// import './PerfilContainer.css'
// import { useContext } from 'react'
// import { useEffect } from 'react'
// import { ToastContainer } from 'react-toastify'
// import PropietarioContext from '../../context/PropietarioContext'
// import { GlobalContext } from '../../context/GlobalStateContext';
// import { useNavigate } from 'react-router-dom';


// const CssTextField = withStyles({
//   root: {
//     '& p': {
//       textAlign: 'left',
//       fontSize: '12px',
//       color: '#F94700 !important'
//     },
//     '& label': {
//       fontSize: '16px'
//     },
//     '& label.Mui-focused': {
//       color: '#F94700 !important',
//     },
//     '& .MuiInput-underline:before': {
//       borderBottom: '1px solid #000 !important'
//     },
//     '& .MuiInput-underline:after': {
//       borderBottom: '1px solid #F94700 !important',
//     },
//     '& .MuiOutlinedInput-root': {
//       '& fieldset': {
//         borderColor: 'black'
//       },
//       '&:hover fieldset': {
//         borderColor: 'black'
//       },
//       '&.Mui-focused fieldset': {
//         borderColor: '#F94700 !important'
//       },
//     },
//   },
// })(TextField);

// const useStyles = makeStyles({
//   button: {
//     backgroundColor: '#f94700 !important',
//     textTransform: 'none !important',
//     fontWeight: 'bold !important',
//     color: '#fff !important',
//     width: '200px !important',
//     margin: '20px 0 !important',
//     height: 'fit-content !important'
//   },
//   buttonDireccion: {
//     backgroundColor: '#f94700 !important',
//     textTransform: 'none !important',
//     fontWeight: 'bold !important',
//     color: '#fff !important',
//     width: '100% !important',
//     margin: '20px 0 !important',
//     height: 'fit-content !important'
//   }
// })

// const regex = /^(?=\w*\d)(?=\w*[a-z])(?=\w*[a-z])\S{8,16}$/

// const PerfilContainer = () => {

//   const classes = useStyles();

//   const { buscarDireccion, buscarPropietario, propietario, handleChange, name, nameAction, apellido, apellidoAction, email, emailAction, phone, phoneAction, actualizarInfo, verificarContrasena, pass, newPass, newPassAction, newPassConfirm, setActiveDireccionModal, direcciones, borrarDireccion, res } = useContext(PropietarioContext);
//   const { restartAll } = useContext(GlobalContext)
//   const navigate = useNavigate()
//   const [section, setSection] = useState(1)

//   useEffect(() => {
//     buscarPropietario()
//     buscarDireccion()
//   }, [])


//   return (
//     <div className='perfilContainer'>
//       <div className='menuPerfil'>
//         <p>Configuración de la cuenta</p>
//         <div className={section === 1 ? 'menuItem menuItemActive' : 'menuItem'} onClick={() => setSection(1)}>
//           <FontAwesomeIcon className='menuIcon' icon={faUser} />
//           Información personal privada
//         </div>
//         <div className={section === 2 ? 'menuItem menuItemActive' : 'menuItem'} onClick={() => setSection(2)}>
//           <FontAwesomeIcon className='menuIcon' icon={faMapLocationDot} />
//           Direcciones
//         </div>
//         <div onClick={() =>{
//           window.scrollTo(0, 0)
//           setTimeout(() => {
//               navigate('/')
//               restartAll()
//           }, 500)
//       }} className="menuItem">
//           <FontAwesomeIcon className='menuIcon' icon={faMapLocationDot} />
//           Cerrar Sesión
//         </div>
//       </div>
//       {section === 1 ? (
//         <div className='menuInfo'>
//           <div className='menuArea'>
//             <div className='menuHeader'>
//               <h2>Información Personal Privada</h2>
//             </div>
//             {propietario !== null ? (
//               <div className='menuInputs'>
//                 <CssTextField
//                   label="Nombres"
//                   variant="outlined"
//                   name="name"
//                   onChange={handleChange}
//                   onBlur={handleChange}
//                   error={name === '' && nameAction}
//                   helperText={name === '' && nameAction ? 'Nombre requerido' : ' '}
//                   value={propietario.nombre && !nameAction ? propietario.nombre : name}
//                   style={{ marginBottom: '16px' }}
//                 />
//                 <CssTextField
//                   label="Apellidos"
//                   variant="outlined"
//                   name="apellido"
//                   onChange={handleChange}
//                   onBlur={handleChange}
//                   error={apellido === '' && apellidoAction}
//                   helperText={apellido === '' && apellidoAction ? 'Apellidos requeridos' : ' '}
//                   value={propietario.apellido && !apellidoAction ? propietario.apellido : apellido}
//                   style={{ marginBottom: '16px' }}
//                 />
//                 <CssTextField
//                   label="Email"
//                   variant="outlined"
//                   name="email"
//                   onChange={handleChange}
//                   onBlur={handleChange}
//                   error={email === '' && emailAction}
//                   helperText={email === '' && emailAction ? 'Email requerido' : ' '}
//                   value={propietario.email && !emailAction ? propietario.email : email}
//                   style={{ marginBottom: '16px' }}
//                 />
//                 <CssTextField
//                   label="Teléfono"
//                   variant="outlined"
//                   name="phone"
//                   onChange={handleChange}
//                   onBlur={handleChange}
//                   error={phone === '' && phoneAction}
//                   helperText={phone === '' && phoneAction ? 'Teléfono requerido' : ' '}
//                   value={propietario.telefono && !phoneAction ? propietario.telefono : phone}
//                   style={{ marginBottom: '16px' }}
//                 />
//               </div>
//             ) : (
//               <h1>Cargando...</h1>
//             )}
//             <div className='menuFooter'>
//               <Button
//                 className={classes.button}
//                 onClick={actualizarInfo}
//               >Guardar</Button>
//             </div>
//           </div>
//           <div className='menuArea'>
//             <div className='menuHeader'>
//               <h2>Cambiar Contraseña</h2>
//             </div>
//             <div className='menuInputs'>
//               <CssTextField
//                 type={'password'}
//                 label="Contraseña actual"
//                 variant="outlined"
//                 name="pass"
//                 onChange={handleChange}
//                 onBlur={handleChange}
//                 helperText={' '}
//                 value={pass}
//                 style={{ marginBottom: '16px' }}
//               />
//               <CssTextField
//                 type={'password'}
//                 label="Nueva contraseña"
//                 variant="outlined"
//                 name="newPass"
//                 onChange={handleChange}
//                 onBlur={handleChange}
//                 error={regex.test(newPass) === false && newPassAction}
//                 helperText={regex.test(newPass) === false && newPassAction ? 'De 8 a 16 carácteres entre números y letras' : ' '}
//                 value={newPass}
//                 style={{ marginBottom: '16px' }}
//               />
//               <CssTextField
//                 type={'password'}
//                 label="Confirmar contraseña"
//                 variant="outlined"
//                 name="newPassConfirm"
//                 onChange={handleChange}
//                 onBlur={handleChange}
//                 error={newPassConfirm !== newPass}
//                 helperText={newPassConfirm !== newPass ? 'Las contraseñas no coinciden' : ' '}
//                 value={newPassConfirm}
//                 style={{ marginBottom: '16px' }}
//               />
//             </div>
//             <div className='menuFooter'>
//               <Button
//                 onClick={verificarContrasena}
//                 className={classes.button}>Guardar</Button>
//             </div>
//           </div>
//         </div>
//       ) : (
//         <div className='menuDireccion'>
//           <div className='menuArea'>
//             <div className="menuHeader">
//               <h2>Direcciones</h2>
//             </div>
//             <p>Agrega o escoge una dirección como principal</p>
//             <Button
//               onClick={() => {
//                 document.body.style.overflowY = "hidden"
//                 setActiveDireccionModal(true)
//               }}
//               className={classes.buttonDireccion}
//             >
//               <FontAwesomeIcon style={{ marginRight: '10px' }} icon={faLocationDot} />
//               Agregar nueva dirección
//             </Button>
//             {direcciones !== null ? (
//               direcciones.map((dir, i) => {
//                 return (
//                   <div key={`direccion${i}`} className='direccionItem'>
//                     <div>
//                       <FontAwesomeIcon style={{ marginRight: '30px', fontSize: '25px' }} icon={faMapPin} />
//                       <p>{dir.direccion}</p>
//                     </div>
//                     <FontAwesomeIcon
//                       style={{ marginLeft: '30px', fontSize: '18px', color: 'red', cursor: 'pointer' }}
//                       icon={faTrash}
//                       onClick={() => borrarDireccion(dir.idDireccion)} />
//                   </div>
//                 )
//               })
//             ) : (
//               <h1>Cargando</h1>
//             )}
//           </div>
//         </div>
//       )}
//       <ToastContainer
//         position="bottom-right"
//         autoClose={1000}
//         hideProgressBar
//         theme='light'
//         newestOnTop={false}
//         closeOnClick
//         rtl={false}
//         pauseOnFocusLoss
//         draggable
//         pauseOnHover />
//     </div>
//   )
// }

// export default PerfilContainer

import { useState, useEffect, useContext } from "react"
import { GlobalContext } from "../../context/GlobalStateContext";
import env from "react-dotenv";
import PropietarioContext from '../../context/PropietarioContext';
import { getAdressList, deleteAdress, getLastBills, updatePassword, updateUser } from "../../servicios/item";
import { useNavigate } from 'react-router-dom';
import AddAddressForm from "../AddAddressForm/AddAddressForm";
import Dialog from '@mui/material/Dialog';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';


const PerfilContainer = () => {

  const { accessToken, restartAll } = useContext(GlobalContext)
  const { propietario } = useContext(PropietarioContext)
  const navigate = useNavigate()

  const [loaded, setLoaded] = useState(false);
  const [loadedPassword, setLoadedPassword] = useState(false);
  const [activeSection, setActiveSection] = useState('information')
  const [formData, setFormData] = useState({
    names: '',
    lastname: '',
    email: '',
    phone: '',
  })
  const [errorFormData, setErrorFormData] = useState({
    names: '',
    lastname: '',
    email: '',
    phone: ''
  })
  const [formPassword, setFormPassword] = useState({
    actualPassword: '',
    newPassword: '',
    confirm: '',
  })
  const [errorFormPassword, setErrorFormPassword] = useState({
    actualPassword: '',
    newPassword: '',
    confirm: '',
  })

  const [addressList, setAddressList] = useState([])
  const [addressModal, setAddressModal ] = useState(false)
  const [newAddressData, setNewAddressData] = useState({
    address: '',
    reference: '',
    coordinates: {
      lat: '',
      lng: ''
    }
  })

  const [itemToDelete, setItemToDelete] = useState('')
  const [confirmDeleteAddressModal, setConfirmDeleteAddressModal] = useState(false)
  const [billsList, setbillsList] = useState([
    {
      "idTicket": 823,
      "periodo": "Febrero 2024",
      "fecha": "2024-02-01T05:00:00.000Z",
      "link": "",
      "estado": "Pendiente",
      "monto_almacenamiento": 1279.6,
      "monto_prorrateo": 500,
      "monto_servicios_adicionales": 10
    },
    
  ])
  const [expanded, setExpanded] = useState(false);
  const [responseModal, setResponseModal ] = useState(false)
  const [responseDataModal, setResponseDataModal ] = useState({
    type: '',
    title: '',
    message: ''
  })

  const handleGetAdressList = async () => {
    try {
      const data = await getAdressList(propietario?.idPropietario, accessToken);
      setAddressList(data)
      if(newAddressData.address) {
        // setAddressObject(data.find(objeto => objeto.direccion === newAddressData.address))
        setNewAddressData({
          address: '',
          reference: '',
          coordinates: {
            lat: '',
            lng: ''
          }
        })
      }
    } catch (error) {
      console.error("Error handling data:", error);
    }
  }
  const handleGetLastBills = async () => {
    try {
      const data = await getLastBills(propietario?.idPropietario, accessToken);
      setbillsList(data)
    } catch (error) {
      console.error("Error handling data:", error);
    }
  }

  const handleDeleteAdress = async () => {
    try {
      const data = await deleteAdress(accessToken, itemToDelete.idDireccion);
      setAddressList(addressList.filter(address => address.idDireccion!== itemToDelete.idDireccion));
      setConfirmDeleteAddressModal(false)
      setItemToDelete('')
    } catch (error) {
      console.error("Error handling data:", error);
    }
  }

  const handleFormChange = (e) => {
    const { name, type, checked, value } = e.target;

    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value
    });
  }
  
  const handleFormPasswordChange = (e) => {
    const { name, type, checked, value } = e.target;

    setFormPassword({
      ...formPassword,
      [name]: type === 'checkbox' ? checked : value
    });
  }

  const validateForm = (types) => {
    const errors = {};
    setLoaded(true)
  
    types.forEach(type => {
      switch(type) {
        case 'names':
          if(formData.names === '') {
            errors.names = 'El campo es requerido';
          } else {
            errors.names = ''
          }
          break;
        case 'lastname':
          if(formData.lastname === '') {
            errors.lastname = 'El campo es requerido';
          } else {
            errors.lastname = ''
          }
          break;
        case 'email':
          if(formData.email === '') {
            errors.email = 'El campo es requerido';
          } else {
            errors.email = ''
          }
          break;
        case 'phone':
          if(formData.phone === '') {
            errors.phone = 'El campo es requerido';
          } else {
            errors.phone = ''
          }
          break;
        default:
      }
    });
  
    setErrorFormData(prevErrors => ({
      ...prevErrors,
      ...errors
    }));

    const errorsVal = Object.values(errors).every(valor => valor === '');
    if(errorsVal) {
      return true
    } else {
      // showSection('formSection', false)
      return false
    }
  };

  const validateFormPassword = (types) => {
    const errors = {};
    setLoadedPassword(true)
  
    types.forEach(type => {
      switch(type) {
        case 'actualPassword':
          if(formPassword.actualPassword === '') {
            errors.actualPassword = 'El campo es requerido';
          } else {
            errors.actualPassword = ''
          }
          break;
        case 'newPassword':
          if(formPassword.newPassword === '') {
            errors.newPassword = 'El campo es requerido';
          } else {
            errors.newPassword = ''
          }
          break;
        case 'confirm':
          if(formPassword.confirm === '') {
            errors.confirm = 'El campo es requerido';
          } else if(formPassword.confirm !== '' && formPassword.confirm !== formPassword.newPassword) {
            errors.confirm = 'La contraseña no coincide';
          } else {
            errors.confirm = ''
          }
          break;
        default:
      }
    });
  
    setErrorFormPassword(prevErrors => ({
      ...prevErrors,
      ...errors
    }));

    const errorsVal = Object.values(errors).every(valor => valor === '');
    if(errorsVal) {
      return true
    } else {
      // showSection('formSection', false)
      return false
    }
  };

  const toggleResponseDataModal = (type, title, message) => {
    setResponseDataModal({
      type: type,
      title: title,
      message: message
    });
  }

  const handleForm = async () => {
    const isValidate = validateForm(['names', 'lastname', 'email', 'phone']);
    if(isValidate) {
      try {
        const data = await updateUser(accessToken, propietario?.idPropietario, 
          formData.names, 
          formData.lastname,
          formData.email,
          formData.phone, 
        );
        if(data === 'Se actualizo correctamente') {
          toggleResponseDataModal('success', 'Datos actualizados', 'Sus datos fue actualizada correctamente')
          setResponseModal(true)
        } else {
          toggleResponseDataModal('error', 'Error', 'Inténtelo otra vez')
          setResponseModal(true)
        }
        
      } catch (error) {
        console.error("Error handling data:", error);
        toggleResponseDataModal('error', 'Error', error)
        setResponseModal(true)
      }
    }
  }

  const handleFormPassword = async () => {
    const isValidate = validateFormPassword(['actualPassword', ' newPassword', 'confirm']);
    if(isValidate) {
      try {
        const data = await updatePassword(accessToken, propietario?.idPropietario,formPassword.actualPassword, formPassword.newPassword, formPassword.confirm );
        if(data === 'Se actualizo correctamente') {
          toggleResponseDataModal('success', 'Contraseña actualizada', 'Su contraseña fue actualizada correctamente')
          setResponseModal(true)
          clearPasswordForm()
        } else {
          toggleResponseDataModal('error', 'Error', data)
          setResponseModal(true)
        }
        
      } catch (error) {
        console.error("Error handling data:", error);
        toggleResponseDataModal('error', 'Error', error)
        setResponseModal(true)
      }
    }
  }

  const clearPasswordForm =  () => {
    setFormPassword({
      actualPassword: '',
      newPassword: '',
      confirm: '',
    })

    setErrorFormPassword({
      actualPassword: '',
      newPassword: '',
      confirm: '',
    })
  }

  const confirmDeleteAddress = (adress) => {
    setItemToDelete(adress)
    setConfirmDeleteAddressModal(true)
    // open modal 
  }

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const PieChart = ({ monto_almacenamiento, monto_prorrateo, monto_servicios_adicionales, url }) => {
    const total = monto_almacenamiento + monto_prorrateo + monto_servicios_adicionales;
    const almacenamientoPercent = (monto_almacenamiento / total) * 100;
    const prorrateoPercent = (monto_prorrateo / total) * 100;
    const serviciosAdicionalesPercent = (monto_servicios_adicionales / total) * 100;
  
    return (
      <div className="c-bill-detail d-flex align-items-center justify-content-center">
        <div className="c-bill-detail__grafic">
          <div className="c-pie" 
            style={{ 
              backgroundImage: `conic-gradient(
                #9e2399 ${almacenamientoPercent}%, 
                #006ccd ${almacenamientoPercent}%, 
                #006ccd ${almacenamientoPercent + prorrateoPercent}%, 
                #00921b ${almacenamientoPercent + prorrateoPercent}%)`
            }}>
          </div>
          </div>
          <div className="c-bill-detail__legend">
              <div></div>
              <ul className="c-bill-detail__list m-0 p-0 w-100">
                <li className="d-flex justify-content-between align-items-center">
                  <div className="c-bill-detail__label d-flex align-items-center gap-1">
                    <span style={{background : '#9e2399'}}></span> Almacenamiento
                  </div>
                  <div>
                    {almacenamientoPercent.toFixed(2)}%
                  </div>
                </li>
                <li className="d-flex justify-content-between align-items-center">
                  <div className="c-bill-detail__label d-flex align-items-center gap-1">
                    <span style={{background : '#006ccd'}}></span> Prorrateo
                  </div>
                  <div>
                    {prorrateoPercent.toFixed(2)}%
                  </div>
                </li>
                <li className="d-flex justify-content-between align-items-center">
                  <div className="c-bill-detail__label d-flex align-items-center gap-1">
                    <span style={{background : '#00921b'}}></span> Servicios adicionales
                  </div>
                  <div>
                    {serviciosAdicionalesPercent.toFixed(2)}%
                  </div>
                </li>
              </ul>
              {url ? (              
                  <div className="text-center">
                    <a href={`${env.API_URL}/${url}`} download className={`c-button c-button--primary-ghost ${url ? 'disabled' : ''}`}>
                      Descargar Factura
                    </a>
                  </div>) : (
                    <div className="text-center c-disabled-download">
                      <a className="c-button c-button--primary-ghost">
                        Descargar Factura
                      </a>
                      <span className=" mt-2">La factura no cuenta con descargaba habilitada</span>
                    </div>
                  )
                }
          </div>
        </div>

    );
  };

  useEffect(() => {
    handleGetAdressList()
    handleGetLastBills()
    if(propietario) {
      setFormData({
        names: propietario.nombre,
        lastname: propietario.apellido,
        email: propietario.email,
        phone: propietario.telefono,
      })
    }
  }, [propietario])

  useEffect(() => {
    const toValidate = Object.keys(formData).filter(key => formData[key] !== '')
      validateForm(toValidate)
  }, [loaded, formData])

  useEffect(() => {
    const toValidate = Object.keys(formPassword).filter(key => formPassword[key] !== '')
      validateFormPassword(toValidate)
  }, [loadedPassword, formPassword])

  useEffect(()=>{
    if(!addressModal) {
      handleGetAdressList();
    }
  }, [addressModal])

  return(
    <div className='c-perfil d-lg-flex align-items-start'>
      <div className="c-perfil-sidebar">

        <ul className="c-list m-0 p-0">
          <li onClick={() => setActiveSection('information')} 
            className={`c-item ${activeSection === 'information' ? 'active' : ''}`}>
            <span data-icon="mdi:user" className='iconify'></span>
            Información personal
          </li>
          <li onClick={() => setActiveSection('address')} 
          className={`c-item ${activeSection === 'address' ? 'active' : ''}`}>
            <span data-icon="entypo:address" className='iconify'></span>
            Direcciones
          </li>
          <li onClick={() => setActiveSection('billing')} 
          className={`c-item ${activeSection === 'billing' ? 'active' : ''}`}>
            <span data-icon="mingcute:card-pay-fill" className='iconify'></span>
            Facturación
          </li>
          <li onClick={() =>{
            window.scrollTo(0, 0)
              setTimeout(() => {
                  navigate('/')
                  restartAll()
              }, 500)
          }} 
          className={`c-item`}>
            <span data-icon="fa:sign-out" className='iconify'></span>
            Cerrar Sesión
          </li>
        </ul>
      </div>
      <div className="c-perfil-contain">
        {activeSection === 'information' && 
          <div>
            <div className='c-form c-form--perfil w-100 mb-4'>
              <h3 className="c-form__title mb-3">
                Información Personal
              </h3>
                <div className='row gy-3'>
                  <div className="col-12 col-lg-6">
                    <div className={`c-input-group ${errorFormData.names ? 'error' : ''}`}>
                      <label>Nombre</label>
                      <input
                        name="names"
                        value={formData.names} 
                        onChange={handleFormChange}
                        onBlur={handleFormChange}
                        type="text"  
                        placeholder='Nombre'/>
                      <span className='c-error'>{errorFormData.names}</span>
                    </div>
                  </div>
                  <div className="col-12 col-lg-6">
                    <div className={`c-input-group ${errorFormData.lastname ? 'error' : ''}`}>
                      <label>Apellidos</label>
                      <input
                        name="lastname"
                        value={formData.lastname} 
                        onChange={handleFormChange}
                        onBlur={handleFormChange}
                        type="text"  
                        placeholder='Apellido'/>
                      <span className='c-error'>{errorFormData.lastname}</span>
                    </div>
                  </div>
                  <div className="col-12 col-lg-6">
                    <div className={`c-input-group ${errorFormData.email ? 'error' : ''}`}>
                      <label>Email</label>
                      <input
                        name="email"
                        value={formData.email} 
                        onChange={handleFormChange}
                        onBlur={handleFormChange}
                        type="text"  
                        placeholder='Email'/>
                      <span className='c-error'>{errorFormData.email}</span>
                    </div>
                  </div>
                  <div className="col-12 col-lg-6">
                    <div className={`c-input-group ${errorFormData.phone ? 'error' : ''}`}>
                      <label>Teléfono</label>
                      <input
                        name="phone"
                        value={formData.phone} 
                        onChange={handleFormChange}
                        onBlur={handleFormChange}
                        type="text"  
                        placeholder='Teléfono'/>
                      <span className='c-error'>{errorFormData.phone}</span>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="text-end mt-3">
                      <button onClick={()=> handleForm()} className="c-button c-button--primary">
                        Editar
                      </button>
                    </div>
                  </div>
                </div>
            </div>
            <div className='c-form c-form--perfil w-100'>
              <h3 className="c-form__title mb-3">
                Actualizar contraseña
              </h3>
                <div className='row gy-3'>
                  <div className="col-12 col-lg-6">
                    <div className={`c-input-group ${errorFormPassword.actualPassword ? 'error' : ''}`}>
                      <label>Contraseña Actual</label>
                      <input
                        name="actualPassword"
                        value={formPassword.actualPassword} 
                        onChange={handleFormPasswordChange}
                        onBlur={handleFormPasswordChange}
                        type="password"  
                        placeholder='Contraseña actual'/>
                      <span className='c-error'>{errorFormPassword.actualPassword}</span>
                    </div>
                  </div>
                  <div className="col-12 col-lg-6">
                    <div className={`c-input-group ${errorFormPassword.newPassword ? 'error' : ''}`}>
                      <label>Nueva contraseña</label>
                      <input
                        name="newPassword"
                        value={formPassword.newPassword} 
                        onChange={handleFormPasswordChange}
                        onBlur={handleFormPasswordChange}
                        type="password"  
                        placeholder='Nueva contraseña'/>
                      <span className='c-error'>{errorFormPassword.newPassword}</span>
                    </div>
                  </div>
                  <div className="col-12 col-lg-6">
                    <div className={`c-input-group ${errorFormPassword.confirm ? 'error' : ''}`}>
                      <label>Confirmar nueva contraseña</label>
                      <input
                        name="confirm"
                        value={formPassword.confirm} 
                        onChange={handleFormPasswordChange}
                        onBlur={handleFormPasswordChange}
                        type="password"  
                        placeholder='Confirmar nueva contraseña'/>
                      <span className='c-error'>{errorFormPassword.confirm}</span>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="text-end mt-3">
                      <button onClick={()=> handleFormPassword()} className="c-button c-button--primary">
                        Editar
                      </button>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        }
        {activeSection === 'address' && 
          <div className="c-perfil-contain__address">
            <h3 className="c-perfil-contain__title mb-3">
              Direcciones
            </h3>
            <ul className="c-adress-list d-flex flex-column p-0 m-0">
            {addressList.map((state) => (
              <li  key={state.idDireccion} className="w-100 d-flex justify-content-between align-items-center">
                <div className="c-adress-list__label">
                  <span data-icon="entypo:address" className='iconify'></span>
                  <span className="px-2">{state.direccion}</span>
                </div>
                <button onClick={()=> confirmDeleteAddress(state)} className="c-adress-list__delete-button">
                  <span data-icon="mdi:trash" className='iconify'></span>
                </button>
              </li>
            ))}
            </ul>
            <div className="mt-4 text-end">
              <button onClick={() => setAddressModal(true)} className="c-button c-button--primary-ghost">
                Agregar nueva dirección
              </button>
            </div>
          </div>}
        {activeSection === 'billing' && 
          <div className="c-perfil-contain__address">
          <h3 className="c-perfil-contain__title mb-3">
            Últimas facturas
          </h3>
          <ul className="c-adress-list d-flex flex-column p-0 m-0">
          {billsList.map((state) => (
            <li key={state.idTicket} className="w-100 d-flex justify-content-start align-items-center">
              <Accordion expanded={expanded === state.id} onChange={handleChange(state.id)}>
                <AccordionSummary
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    className='p-0 m-0'
                    >
                    <div className="c-adress-list__label">
                      <span data-icon="ri:bill-fill" className='iconify'></span>
                      <span className="px-2">{state.periodo}</span>
                    </div>
                </AccordionSummary>
                <AccordionDetails className='p-0'>
                  <PieChart 
                      monto_almacenamiento={state.monto_almacenamiento}
                      monto_prorrateo={state.monto_prorrateo}
                      monto_servicios_adicionales={state.monto_servicios_adicionales}
                      url={state.link}
                    />
                </AccordionDetails>
            </Accordion>
            </li>
          ))}
          </ul>
        </div>}
      </div>

      <Dialog
        open={addressModal}
        onClose={() => setAddressModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      > 

        <AddAddressForm setAddressModal={setAddressModal} setNewAddressDataPerfil={setNewAddressData} />
      </Dialog>

      <Dialog
      open={confirmDeleteAddressModal}
      onClose={() => setConfirmDeleteAddressModal(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <div className='c-modal c-modal--alert'>
        <div className='c-modal__content'>
          <div className='c-modal__icon c-modal__icon--alert'>
            <span data-icon="mingcute:alert-fill" className='iconify'></span>
          </div>
          <div className='c-modal__title mb-3'>
            ¿Está seguro que desea eliminar la dirección?
          </div>
          <p className='c-modal__description'>
            La direccion: <strong>"{itemToDelete.direccion}"</strong> sera eliminada de forma permanente
          </p>
        </div>
        <div className='c-modal__footer d-flex align-items-center justify-content-end'>
          <button className='c-button c-button--white-gray me-3' onClick={()=> setConfirmDeleteAddressModal(false)} >Cerrar</button>
          <button className='c-button c-button--primary' onClick={()=> handleDeleteAdress()} >Eliminar</button>
        </div>
      </div>
    </Dialog>
    <Dialog
      open={responseModal}
      onClose={() => setResponseModal(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <div className='c-modal c-modal--alert'>
        <div className='c-modal__content'>

        {responseDataModal.type === 'success' ? (
          <div className='c-modal__icon c-modal__icon--success'>
          <span data-icon="ep:success-filled" className='iconify'></span>
        </div>
        ) : (
          <div className='c-modal__icon c-modal__icon--alert'>
            <span data-icon="mingcute:alert-fill" className='iconify'></span>
          </div>
        )}
          
          <div className='c-modal__title mb-3'>
            {responseDataModal.title}
          </div>
          <p className='c-modal__description'>
            {responseDataModal.message}
          </p>
        </div>
        <div className='c-modal__footer d-flex align-items-center justify-content-end'>
          <button className='c-button c-button--white-gray' onClick={() => setResponseModal(false)} >Cerrar</button>
        </div>
      </div>
    </Dialog>
    </div>
  )
}
export default PerfilContainer