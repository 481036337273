import React, { createContext, useState } from "react"
import navigatorDetector from "../tools/NavigatorDetector"

export const GlobalContext = createContext()

const GlobalStateContext = ({ children }) => {

  const [browser, setBrowser] = useState(navigatorDetector)
  const [webkitBrowser, setWebkitBrowser] = useState('Safari')

  const [isLoading, setIsLoading] = useState(false)
  const [loaderState, setLoaderState] = useState(1)
  const [change, setChange] = useState(true)
  const [activeModal, setActiveModal] = useState(false)
  const [activeDireccionModal, setActiveDireccionModal] = useState(false)
  const [page, setPage] = useState(1)
  const [filtersDisplayed, setFiltersDisplayed] = useState(false)
  const [swalCambio, setSwalCambio] = useState(true)

  const [idPropietario, setIdPropietario] = useState(null || localStorage.getItem('trustedUser'))
  const [accessToken, setAccessToken] = useState(null || localStorage.getItem('t'))
  const [userIsTrusted, setUserIsTrusted] = useState(null)

  const [activeFilters, setActiveFilters] = useState([])
  const [filteredArticles, setFilteredArticles] = useState([])
  const [ids, setIds] = useState([])

  const [filter, setFilter] = useState('')
  const [cajaFilter, setCajaFilter] = useState(false)
  const [sueltoFilter, setSueltoFilter] = useState(false)

  const [formEnvioPage, setFormEnvioPage] = useState(2)
  const [direccionName, setDireccionName] = useState(null)

  const restartAll = () => {
    localStorage.removeItem('trustedUser')
    localStorage.removeItem('t')

    setIsLoading(false)
    setLoaderState(1)
    setChange(true)
    setActiveModal(false)
    setActiveDireccionModal(false)

    setIdPropietario(null || localStorage.getItem('trustedUser'))
    setAccessToken(null || localStorage.getItem('trustedUser'))
    setUserIsTrusted(null)

    setActiveFilters([])
    setFilteredArticles([])
    setIds([])

    setFilter('')
    setCajaFilter(false)
    setSueltoFilter(false)

    setFormEnvioPage(1)
  }

  const formatStrings = (str) =>{
    return str
    .replaceAll('Ã±','ñ')
    .replaceAll('Ã¡','á')
    .replaceAll('Ã','í')
    .replaceAll('í³','ó')
  }

  const encodePetition = (parameters, formBody = []) => {
    for (let property in parameters) {
      let encodedKey = encodeURIComponent(property)
      let encodedValue = encodeURIComponent(parameters[property])
      formBody.push(encodedKey + "=" + encodedValue)
    }
    return formBody.join("&")
  }

  return (
    <GlobalContext.Provider
      value={{
        isLoading, setIsLoading,
        loaderState, setLoaderState,
        change, setChange,
        userIsTrusted, setUserIsTrusted,
        encodePetition,
        idPropietario, setIdPropietario, accessToken, setAccessToken,
        filteredArticles, setFilteredArticles,
        filter, setFilter,
        cajaFilter, setCajaFilter,
        sueltoFilter, setSueltoFilter,
        activeFilters, setActiveFilters,
        formEnvioPage, setFormEnvioPage,
        activeModal, setActiveModal,
        activeDireccionModal, setActiveDireccionModal,
        ids, setIds,
        restartAll,
        page, setPage,
        filtersDisplayed, setFiltersDisplayed,
        swalCambio, setSwalCambio,
        direccionName, setDireccionName,
        formatStrings,
        browser, webkitBrowser,
        setBrowser,
        setWebkitBrowser,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalStateContext;