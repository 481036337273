import React from 'react'
import CarritoEliminar from '../components/CarritoEliminar/CarritoElimnar'

const CarritoEliminarView = () => {
  return (
    <div>
      <CarritoEliminar />
    </div>
  )
}

export default CarritoEliminarView