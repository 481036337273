import React, { createContext, useContext, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from "react-toastify"
import Swal from 'sweetalert2'
import env from "react-dotenv";
import { GlobalContext } from "./GlobalStateContext";

const ArticleContext = createContext('');

const ArticleProvider = ({ children, props }) => {
  const navigate = useNavigate()
  const { idPropietario, setLoaderState, accessToken, encodePetition, change, setChange } = useContext(GlobalContext)

  const [articulo, setArticulo] = useState(null)

  const [fotos, setFotos] = useState(null)
  const [editTitle, setEditTitle] = useState(false)
  const [editDescription, setEditDescription] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [articulos, setArticulos] = useState(null)
  const [filteredArticles, setFilteredArticles] = useState([])
  const [carrito, setCarrito] = useState(null)
  const [activeFilters, setActiveFilters] = useState([])
  const [filter, setFilter] = useState('')
  const [page, setPage] = useState(1)

  const [newTitle, setNewTitle] = useState('')
  const [newDescription, setNewDescription] = useState('')

  let { id } = useParams()

  const repeatPriceSwal = () => {
    Swal.fire({
      title: 'Precio',
      text: 'Indique el precio de venta',
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: 'Vender',
      cancelButtonText: 'Cancelar',
      footer: '<p style="color: red; font-weight: bold">Ingrese un precio válido!</p>',
    }).then((result) => {
      if (result.isConfirmed === true && !isNaN(result.value) && parseFloat(result.value) > 0 && result.value.length > 0) {
        ventaArticulo(id, result.value)
      } else if (result.isConfirmed === true && (isNaN(result.value) || parseFloat(result.value) <= 0 || result.value.length === 0)) {
        repeatPriceSwal()
      }
    })
  }

  const repeatPoliciesSwal = () => {
    Swal.fire({
      title: '¿Está seguro que desea poner en venta el siguiente artículo?',
      text: 'Lea las políticas de venta en Storange y marque el checkbox a continuación',
      icon: 'warning',
      input: 'checkbox',
      inputValue: 0,
      inputPlaceholder: 'He leído las <a href="https://www.siasa.com/mails/politicasdeventas.pdf" target="_blank" style="color: #F94700">Políticas de Venta</a> y quiero vender mi artículo',
      showCancelButton: true,
      confirmButtonText: 'Siguiente',
      cancelButtonText: 'Cancelar',
      footer: '<p style="color: red; font-weight: bold">Acepte el acuerdo y marque el checkbox</p>',
    }).then((result) => {
      if (result.value === 1 && result.isConfirmed === true) {
        Swal.fire({
          title: 'Precio',
          text: 'Indique el precio de venta',
          input: 'text',
          showCancelButton: true,
          confirmButtonText: 'Vender',
          cancelButtonText: 'Cancelar',
        }).then((result) => {
          if (result.isConfirmed === true && !isNaN(result.value) && parseFloat(result.value) > 0 && result.value.length > 0) {
            ventaArticulo(id, result.value)
          } else if (result.isConfirmed === true && (isNaN(result.value) || parseFloat(result.value) <= 0 || result.value.length === 0)) {
            repeatPriceSwal()
          }
        })
      } else if (result.value === 0 && result.isConfirmed === true) {
        repeatPoliciesSwal()
      }
    })
  }

  const handleCancelSell = () => {
    Swal.fire({
      title: '¿Esta seguro(a) que desea cancelar la venta?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sí, cancelalo!',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        cancelarVenta(id)
      }
    })
  }

  const handleSell = () => {
    Swal.fire({
      title: '¿Está seguro que desea poner en venta el siguiente artículo?',
      text: 'Lea las políticas de venta en Storange y marque el checkbox a continuación',
      icon: 'warning',
      input: 'checkbox',
      inputValue: 0,
      inputPlaceholder: 'He leído las <a href="https://www.siasa.com/mails/politicasdeventas.pdf" target="_blank" style="color: #F94700">Políticas de Venta</a> y quiero vender mi artículo',
      showCancelButton: true,
      confirmButtonText: 'Siguiente',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.value === 1 && result.isConfirmed === true) {
        Swal.fire({
          title: 'Precio',
          text: 'Indique el precio de venta',
          input: 'text',
          inputAttributes: {
            autocapitalize: 'off'
          },
          showCancelButton: true,
          confirmButtonText: 'Vender',
          cancelButtonText: 'Cancelar',
        }).then((result) => {
          if (result.isConfirmed === true && !isNaN(result.value) && parseFloat(result.value) > 0 && result.value.length > 0) {
            ventaArticulo(id, result.value)
          } else if (result.isConfirmed === true && (isNaN(result.value) || parseFloat(result.value) <= 0 || result.value.length === 0)) {
            repeatPriceSwal()
          }
        })
      } else if (result.value === 0 && result.isConfirmed === true) {
        repeatPoliciesSwal()
      }
    })
  }

  const handleDelete = () => {
    Swal.fire({
      title: '¿Esta seguro(a) que desea eliminar el artículo?',
      text: "Este cambio no se puede revertir",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sí, borralo!',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        eliminarArticulo(id, navigate)
      }
    })
  }
  
  const repeatChangePriceSwal = () => {
    Swal.fire({
      title: 'Cambio de precio',
      text: 'Indique el nuevo precio de venta',
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: 'Cambiar',
      cancelButtonText: 'Cancelar',
      footer: '<p style="color: red; font-weight: bold">Ingrese un precio válido!</p>',
    }).then((result) => {
      if (result.isConfirmed === true && !isNaN(result.value) && parseFloat(result.value) > 0 && result.value.length > 0) {
        cambiarPrecio(id, result.value)
      } else if (result.isConfirmed === true && (isNaN(result.value) || parseFloat(result.value) <= 0 || result.value.length === 0)) {
        repeatChangePriceSwal()
      }
    })
  }

  const handlePriceChange = () => {
    Swal.fire({
      title: 'Cambio de precio',
      text: 'Indique el nuevo precio de venta',
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: 'Cambiar',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed === true && !isNaN(result.value) && parseFloat(result.value) > 0 && result.value.length > 0) {
        cambiarPrecio(id, result.value)
      } else if (result.isConfirmed === true && (isNaN(result.value) || parseFloat(result.value) <= 0 || result.value.length === 0)) {
        repeatChangePriceSwal()
      }
    })
  }
  
  const obtenerArticulo = (idArticulo) => {
    setArticulo(null)
    setFotos(null)
    let details = { idArticulo }

    fetch(env.API_URL+"/fotos", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        "Authorization": "Bearer "+accessToken
      },
      body: encodePetition(details)
    })
      .then((res) => res.json())
      .then((res) => {
        setFotos(res)
      })

    fetch(env.API_URL+"/articuloPorId", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        "Authorization": "Bearer "+accessToken
      },
      body: encodePetition(details)
    })
      .then((res) => res.json())
      .then((res) => {
        setArticulo(res)
      })
  }

  const buscarArticulos = (showToast, navigate) => {
    showToast = showToast || null
    let details = { idPropietario, filter }
    // fetch(env.API_URL+"/articulovirtual", {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    //     "Authorization": "Bearer "+accessToken
    //   },
    //   body: encodePetition(details)
    // })
    //   .then((res) => res.json())
    //   .then((res) => {
    //     setIsLoading(false)
    //     setArticulos(res)
    //     setFilteredArticles(res)
    //     if(!filter) {
    //       setCarrito(res.filter(art => {
    //         return art.estadoEnvio === 1 && art.idEstadoArticulo !== 3
    //       }))
    //     }
    //     if (showToast === 1) toast("Se agregó al carrito de envío")
    //     if (showToast === 2) toast("Articulo retirado del carrito de envío")
    //   })
    //   .catch(() =>{
    //     Swal.fire({
    //       title: 'No autorizado',
    //       text: 'No tiene permiso para realizar esta operacion',
    //       icon: 'alert'
    //     })
    //   })
    //   .finally(() => setIsLoading(false))
  }

  const actualizarEstadoEnvio = (estadoEnvio, idArticulo) => {
    let details = { estadoEnvio, idArticulo }

    setIsLoading(true)

    fetch(env.API_URL+"/actualizarEstadoEnvio", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        "Authorization": "Bearer "+accessToken
      },
      body: encodePetition(details)
    })
      .then(() => {
        setArticulo(
          {
            ...articulo,
            estadoEnvio
          }
        )
      }).finally(()=> {
        estadoEnvio === 1
          ? buscarArticulos(1)
          : buscarArticulos(2)
      })
  }
  
  const actualizarTitulo = (titulo, setEditTitle) => {
    let details = { titulo, idArticulo: articulo.idArticulo }

    setIsLoading(true)

    fetch(env.API_URL+"/actualizarTitulo", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        "Authorization": "Bearer "+accessToken
      },
      body: encodePetition(details)
    })
      .then(() => {
        setIsLoading(false)
        setArticulo({ ...articulo, Titulo: titulo })
        setEditTitle(false)
      })
  }
  
  const actualizarDescripcion = (descripcion, setEditDescription) => {
    let details = { descripcion, idArticulo: articulo.idArticulo }

    setIsLoading(true)

    fetch(env.API_URL+"/actualizarDescripcion", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        "Authorization": "Bearer "+accessToken
      },
      body: encodePetition(details)
    })
      .then(() => {
        setIsLoading(false)
        setArticulo(
          descripcion === ''
            ? { ...articulo, descripcionPropietario: null }
            : { ...articulo, descripcionPropietario: descripcion })
        setEditDescription(false)
      })
  }

  
  const handleFilters = () => {
    setPage(1)
    if (activeFilters.length === 0) {
      setFilteredArticles(articulos)
    } else if (
      (activeFilters.indexOf('caja') !== -1 ||
        activeFilters.indexOf('suelto') !== -1) === false &&
      (activeFilters.indexOf(1) !== -1 ||
        activeFilters.indexOf(2) !== -1 ||
        activeFilters.indexOf(3) !== -1 ||
        activeFilters.indexOf(5) !== -1) === true
    ) {
      setFilteredArticles(articulos.filter(el => activeFilters.indexOf(el.idEstadoArticulo) !== -1))
    } else if (
      (activeFilters.indexOf('caja') !== -1 ||
        activeFilters.indexOf('suelto') !== -1) === true &&
      (activeFilters.indexOf(1) !== -1 ||
        activeFilters.indexOf(2) !== -1 ||
        activeFilters.indexOf(3) !== -1 ||
        activeFilters.indexOf(5) !== -1) === false
    ) {
      setFilteredArticles(articulos.filter(el => activeFilters.indexOf(el.tipoArticulo.toLowerCase()) !== -1))
    } else {
      setFilteredArticles(articulos.filter(el => {
        return activeFilters.indexOf(el.idEstadoArticulo) !== -1 && activeFilters.indexOf(el.tipoArticulo.toLowerCase()) !== -1
      }))
    }
  }

  const eliminarArticulo = (id, navigate) =>{
    let details = {
      idArticulo: id
    }
    setIsLoading(true)
    fetch(env.API_URL+"/eliminarArticulo", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        "Authorization": "Bearer "+accessToken
      },
      body: encodePetition(details)
    })
    .then(() => {
      toast("El artículo se borro de la lista")
      buscarArticulos()
      setIsLoading(false)
      window.scrollTo(0, 0)
      setChange(!change)
      setLoaderState(0)
      setTimeout(() => navigate('/dashboard'), 500)
    })
  }

  const ventaArticulo = (id, precio) => {
    let details = {
      idArticulo: id,
      precio
    }
    setIsLoading(true)
    fetch(env.API_URL+"/venderArticulo", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        "Authorization": "Bearer "+accessToken
      },
      body: encodePetition(details)
    })
    .then(() => {
      toast("El artículo se puso en venta")
      obtenerArticulo(id)
      setIsLoading(false)
    })
  }

  const cancelarVenta = (id) => {
    let details = {
      idArticulo: id
    }
    setIsLoading(true)
    fetch(env.API_URL+"/cancelarVenta", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        "Authorization": "Bearer "+accessToken
      },
      body: encodePetition(details)
    })
    .then(() => {
      toast("Se canceló la venta")
      obtenerArticulo(id)
      setIsLoading(false)
    })
  }

  const cambiarPrecio = (id, precio) => {
    let details = {
      idArticulo: id,
      precio
    }
    setIsLoading(true)
    fetch(env.API_URL+"/actualizarPrecio", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        "Authorization": "Bearer "+accessToken
      },
      body: encodePetition(details)
    })
    .then(() => {
      toast("Se actualizó el precio de venta")
      obtenerArticulo(id)
      setIsLoading(false)
    })
  }

  const object = {
    id,
    articulo,
    buscarArticulos,
    obtenerArticulo,
    handleCancelSell,
    handleDelete,
    handlePriceChange,
    handleSell,
    change,
    setChange,
    setLoaderState,
    fotos,
    editTitle,
    setEditTitle,
    editDescription,
    setEditDescription,
    newTitle,
    setNewTitle,
    newDescription,
    setNewDescription,
    actualizarTitulo,
    actualizarDescripcion,
    setIsLoading,
    isLoading,
    actualizarEstadoEnvio,
    articulos,
    filteredArticles,
    carrito,
    filter,
    setFilter,
    activeFilters,
    setActiveFilters,
    handleFilters,
    page,
    setPage,
    cambiarPrecio, cancelarVenta, ventaArticulo, eliminarArticulo
  };


  return (
      <ArticleContext.Provider value={object}>{children}</ArticleContext.Provider>
  );
};

export { ArticleProvider };
export default ArticleContext;
