import React from 'react'
import { Outlet } from 'react-router-dom'
import CarritoModal from './CarritoModal/CarritoModal'
import DireccionModal from './DireccionModal/DireccionModal'
import Loader from './Loader/Loader'
import Navbar from './Navbar/Navbar'
import SecondaryNavbar from './SecondaryNavbar/SecondaryNavbar'
import { PropietarioProvider } from '../context/PropietarioContext'
import { ArticleProvider } from '../context/ArticleContext'
import { OrdenProvider } from '../context/OrdenContext'

const Layout = () => {

  return (
    <div style={{display: 'flex', flexDirection: 'column'}}>
      <PropietarioProvider>
        <ArticleProvider>
          <OrdenProvider>
            <Navbar/>
            <Loader/>
            <CarritoModal/>
            <DireccionModal/>
            <Outlet/>
            <SecondaryNavbar/>
          </OrdenProvider>
        </ArticleProvider>
      </PropietarioProvider>
    </div>
  )
}

export default Layout