import React from 'react'

const HomeView = () => {

  return (
    <div className='dashboardView'>
      <div className='c-home'>
        <div className='container'>
          <div className='row gy-3 justify-content-center'>
            <div className='col-12'>
              <h3 className='c-home__title'>
                ¿Qué quieres hacer hoy?
              </h3>
            </div>
            <div className='col-12 col-md-6 col-lg-4'>
              <a href="/envio" className='c-card-link c-welcome d-block'>
                <div className='c-card-link__icon'>
                  <span data-icon='mynaui:send' className='iconify'></span>
                </div>
                <div className='c-card-link__title'>
                  Carrito de Envio 
                </div>
              </a>
            </div>
            <div className='col-12 col-md-6 col-lg-4'>
              <a href="/eliminar" className='c-card-link c-welcome d-block'>
                <div className='c-card-link__icon'>
                  <span data-icon='mdi:trash-outline' className='iconify'></span>
                </div>
                <div className='c-card-link__title'>
                  Carrito de Eliminacion
                </div>
              </a>
            </div>
            <div className='col-12 col-md-6 col-lg-4'>
              <a href="/recojo" className='c-card-link c-welcome d-block'>
                <div className='c-card-link__icon'>
                  <span data-icon="hugeicons:pickup-02" className='iconify'></span>
                </div>
                <div className='c-card-link__title'>
                  Orden de Recojo
                </div>
              </a>
            </div>
            <div className='col-12 col-md-6 col-lg-4'>
              <a href="/preguntas-frecuentes" className='c-card-link c-welcome d-block'>
                <div className='c-card-link__icon'>
                  <span data-icon="mingcute:question-line" className='iconify'></span>
                </div>
                <div className='c-card-link__title'>
                  Preguntas Frecuentes
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HomeView 