import React from 'react'
import Layout from '../components/Layout'
import ArticleView from '../views/ArticleView'
import DashboardView from '../views/DashboardView'
import LoginView from '../views/LoginView'
import RecogerEnviarView from '../views/RecogerEnviarView'
import RecoveryView from '../views/RecoveryView'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import ScrollToTop from '../tools/ScrollToTop'
import PerfilContainer from '../components/PerfilContainer/PerfilContainer'
import GarageSaleView from '../views/GarageSaleView'
import Catalogoview from '../views/CatalogoView'
import CarritoEnvioView from '../views/CarritoEnvio'
import CarritoEliminarView from '../views/CarritoEliminarView'
import CarritoSuccessView from '../views/CarritoSuccessView'
import OrderRecojoView from '../views/OrdenRecojoView'
import FaqView from '../views/FaqView'
import HomeView from '../views/HomeView'

const Rutas = () => {
  return (
    <BrowserRouter>
      <ScrollToTop>
        <Routes>
            <Route path='/' element={<LoginView/>}/>
            <Route path='/recovery' element={<RecoveryView/>}/>
            <Route path='/dashboard' element={<Layout/>}>
                <Route index element={<DashboardView/>}/>
                <Route path='/dashboard/item/:id' element={<ArticleView/>}/>
            </Route>
            <Route path='/home' element={<Layout/>}>
              <Route index element={<HomeView/>}/>
            </Route>
            <Route path='/pickup_send' element={<Layout/>}>
              <Route index element={<RecogerEnviarView/>}/>
            </Route>
            <Route path='/perfil' element={<Layout/>}>
              <Route index element={<PerfilContainer/>}/>
            </Route>
            <Route path='/garage-sale' element={<Layout/>}>
              <Route index element={<GarageSaleView/>}/>
            </Route>
            <Route path="/garage-sale/:codigo/catalogo">
              <Route index element={<Catalogoview/>}/>
            </Route>
            <Route path="/envio" element={<Layout/>}>
              <Route index element={<CarritoEnvioView/>}/>
            </Route>
            <Route path="/eliminar" element={<Layout/>}>
              <Route index element={<CarritoEliminarView/>}/>
            </Route>
            <Route path="/envio/:id/success" element={<Layout/>}>
              <Route index element={<CarritoSuccessView/>}/>
            </Route>
            <Route path="/eliminar/:id/success" element={<Layout/>}>
              <Route index element={<CarritoSuccessView/>}/>
            </Route>
            <Route path="/recojo" element={<Layout/>}>
              <Route index element={<OrderRecojoView/>}/>
            </Route>
            <Route path="/preguntas-frecuentes" element={<Layout/>}>
              <Route index element={<FaqView/>}/>
            </Route>
        </Routes>
      </ScrollToTop>
    </BrowserRouter>
  )
}

export default Rutas