import React from 'react'
import RecoveryContainer from '../components/InitialScreen/RecoveryContainer/RecoveryContainer'
import { RecoveryProvider } from '../context/RecoveryContext'

const RecoveryView = () => {
  return (
    <div className='recoveryView'>
        <RecoveryProvider>
          <RecoveryContainer/>
        </RecoveryProvider>
    </div>
  )
}

export default RecoveryView