import React, {useState} from 'react'
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';

const FaqView = () => {
  const [saveExpanded, setSaveExpanded] = useState(false);
  const [deleteExpanded, setDeleteExpanded] = useState(false);
  const [getBackExpanded, setGetBackExpanded] = useState(false);

  const handleSaveChange = (panel) => (event, isExpanded) => {
    setSaveExpanded(isExpanded ? panel : false);
  };

  const handleDeleteChange = (panel) => (event, isExpanded) => {
    setDeleteExpanded(isExpanded ? panel : false);
  };

  const handleGetBackChange = (panel) => (event, isExpanded) => {
    setGetBackExpanded(isExpanded ? panel : false);
  };

  const saveQuestions = [
    {
      question: '¿Cómo solicito que recojan mis cosas?',
      answers: 'Solo nos llamas o nos escribes y programaremos la recogida.'
    },
    {
      question: '¿Me cobran por recoger mis cosas?',
      answers: 'No cobramos por recoger. Recuerda que nuestro servicio de recogida incluye tanto el transporte, personal, proceso de recepción y hacerte un catálogo online de tus artículos.La razón por la que no cobramos por recoger es que entendemos que lo que necesitas inicialmente es hacer más espacio así que evitamos cualquier fricción en el servicio.'
    },
    {
      question: '¿Qué significa un artículo?',
      answers: 'Para nosotros un artículo es un paquete que ingresa y sale completo. Un artículo también puede ser un conjunto de objetos. Por ejemplo, si tienes un kit paellero, compuesto por la base, la paellera y los accesorios, ese conjunto podría ser un artículo llamado kit paellero. Lo mismo sucede con las cajas Storange, todos los objetos que estén dentro de la caja hacen un conjunto con el artículo caja.'
    },
    {
      question: '¿Si tengo un artículo con varios objetos, podrían enviarme algunos objetos?',
      answers: 'No podemos. El artículo ingresa y sale completo. Por eso es muy importante que decidas previamente que objetos componen el artículo. Lo que obviamente si se puede hacer, es enviarte el conjunto y que tú retires los que necesites y luego nos vuelvas a pedir que recojamos lo que sobró.'
    },
    {
      question: '¿Qué significa que el almacenamiento es bajo demanda?',
      answers: 'Significa que solo cobramos por la suma de los m3 de los artículos que tienes almacenados en un periodo de tiempo (1 mes). En la fecha de corte, hacemos un cálculo de los m3 y esa es la factura que tendrás que pagar, independientemente de si dentro del periodo de tiempo retiras o vendes o eliminas algunos artículos. Siempre tendrás a tu disposición  un pre cálculo una semana antes de la fecha de corte para que puedas decidir qué hacer antes de tu fecha de corte.'
    },
    {
      question: '¿Cómo se calculan los m3?',
      answers: 'Los m3 de cada artículo es la multiplicación del ancho, largo y altura. La medida mínima de almacenamiento es de 0.10 m3 por lo tanto si tú artículo tiene menos que eso, consideraremos 0.10 como vólumen de tu artículo. La sumatoria del vólumen de tus artículos serán los m3 que mantienes almacenados con nosotros.'
    },
    {
      question: '¿Cuánto tiempo después de que recogen mis cosas tengo mi catálogo online?',
      answers: 'Inmediatamente después de que hayamos recogido tus cosas, tus artículos ingresan en nuestro proceso de recepción, una vez que se termina con todos los artículos de esa recogida se suben a tu catálogo. Normalmente no nos demoramos más de 48 horas en subir todos tus artículos pero puede ser menos. Te notificaremos una vez que hemos completado el proceso. '
    }
  ]
  const deleteQuestions = [
    {
      question: '¿Qué pasa con el artículo si doy en eliminar?',
      answers: 'Si das en eliminar, automáticamente tu artículo desaparece de tu inventario y obviamente dejas de pagar por el almacenamiento. En ese caso nuestro equipo decidirá si el artículo puede ser vendido, restaurado o reciclado.'
    },
    {
      question: '¿Puedo arrepentirme de eliminar?',
      answers: 'Lamentablemente no se puede. Aunque resulte tedioso siempre utilizamos la confirmación de dos pasos para que no te equivoques pero si eliminaste un artículo, no se podrá revertir la operación de forma automática porque hay la posibilidad de que ya hayamos tomado decisiones con respecto a el. Sin embargo si lo eliminaste por error, comunícate con nuestra área de atención al cliente (951612957) para ver si podemos ayudarte.'
    },
  ]
  const getBackQuestions = [
    {
      question: '¿Cómo hago para pedir algunos artículos de vuelta?',
      answers: 'Es muy sencillo, solo ingresas a Tu Zona, seleccionas los artículos que quieres que te enviemos, colocas la dirección y listo, programaremos el envio de tus artículos.'
    },
    {
      question: '¿Pedir que me envíen mis artículos tiene algún costo?',
      answers: 'Si. El costo del envío cubre los costos de retiro del almacén, transporte y entrega de tus artículos. Recuerda que si el artículo se vende estos costos los asume el comprador. '
    },
    {
      question: '¿Pueden enviarme los artículos a una dirección diferente de donde la recogieron?',
      answers: 'Por supuesto, siempre y cuando se encuentre dentro de nuestras zona de cobertura y sea en la misma ciudad donde recogimos. '
    },
  ]
  return (
    <div>
      <div className='c-faq'>
        <div className='container'>
          <div className='mb-4'>
            <h4 className='c-faq__subtitle'>
              Encuentra respuestas a las preguntas más comunes sobre nuestros productos y servicios
            </h4>
            <h2 className='c-faq__title'>
              Preguntas frecuentes
            </h2>
          </div>
          <div className='mb-4'>
            <h3 className='c-faq__sectionTitle'>Guardar un artículo</h3>
            <ul className="c-accordion-list d-flex flex-column p-0 m-0">
              {
                saveQuestions.map((item,i)=> (
                  <li key={`question-${i}`} className="w-100 d-flex justify-content-start align-items-center mb-2">
                    <Accordion expanded={saveExpanded === i} onChange={handleSaveChange(i)}>
                      <AccordionSummary
                          aria-controls="panel1bh-content"
                          id="panel1bh-header"
                          className='p-0 m-0'
                          >
                          <div className={`c-accordion-list__label d-flex justify-content-between align-items-center ${saveExpanded === i ? 'active' : ''}`}>
                            <div>
                              <span data-icon="mingcute:question-fill" className='iconify'></span>
                              <span className="px-2">{item.question}</span>
                            </div>
                            <span data-icon="fluent:chevron-right-12-filled" className='iconify c-accordion-list__arrow'></span>
                          </div>
                      </AccordionSummary>
                        <AccordionDetails className='p-0'>
                          <div className="p-3">
                            {item.answers}
                          </div>
                        </AccordionDetails>
                    </Accordion>
                  </li>
                ))

              }
            </ul>
          </div>
          <div className='mb-4'>
            <h3 className='c-faq__sectionTitle'>Eliminar un artículo</h3>
            <ul className="c-accordion-list d-flex flex-column p-0 m-0">
              {
                deleteQuestions.map((item,i)=> (
                  <li key={`del-question-${i}`} className="w-100 d-flex justify-content-start align-items-center mb-2">
                    <Accordion expanded={deleteExpanded === i} onChange={handleDeleteChange(i)}>
                      <AccordionSummary
                          aria-controls="panel1bh-content"
                          id="panel1bh-header"
                          className='p-0 m-0'
                          >
                          <div className={`c-accordion-list__label d-flex justify-content-between align-items-center ${deleteExpanded === i ? 'active' : ''}`}>
                            <div>
                              <span data-icon="mingcute:question-fill" className='iconify'></span>
                              <span className="px-2">{item.question}</span>
                            </div>
                            <span data-icon="fluent:chevron-right-12-filled" className='iconify c-accordion-list__arrow'></span>
                          </div>
                      </AccordionSummary>
                        <AccordionDetails className='p-0'>
                          <div className="p-3">
                            {item.answers}
                          </div>
                        </AccordionDetails>
                    </Accordion>
                  </li>
                ))

              }
            </ul>
          </div>
          <div className='mb-4'>
            <h3 className='c-faq__sectionTitle'>Pedir de vuelta un artículo</h3>
            <ul className="c-accordion-list d-flex flex-column p-0 m-0">
              {
                getBackQuestions.map((item,i)=> (
                  <li key={`getback-question-${i}`} className="w-100 d-flex justify-content-start align-items-center mb-2">
                    <Accordion expanded={getBackExpanded === i} onChange={handleGetBackChange(i)}>
                      <AccordionSummary
                          aria-controls="panel1bh-content"
                          id="panel1bh-header"
                          className='p-0 m-0'
                          >
                          <div className={`c-accordion-list__label d-flex justify-content-between align-items-center ${getBackExpanded === i ? 'active' : ''}`}>
                            <div>
                              <span data-icon="mingcute:question-fill" className='iconify'></span>
                              <span className="px-2">{item.question}</span>
                            </div>
                            <span data-icon="fluent:chevron-right-12-filled" className='iconify c-accordion-list__arrow'></span>
                          </div>
                      </AccordionSummary>
                        <AccordionDetails className='p-0'>
                          <div className="p-3">
                            {item.answers}
                          </div>
                        </AccordionDetails>
                    </Accordion>
                  </li>
                ))

              }
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FaqView