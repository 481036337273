import React from 'react'
import GarageSale from '../components/GarageSale/GarageSale'

const GarageSaleView = () => {
  return (
    <div>
      <GarageSale />
    </div>
  )
}

export default GarageSaleView