import React, { useContext, useEffect, useState } from 'react'
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import Drawer from '@mui/material/Drawer';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTruckFast, faCirclePlus, faFaceLaugh } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom';
import { GlobalContext } from '../../context/GlobalStateContext';
import './Navbar.css'
import PropietarioContext from '../../context/PropietarioContext';
import ArticleContext from '../../context/ArticleContext';
import OrdenContext from '../../context/OrdenContext';

const Navbar = () => {

    const { propietario, buscarPropietario } = useContext(PropietarioContext)
    const { buscarArticulos, carrito } = useContext(ArticleContext)
    const { setOe, oe, setDireccionSelect } = useContext(OrdenContext)
    const { setChange, change, setLoaderState, restartAll, setFormEnvioPage, formatStrings } = useContext(GlobalContext)
    const navigate = useNavigate()

    const pages = [
        {text: 'Recoger/Enviar', path:'/pickup_send'},
        {text: 'Mis articulos', path:'/dashboard'},
        {text: 'Perfil', path:'/perfil'}
    ]

    const [anchorElNav, setAnchorElNav] = useState(null);
    const [anchorElUser, setAnchorElUser] = useState(null);
    const [innerWidth, setInnderWidth] = useState(window.innerWidth)

    const [open, setOpen] = React.useState(false);
    const [expanded, setExpanded] = React.useState(false);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget)
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget)
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null)
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null)
    };

    const handleInnerWidth = () => setInnderWidth(window.innerWidth)

    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
    };

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    useEffect(() => {
        buscarPropietario()
        buscarArticulos()
        window.addEventListener('resize', handleInnerWidth)

        return () =>{
            window.removeEventListener('resize',handleInnerWidth)
        }
    }, [])

    return (
        <header className="c-header w-100 pb-0 position-fixed">
            <div className="container position-relative">
                <nav className="c-nav d-flex align-items-center justify-content-between w-100">
                    <div className="c-nav__left d-flex align-items-center h-100">
                        <figure className="c-brand m-0">
                            <a href="/home" aria-current="page" className="c-brand__link">
                                <img src="https://storange-images.s3.amazonaws.com/img/storangelogo_white.webp" alt="" className='h-100' />
                            </a>
                        </figure>
                        <ul className="c-menu m-0 p-0 d-none d-lg-flex">
                            <li className="d-flex align-items-center menu-item">
                                <a href="/home" aria-current="page" className="">
                                    <span data-icon="tabler:home-filled" className='iconify'></span>
                                    <span>
                                        Gestionar
                                    </span>
                                </a>
                            </li>
                            <li className="d-flex align-items-center menu-item">
                                {/* <a href={`/garage-sale/${propietario?.idPropietario}/catalogo`} aria-current="page" className=""> */}
                                <a href="/dashboard" aria-current="page" className="">
                                    <span data-icon="fluent:clipboard-bullet-list-16-filled" className='iconify'></span>
                                    <span>
                                        Catálogo
                                    </span>
                                </a>
                            </li>
                            <li className="d-flex align-items-center menu-item">
                                <a href="/garage-sale" aria-current="page" className="">
                                    <span data-icon="mdi:cart-sale" className='iconify'></span>
                                    <span>
                                        Garage Sale
                                    </span>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="c-nav__right">
                        <button onClick={toggleDrawer(true)} className='c-button c-button--burguer'>
                            <span data-icon="mingcute:menu-fill" className='iconify'></span>
                        </button>
                    </div>
                </nav>
                <Drawer anchor={'right'} open={open} onClose={toggleDrawer(false)}>
                    <div className='c-sidebar'>
                        <div className='c-sidebar__item fw-semibold c-sidebar__item--header position-relative d-flex align-items-center justify-content-between'>
                            <span>Menu</span>
                            <button onClick={toggleDrawer(false)} className='c-cross'>
                                <span data-icon="maki:cross" className='iconify'></span>
                            </button>
                        </div>

                        <Accordion className='d-none' expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                            <AccordionSummary
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                                className='p-0 m-0'
                                >
                                <div className='c-sidebar__item fw-semibold d-flex align-items-center justify-content-between'>
                                    <span>Option 1</span>
                                    <span data-icon="octicon:chevron-down-12" className='iconify'></span>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails className='p-0'>
                                <a href="/" className='c-sidebar__item c-sidebar__item--subitem d-flex align-items-center justify-content-between'>
                                    <span>Option 10</span>
                                </a>
                                <a href="/" className='c-sidebar__item c-sidebar__item--subitem d-flex align-items-center justify-content-between'>
                                    <span>Option 11</span>
                                </a>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion className='d-none' expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                            <AccordionSummary
                                aria-controls="panel2bh-content"
                                id="panel2bh-header"
                                className='p-0 m-0'
                                >
                                <div className='c-sidebar__item fw-semibold d-flex align-items-center justify-content-between'>
                                    <span>Option 2</span>
                                    <span data-icon="octicon:chevron-down-12" className='iconify'></span>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails className='p-0'>
                                <a href="/" className='c-sidebar__item c-sidebar__item--subitem d-flex align-items-center justify-content-between'>
                                    <span>Option 20</span>
                                </a>
                                <a href="/" className='c-sidebar__item c-sidebar__item--subitem d-flex align-items-center justify-content-between'>
                                    <span>Option 22</span>
                                </a>
                            </AccordionDetails>
                        </Accordion>

                        <a href="/home" className='c-sidebar__item fw-semibold d-flex align-items-center justify-content-start gap-2'>
                            <span data-icon="tabler:home-filled" className='iconify'></span>
                            <span>Gestionar</span>
                        </a>
                        <a href="/dashboard" className='c-sidebar__item fw-semibold d-flex align-items-center justify-content-start gap-2'>
                            <span data-icon="fluent:clipboard-bullet-list-16-filled" className='iconify'></span> <span>Catálogo</span>
                        </a>
                        <a href="/garage-sale" className='c-sidebar__item fw-semibold d-flex align-items-center justify-content--start gap-2'>
                            <span data-icon="mdi:cart-sale" className='iconify'></span>
                            <span>Garage sale</span>
                        </a>
                        <a href="/perfil" className='c-sidebar__item fw-semibold d-flex align-items-center justify-content--start gap-2'>
                            <span data-icon="mdi:user" className='iconify'></span>
                            <span>Perfil</span>
                        </a>
                    </div>
                </Drawer>
            </div>
            {/* old ver */}
            <div className='navbar d-none'>
                <AppBar position="static" className='appbar'>
                    <Container maxWidth="xl">
                        <Toolbar disableGutters style={{justifyContent: 'space-between'}}>
                            <Box style={{
                                display: innerWidth < 900 ? 'flex' : 'none',
                            }}>
                                <IconButton
                                    size="large"
                                    aria-label="account of current user"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={handleOpenNavMenu}
                                    style={{ color: '#fff' }}
                                >
                                    <MenuIcon />
                                </IconButton>
                                <Menu
                                    id="menu-appbar"
                                    anchorEl={anchorElNav}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                    open={Boolean(anchorElNav)}
                                    onClose={handleCloseNavMenu}
                                    sx={{
                                        display: { xs: 'block', md: 'none' },
                                    }}
                                >
                                    {pages.map((page) => (
                                        <MenuItem
                                        key={page.text}
                                        onClick={() => {
                                            window.scrollTo(0, 0)
                                            handleCloseNavMenu()
                                            setChange(!change)
                                            setLoaderState(0)
                                            setTimeout(() => {
                                                navigate(page.path)
                                                setOe({
                                                    ...oe,
                                                    direccion: '',
                                                    direccionName: ''
                                                })
                                                setDireccionSelect('default')
                                                setFormEnvioPage(2)
                                            }, 500)
                                        }}>
                                            <Typography textAlign="center">{page.text}</Typography>
                                        </MenuItem>
                                    ))}
                                </Menu>
                            </Box>

                            <img
                            src={'https://storange-images.s3.amazonaws.com/img/storangelogo_white.webp'}
                            style={{
                                width: innerWidth < 450 ? '150px' : '200px'
                            }}
                            alt="storange logo" />

                            <Box
                            sx={{flexGrow: 'initial', display: { xs: 'none', md: 'flex' } }}
                            >
                                <div
                                    className={'navLink'}
                                    onClick={() => {
                                        window.scrollTo(0, 0)
                                        setChange(!change)
                                        setLoaderState(0)
                                        setTimeout(() => {
                                            navigate('/pickup_send')
                                            setOe({
                                                ...oe,
                                                direccion: '',
                                                direccionName: ''
                                            })
                                            setDireccionSelect('default')
                                            setFormEnvioPage(2)
                                        }, 500)
                                    }}
                                    sx={{ my: 2, color: 'white', display: 'block' }}
                                >
                                    <div style={{position: 'relative', width: '100%', display: 'flex', justifyContent: 'center'}}>
                                        <FontAwesomeIcon className='menuIcon' icon={faTruckFast} />
                                        {carrito !== null ? (
                                            <div className='indicator'>
                                                {carrito.length}
                                            </div>
                                        ) : false}
                                    </div>
                                    <div>Recoger/Enviar</div>
                                </div>

                                <div
                                    className={'navLink'}
                                    onClick={() => {
                                        window.scrollTo(0, 0)
                                        setChange(!change)
                                        setLoaderState(0)
                                        setTimeout(() => navigate('/dashboard'), 500)
                                    }}
                                    sx={{ my: 2, color: 'white', display: 'block' }}
                                >
                                    <FontAwesomeIcon className='menuIcon' icon={faCirclePlus} />
                                    <div>Mis articulos</div>
                                </div>

                                <div
                                    className={'navLink'}
                                    onClick={() => {
                                        window.scrollTo(0, 0)
                                        setChange(!change)
                                        setLoaderState(0)
                                        setTimeout(() => navigate('/perfil'), 500)
                                    }}
                                    sx={{ my: 2, color: 'white', display: 'block' }}
                                >
                                    <FontAwesomeIcon className='menuIcon' icon={faFaceLaugh} />
                                    <div>Perfil</div>
                                </div>

                            </Box>

                            <Box sx={{ flexGrow: 0 }}>
                                {propietario !== null && (
                                    <>
                                        <Tooltip title="Open settings">
                                            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                                <Avatar className='avatar' alt={propietario.nombre} src="/static/images/avatar/2.jpg" />
                                            </IconButton>
                                        </Tooltip>
                                        <Menu
                                            sx={{ mt: '45px' }}
                                            id="menu-appbar"
                                            anchorEl={anchorElUser}
                                            anchorOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                            }}
                                            keepMounted
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                            }}
                                            open={Boolean(anchorElUser)}
                                            onClose={handleCloseUserMenu}
                                            className='menu'
                                        >
                                            <div className="menuItem">
                                                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                                    <Avatar style={{ backgroundColor: '#795548' }} alt={propietario.nombre} src="/static/images/avatar/2.jpg" />
                                                </IconButton>
                                                <h3 className='name'>
                                                    {`${formatStrings(propietario.nombre)} ${formatStrings(propietario.apellido || '')}`}
                                                </h3>
                                                <p className='email'>
                                                    {propietario.email}
                                                </p>
                                            </div>
                                            <div onClick={() =>{
                                                window.scrollTo(0, 0)
                                                setChange(!change)
                                                setLoaderState(0)
                                                handleCloseUserMenu()
                                                setTimeout(() => navigate('/perfil'), 500)
                                            }} className="menuItem">Editar Perfil</div>
                                            <div onClick={() =>{
                                                window.scrollTo(0, 0)
                                                handleCloseUserMenu()
                                                setTimeout(() => {
                                                    navigate('/')
                                                    restartAll()
                                                }, 500)
                                            }} className="menuItem">Cerrar Sesión</div>
                                        </Menu>
                                    </>
                                )}
                            </Box>
                        </Toolbar>
                    </Container>
                </AppBar>
            </div>
            {/* old ver */}
        </header>
    )
}

export default Navbar