import React, {useState, useContext, useEffect} from "react"
import { useParams } from 'react-router-dom'
import env from "react-dotenv";
import { GlobalContext } from "../../context/GlobalStateContext";
import PropietarioContext from '../../context/PropietarioContext';
import { getOrderData } from "../../servicios/item";

const CarritoSuccess = () => {
  const { accessToken } = useContext(GlobalContext)
  const { propietario } = useContext(PropietarioContext)
  const [orderData, setOrderData] = useState(null)
  const { id } = useParams();
  
  const handleOrderData = async () => {
    try {
      const data = await getOrderData(id, accessToken);
      // const order = data.find(order => order.idOGL === id);
      if(data) {
        setOrderData(data);
      }
    } catch (error) {
      console.error("Error handling data:", error);
    }
  }

  useEffect(()=> {
    if(propietario) {
      handleOrderData()
    }
  }, [propietario])

  return(
  <div className="c-sendcar c-catalogo">
    <div className="container">
      {orderData !== null ? (
        <div className='c-step'>
          <div className='c-modal__icon c-modal__icon--success text-center'>
            <span data-icon="ep:success-filled" className='iconify'></span>
          </div>
          <div className='d-flex align-items-center justify-content-center'>
            <span className='c-step__label'> Order generada correctamente </span>
          </div>
          <div className="c-order-number mb-3 text-center mb-4">
            <span>N. de orden</span>
            <div className="">
              {orderData.idOGL}
            </div>
          </div>
          <div className="c-resume mb-3">
            <div className='c-step__description mb-3'>
              Tiene <strong>{orderData.articulos.length} artículo(s)</strong> seleccionados: 
            </div>
              <div className='c-detailList mb-3'>
                {orderData.articulos.map((article, index) => (
                      <div key={index} className='c-detailList__item d-flex align-items-center justify-content-center'>
                      <figure className='c-detailList__figure m-0'>
                        <img src={article.imagenRecogida !== null
                        ? `https://storange-images.s3.amazonaws.com/appsheet/data/StorangeGestionLogistica_5361150/${article.imagenRecogida}`
                        : 'https://pad.minem.gob.pe/Proyecta_CMS/Recursos/ProyectoImg/SinImagen.png'} alt={article.titulo} className='w-100 h-100 object-fit-cover' />
                      </figure>
                      <div className='c-detailList__data d-flex align-items-center justify-content-between'>
                        <span>{article.titulo}</span>
                        <span className="opacity-0">S/ XX</span>
                      </div>
                      </div>
                    ))}

              </div>
            <div className="d-flex flex-column gap-3">
              {orderData.tipoServicio === 'Envio de Articulos' && 
                <div className="d-flex flex-column gap-3">
                  <div className='c-detailTotal d-flex align-items-center justify-content-between'>
                    <span>
                      <span data-icon="ep:success-filled" className='iconify'></span>
                      <span className='ms-2'>Direccion:</span>
                    </span>
                    <span>{orderData.Direccion}</span>
                  </div>
                  <div className='c-detailTotal d-flex align-items-center justify-content-between'>
                    <span>
                      <span data-icon="ep:success-filled" className='iconify'></span>
                      <span className='ms-2'>Fecha:</span>
                    </span>
                    <span>{orderData.FechaSolicitado}</span>
                  </div>
                </div>
              }
              <div className='c-detailTotal d-flex align-items-center justify-content-between'>
                <span>
                  <span data-icon="ep:success-filled" className='iconify'></span>
                  <span className='ms-2'>Volumen total:</span>
                </span>
                <span>{orderData.volumenTotal} m<sup>3</sup></span>
              </div>
              <div className='c-detailTotal d-flex align-items-center justify-content-between'>
                <span>
                  <span data-icon="ep:success-filled" className='iconify'></span>
                  <span className='ms-2'>Tipo de servicio:</span>
                </span>
                <span>{orderData.tipoServicio}</span>
              </div>
              <div className='c-detailTotal d-flex align-items-center justify-content-between'>
                <span>
                  <span data-icon="ep:success-filled" className='iconify'></span>
                  <span className='ms-2'>Total:</span>
                </span>
                <span>S/ {orderData.total}</span>
              </div>
            </div>
          </div>
        </div>
      ): <div>
        No se encontro el numero de orden
        </div>}
    </div>
  </div>)
}

export default CarritoSuccess