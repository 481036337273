import React from 'react'
import CarritoEnvio from '../components/CarritoEnvio/CarritoEnvio'

const CarritoEnvioView = () => {
  return (
    <div>
      <CarritoEnvio />
    </div>
  )
}

export default CarritoEnvioView