import React, {useState, useEffect} from "react"
// import { GoogleMap, MarkerF} from "@react-google-maps/api";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from "@reach/combobox";
import "@reach/combobox/styles.css";

// const ModalAddress = (adressListOption) => {
//   const [map, setMap] = useState(/** @type google.maps.Map */ (null))
//   const center = useMemo(() => ({ lat: 43.45, lng: -80.49 }), []);
//   const [selected, setSelected] = useState(null);
//   const [addressList, setAddressList] = useState(adressListOption)

//   const PlacesAutocomplete = ({ setSelected }) => {
//     const {
//       ready,
//       value,
//       setValue,
//       suggestions: { status, data },
//       clearSuggestions,
//     } = usePlacesAutocomplete();
  
//     const handleSelect = async (address) => {
//       setValue(address, false);
//       clearSuggestions();
  
//       const results = await getGeocode({ address });
//       const { lat, lng } = await getLatLng(results[0]);
//       setSelected({ lat, lng });
//     };
  
//     return (
//       <Combobox onSelect={handleSelect}>
//         <ComboboxInput
//           value={value}
//           onChange={(e) => setValue(e.target.value)}
//           disabled={!ready}
//           className="combobox-input"
//           placeholder="Search an address"
//         />
//         <ComboboxPopover>
//           <ComboboxList>
//             {status === "OK" &&
//               data.map(({ place_id, description }) => (
//                 <ComboboxOption key={place_id} value={description} />
//               ))}
//           </ComboboxList>
//         </ComboboxPopover>
//       </Combobox>
//     );
//   };
//   return(
//     <div className='c-modal'>
//           <div className='c-modal__content'>
//             <div className="d-flex align-items-center justify-content-between mb-3">
//               <div className='c-modal__title me-3'>
//                 Agregar Nueva Dirección
//               </div>
//             </div>
//             <div >
//               <div className='c-form w-100'>
//                 <div className='row gy-3'>
//                   <div className='col-12'>
//                     <div className='c-input-group'>
//                       <label>Dirección</label>
//                         {/* <input type="text" placeholder='Dirección'/> */}
//                         <PlacesAutocomplete />
//                       <span className='c-error'>error</span>
//                     </div>
//                   </div>
//                   <div className="col-12">
//                     <div className='c-input-group'>
//                       <label onClick={()=>  map.panTo({ lat: 48.8584, lng: 2.2945 })}>Referencia</label>
//                       <input type="text" placeholder='Referencia'/>
//                       <span className='c-error'>error</span>
//                     </div>
//                   </div>


//                     {addressList && 
//                       <div className="c-map">
//                         <GoogleMap
//                           mapContainerStyle={{ height: '100%' }}
//                           zoom={14}
//                           center={center}
//                           mapContainerClassName="map-container"
//                           onLoad={map => setMap(map)}
//                         >
//                           {selected && <MarkerF position={selected} />}
//                         </GoogleMap>
//                     </div>
//                     }
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//   )
// }

// export default ModalAddress



const PlacesAutocomplete = ({ setNewAddressData, newAddressData }) => {
  const {
    ready,
    value,
    setValue,
    suggestions: { status, data },
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      componentRestrictions: { country: 'pe' },
    },
  });

  const handleSelect = async (address) => {
    setValue(address, false);
    clearSuggestions();

    try {
      const results = await getGeocode({ address });
      const { lat, lng } = await getLatLng(results[0]);
      setNewAddressData(prevState => ({
        ...prevState,
        address: address,
        coordinates: { lat, lng }
      }));
    } catch (error) {
      console.error("Error fetching geocode and lat/lng:", error);
    }

  };

  useEffect(() => {
    if (newAddressData.address) {
      setValue(newAddressData.address, false);
    }
  }, []);

  return (
    <Combobox onSelect={handleSelect}>
      <ComboboxInput
        value={value}
        onChange={(e) => setValue(e.target.value)}
        disabled={!ready}
        className="combobox-input"
        placeholder="Search an address"
      />
      <ComboboxPopover>
        <ComboboxList>
          {status === "OK" &&
            data.map(({ place_id, description }) => (
              <ComboboxOption key={place_id} value={description} />
            ))}
        </ComboboxList>
      </ComboboxPopover>
    </Combobox>
  );
};

export default PlacesAutocomplete