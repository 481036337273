import React from 'react'
import RecogerEnviarContainer from '../components/RecogerEnviarContainer/RecogerEnviarContainer';

const RecogerEnviarView = () => {
  return (
    <div className='recogerEnviarView'>
        <RecogerEnviarContainer/>
    </div>
  )
}

export default RecogerEnviarView