import React from 'react'
import LoginContainer from '../components/InitialScreen/LoginContainer/LoginContainer'
import { AuthProvider } from '../context/AuthContext'

const LoginView = () => {
  return (
    <div className='loginView' style={{overflow: 'hidden'}}>
        <AuthProvider>
          <LoginContainer/>
        </AuthProvider>
    </div>
  )
}

export default LoginView