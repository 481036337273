// const encodePetition = (parameters, formBody = []) => {
//   for (let property in parameters) {
//     let encodedKey = encodeURIComponent(property)
//     let encodedValue = encodeURIComponent(parameters[property])
//     formBody.push(encodedKey + "=" + encodedValue)
//   }
//   return formBody.join("&")
// }

import env from "react-dotenv";


export const enviarACarrito = (idArticulo, typeCart, accessToken) => {
  let details = { idArticulo, typeCart }

  fetch(env.API_URL+"/updateCart", {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      "Authorization": "Bearer "+accessToken
    },
    body: JSON.stringify(details)
  })
    .then((res) => res.text())
    .then((res) => {
      console.log(res)
    })
}

export const getGallery = (idArticulo, accessToken) => {
  let details = { idArticulo }

  return fetch(env.API_URL+"/fotos", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization": "Bearer "+accessToken
    },
    body: JSON.stringify(details)
  })
    .then((res) => res.json())
    .then((res) => {
      console.log(res)
      return res;
    })
    .catch((error) => {
      console.error("Error fetching gallery:", error);
      throw error;
    });
}

export const getExternalGallery = (idArticulo) => {
  let details = { idArticulo }

  return fetch(env.API_URL+"/external/fotos", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      // "Authorization": "Bearer "+accessToken
    },
    body: JSON.stringify(details)
  })
    .then((res) => res.json())
    .then((res) => {
      console.log(res)
      return res;
    })
    .catch((error) => {
      console.error("Error fetching gallery:", error);
      throw error;
    });
}

export const getMoreArticles = (typeCart, idPropietario, accessToken) => {
  return fetch(`${env.API_URL}/cart/articles/${idPropietario}/${typeCart}/more`, {
    method: "GET",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      "Authorization": "Bearer "+accessToken
    },
  })
    .then((res) => res.json())
    .then((res) => {
      return res;
    })
    .catch((err) =>{
      console.log('no autorizado', err)
    })
    .finally(() => console.log('Finalizado'))
}
export const getArticlesFromCart = (typeCart, idPropietario, accessToken) => {
  return fetch(`${env.API_URL}/cart/articles/${idPropietario}/${typeCart}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      "Authorization": "Bearer "+accessToken
    },
  })
    .then((res) => res.json())
    .then((res) => {
      return res;
    })
    .catch((err) =>{
      console.log('no autorizado', err)
    })
    .finally(() => console.log('Finalizado'))
}

export const getAdressList = (idPropietario, accessToken) => {
  let details = { idPropietario }

  return fetch(env.API_URL+"/direcciones", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization": "Bearer "+accessToken
    },
    body: JSON.stringify(details)
  })
    .then((res) => res.json())
    .then((res) => {
      console.log(res)
      return res;
    })
    .catch((error) => {
      console.error("Error fetching gallery:", error);
      throw error;
    });
}
export const getOrderData = (orderId, accessToken) => {
  // let details = { idPropietario }

  // return fetch(env.API_URL+"/obtenerOGL", {
  return fetch(env.API_URL_V2+"/ogl/"+orderId, {
    // method: "POST",
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Authorization": "Bearer "+accessToken
    },
    // body: JSON.stringify(details)
  })
    .then((res) => res.json())
    .then((res) => {
      console.log(res)
      return res;
    })
    .catch((error) => {
      console.error("Error:", error);
      throw error;
    });
}

export const getLastBills = (idPropietario, accessToken) => {
  return fetch(env.API_URL+"/ticket/propietario/"+idPropietario+"/recent", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Authorization": "Bearer "+accessToken
    },
  })
    .then((res) => res.json())
    .then((res) => {
      console.log(res)
      return res;
    })
    .catch((error) => {
      console.error("Error fetching gallery:", error);
      throw error;
    });
}

export const addAdress = (idPropietario, accessToken, lat, lng, directionMain, direction, distrito, referencia) => {
  let details = { lat, lng, directionMain, direction, distrito, referencia, idPropietario }

  fetch(env.API_URL+"/agregarDireccion", {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "Authorization": "Bearer "+accessToken
    },
    body: JSON.stringify(details)
  })
    .then((res) => res.text())
    .then((res) => {
      console.log(res)
    })
}

export const deleteAdress = (accessToken, idDireccion) => {
  let details = { idDireccion }

  fetch(env.API_URL+"/borrarDireccion", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization": "Bearer "+accessToken
    },
    body: JSON.stringify(details)
  })
    .then((res) => res.text())
    .then((res) => {
      console.log(res)
    })
}

export const sendSale  = (accessToken, data) => {
  return fetch(env.API_URL+"/sale", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization": "Bearer "+accessToken
    },
    body: JSON.stringify(data)
  })
    .then((res) => res.text())
    .then((res) => {
      console.log(res)
    })
    .catch((error) => {
      console.error("Error:", error);
      throw error;
    });
}

export const sendExternalSale  = (data) => {
  return fetch(env.API_URL+"/external/sale", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      // "Authorization": "Bearer "+accessToken
    },
    body: JSON.stringify(data)
  })
    .then((res) => res.text())
    .then((res) => {
      console.log(res)
    })
    .catch((error) => {
      console.error("Error:", error);
      throw error;
    });
}

export const pickupOrder  = (accessToken, data) => {
  return fetch(env.API_URL+"/ogl/recojo", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization": "Bearer "+accessToken
    },
    body: JSON.stringify(data)
  })
    .then((res) => res.text())
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.error("Error:", error);
      throw error;
    });
}

export const updatePassword = (accessToken, idPropietario, currentPassword, newPass, newPassConfirm) => {
  if (newPass === newPassConfirm && newPass.length > 6) {
    let details = { 
      idPropietario,
      currentPassword ,
      newPassword : newPass }

    return fetch(env.API_URL_V2+"/actualizarContrasena", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer "+accessToken
      },
      body: JSON.stringify(details)
    })
    .then((res) => res.text())
    .then((res) => {
      console.log(res)
      return res
    })
    .catch((error) => {
      console.error("Error:", error);
      throw error;
    });
  } else {
    return false
  }
}

export const updateUser = (accessToken, idPropietario, name, apellido, email, phone) => {
    let details = { idPropietario, name, apellido, email, phone }
    return fetch(env.API_URL+"/actualizarPropietario", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer "+accessToken
      },
      body: JSON.stringify(details)
    })
    .then((res) => res.text())
    .then((res) => {
      return res
    })
    .catch((error) => {
      console.error("Error:", error);
      throw error;
    });
}

