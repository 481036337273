import { useState, useRef, useContext, useEffect } from 'react'
import env from "react-dotenv";
import useClickOutside from '../../hooks/useClickOutside';
import { enviarACarrito } from '../../servicios/item';
import PropietarioContext from '../../context/PropietarioContext';
import { GlobalContext } from '../../context/GlobalStateContext';
import Snackbar from '@mui/material/Snackbar';
import Tooltip from '@mui/material/Tooltip';


const GarageSale = () => {
  const [articulos, setArticulos] =  useState([])
  const [articulosPublicados, setArticulosPublicados] =  useState([])
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const { accessToken } = useContext(GlobalContext)
  const { propietario } = useContext(PropietarioContext)
  const [toast, setToast] = useState({
    open: false,
    vertical: 'bottom',
    horizontal: 'left',
  })
  const { vertical, horizontal, open } = toast;

  const handleCopyClick = () => {
    navigator.clipboard.writeText(`${window.location.hostname}/garage-sale/${propietario.idPropietario}/catalogo`)
      .then(() => {
        console.log('Texto copiado al portapapeles');
        setToast({ ...toast, open: true });
      })
      .catch(err => {
        console.error('Error al copiar el texto: ', err);
      });
  };

  const handleCloseToast = () => {
    setToast({ ...toast, open: false });
  };

  const handleCheckboxChange = (event) => {
    const checkboxId = event.target.id;
    const isChecked = event.target.checked;

    if (isChecked) {
      // Add the checkbox ID to the array if checked
      setSelectedItems((prevIds) => [...prevIds, checkboxId]);
    } else {
      // Remove the checkbox ID from the array if unchecked
      setSelectedItems((prevIds) => prevIds.filter((id) => id !== checkboxId));
      setSelectAllChecked(false)
    }
  };

  const handleSelectAllChange = (event) => {
    if (event.target.checked) {
      const allItemsListed = [...articulos, ...articulosPublicados];
      const allCheckboxIds = allItemsListed.map((item) => String(item.idArticulo));
      setSelectAllChecked(true)
      setSelectedItems(allCheckboxIds);
    } else {
      setSelectedItems([]);
      setSelectAllChecked(false)
    }
  };

  const isCheckboxSelected = (itemId) => {
    return selectedItems.includes(String(itemId));
  };

  const encodePetition = (parameters, formBody = []) => {
    for (let property in parameters) {
      let encodedKey = encodeURIComponent(property)
      let encodedValue = encodeURIComponent(parameters[property])
      formBody.push(encodedKey + "=" + encodedValue)
    }
    return formBody.join("&")
  }

  const getArticlesFromCart = (typeCart) => {
    fetch(`${env.API_URL}/cart/articles/${propietario?.idPropietario}/${typeCart}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        "Authorization": "Bearer "+accessToken
      },
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res)
        if(typeCart === '4') {
          setArticulosPublicados(res)
        } else {
          setArticulos(res)
        }
      })
      .catch((err) =>{
        console.log('no autorizado', err)
      })
      .finally(() => console.log('Finalizado'))
  }

  const removeItemsFromCar =  () => {
    enviarACarrito(selectedItems, 0, accessToken)
    const FilteredArticles = articulos.filter(articulo => !selectedItems.includes(articulo.idArticulo))
    const FilteredPublishedArticles = articulosPublicados.filter(articulo => !selectedItems.includes(articulo.idArticulo))
    setArticulos(FilteredArticles)
    setArticulosPublicados(FilteredPublishedArticles)
    setSelectedItems([])
    setSelectAllChecked(false)
  }

  const RenderItemRow = ({articulo}) => {
    const [article, setArticle] = useState(articulo) 
    const [isSubMenuShow, setIsSubMenuShow] = useState(false);
    const menuCardRef = useRef(null);
    const [isEditing, setIsEditing] = useState({ title: false, price: false, description: false });
    const [newTitle, setNewTitle] = useState('')
    const [newDescription, setNewDescription] = useState('')
    const [newPrice, setNewPrice] = useState('')

    useClickOutside(menuCardRef, () => setIsSubMenuShow(false));

    const addToCar = (car) => {
      const carNumber = parseInt(car);

      if(article.estadoEnvio === 0 || article.estadoEnvio !== carNumber ) {
          enviarACarrito(article.idArticulo, car, accessToken)
          setArticle({ ...article, estadoEnvio: carNumber })
        } else if(article.estadoEnvio === carNumber ) {
          enviarACarrito(article.idArticulo,'2', accessToken)
          setArticle({ ...article, estadoEnvio: 2 })
        }

        setIsSubMenuShow(false);
    }

    const actualizarTitulo = (newtitle) => {
      let details = { titulo: newtitle, idArticulo: article.idArticulo }
  
      fetch(env.API_URL+"/actualizarTitulo", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
          "Authorization": "Bearer "+accessToken
        },
        body: encodePetition(details)
      })
        .then(() => {
          setArticle({ ...article, titulo: newtitle })
        })
    }
  
    const actualizarDescripcion = (newDescription) => {
      let details = { descripcion: newDescription, idArticulo: article.idArticulo }
  
      fetch(env.API_URL+"/actualizarDescripcion", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
          "Authorization": "Bearer "+accessToken
        },
        body: encodePetition(details)
      })
        .then(() => {
          setArticle({ ...article, descripcionPropietario: newDescription })
        })
    }
  
    const actualizarPrecio = (newPrice) => {
      let details = { precio: newPrice, idArticulo: article.idArticulo }
  
      fetch(env.API_URL+"/actualizarPrecio", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
          "Authorization": "Bearer "+accessToken
        },
        body: encodePetition(details)
      })
        .then(() => {
          setArticle({ ...article, precio: newPrice })
        })
    }

    const handleEditFormClick = ({ type, value, action }) => {
      setIsEditing({ ...isEditing, [type]: value });
  
      if( action === 'edit') {
        switch(type) {
          case 'title': 
            if(newTitle) {
              actualizarTitulo(newTitle);
            }
            break;
          case 'description':
            if(newDescription) {
              actualizarDescripcion(newDescription);
            }
            break;
          case 'price':
            if(newPrice) {
              actualizarPrecio(newPrice);
            }
            break;
          default:
        }
      } else {
        setNewTitle(article.titulo)
        setNewDescription(article.descripcionPropietario)
        setNewPrice(article.precio)
      }
    };

    const tooltipMessage = (item) => {
      if(item.estadoEnvio === 4) {
        return "Publicado"
      }
      const message = item.precio === '' || item.descripcionPropietario === '' ? "Completa los datos del artículo" : "Publicar a la venta"
      return message;
    }

    if (article.estadoEnvio !== 2 && article.estadoEnvio !== 4) {
      return null;
    }

    return (
      <div className='c-table__row'>
        <div className='c-table__item'>
          <input 
            type='checkbox' 
            id={article.idArticulo} 
            onChange={handleCheckboxChange}
            checked={isCheckboxSelected(article.idArticulo)} 
            className='c-checkbox' />
        </div>
        <div className='c-table__item'>
          <figure className='c-table__figure'>
            <img src={article.imagenRecogida !== null
          ? `https://storange-images.s3.amazonaws.com/appsheet/data/StorangeGestionLogistica_5361150/${article.imagenRecogida}`
          : 'https://pad.minem.gob.pe/Proyecta_CMS/Recursos/ProyectoImg/SinImagen.png'} alt={article.titulo}  className='w-100 h-100 object-fit-contain'/>
          </figure>
        </div>
        <div className='c-table__item'>
            {isEditing.title ? (<form className='c-edit-feature c-edit-feature--small'>
              <input 
                value={newTitle}
                onChange={(e) => setNewTitle(e.target.value)} 
                type="text" 
                className='w-100' />
              <div className='c-edit-feature__button-group d-flex align-items-start mt-3'>
                <button onClick={() => handleEditFormClick({ type: 'title', value: false, action: 'edit'  })}  className='c-button c-button--primary' type="button">Editar</button>
                <button onClick={() => handleEditFormClick({ type: 'title', value: false,action: 'cancel' })} className='c-button c-button--primary-ghost'>Cancelar</button>
              </div>
            </form> 
          ) : (
            <div>
              <span>
                {article.titulo}
              </span> 
              <button onClick={() => handleEditFormClick({ type: 'title', value: true })} className="c-edit ms-2">
                <span data-icon="material-symbols:edit-outline" className='iconify'></span>
              </button>
            </div>
          ) }
        </div>
        <div className='c-table__item'>
          {isEditing.description ? (<form className='c-edit-feature c-edit-feature--small'>
            <input  
              type="text" 
              className='w-100'
              value={newDescription}
              onChange={(e) => setNewDescription(e.target.value)} 
            />
            <div className='c-edit-feature__button-group d-flex align-items-start mt-3'>
              <button onClick={() => handleEditFormClick({ type: 'description', value: false,  action: 'edit' })}  className='c-button c-button--primary'>Editar</button>
              <button onClick={() => handleEditFormClick({ type: 'description', value: false,  action: 'cancel' })} className='c-button c-button--primary-ghost'>Cancelar</button>
            </div>
          </form> 
        ) : (
        <div>
          <p className='m-0'>{article.descripcionPropietario ? article.descripcionPropietario : 'Articulo sin descripción'}</p>

          <button onClick={() => handleEditFormClick({ type: 'description', value: true })} className="c-edit ms-2">
            <span data-icon="material-symbols:edit-outline" className='iconify'></span>
          </button>
        </div>
      )}
          
          </div>
        <div className='c-table__item'>
          {isEditing.price ? (
            <form className='c-edit-feature c-edit-feature--small'>
                <input 
                  type="number" 
                  className='w-100'
                  value={newPrice}
                  onChange={(e) => setNewPrice(e.target.value)}
                />
                <div className='c-edit-feature__button-group d-flex align-items-start mt-3'>
                  <button onClick={() => handleEditFormClick({ type: 'price', value: false, action :'edit' })}  className='c-button c-button--primary'>Editar</button>
                  <button onClick={() => handleEditFormClick({ type: 'price', value: false,action :'cancel'})} className='c-button c-button--primary-ghost'>Cancelar</button>
                </div>
              </form> 
            ) : (
              <div>
                <span>
                  S/ {article.precio ? article.precio : 0 }
                </span> 
                <button onClick={() => handleEditFormClick({ type: 'price', value: true })} className="c-edit ms-2">
                  <span data-icon="material-symbols:edit-outline" className='iconify'></span>
                </button>
              </div>
            )}
          </div>

      <div ref={menuCardRef} className='c-table__item position-relative'>
        <Tooltip title={tooltipMessage(article)}>
          <button onClick={() => addToCar('4', article.idArticulo)} className={`c-clear-button c-publish-item me-2 ${article.estadoEnvio === 4 ? 'active' : ''}`} disabled={article.precio === '' || article.descripcionPropietario === ''}>
            <span data-icon='mdi:world' className='iconify'></span>
          </button>
        </Tooltip>
        <button onClick={() => setIsSubMenuShow(!isSubMenuShow)} className='c-clear-button'>
          <span data-icon='ph:shopping-cart' className='iconify'></span>
        </button>
        { isSubMenuShow && <div className='c-floatselect round-btn position-absolute'>
          <ul className='m-0 p-0'>  
            <li
              className='c-floatselect__option option-title w-100 d-flex align-items-center justify-content-between'
            >
              <div className='c-floatselect__label title d-flex align-items-center'>
                <span>Agregar al carrito de:</span>
              </div>
            </li>
            <li
              onClick={() => addToCar('1', article.idArticulo)}
              className='c-floatselect__option w-100 d-flex align-items-center justify-content-between'
            >
              <div className='c-floatselect__label d-flex align-items-center'>
                <span data-icon='mynaui:send' className='iconify'></span>
                <span>Envío</span>
              </div>
              <button className='c-floatselect__add'>
                <span data-icon="tabler:plus" className='iconify'></span>
              </button>
            </li>
            <li
              onClick={() => addToCar('3', article.idArticulo)}
              className='c-floatselect__option w-100 d-flex align-items-center justify-content-between'
            >
              <div className='c-floatselect__label d-flex align-items-center'>
                <span data-icon='mdi:trash-outline' className='iconify'></span>
                <span>Eliminación</span>
              </div>
              <button className='c-floatselect__add'>
                <span data-icon="tabler:plus" className='iconify'></span>
              </button>
            </li>
          </ul>
        </div>}
      </div>
    </div>
    )
  }

  useEffect(()=>{
    getArticlesFromCart('2');
    getArticlesFromCart('4');
  }, [propietario])

  return(    
  <div className="c-garage-sale">
    <div className="container">
      <div className="d-flex align-items-center justify-content-between">
        <a href="/dashboard" className="c-return">
          <span data-icon="ep:back" className='iconify'></span> Regresar
        </a>

        <button onClick={handleCopyClick} className="c-button c-button--primary-ghost">
          <span data-icon="solar:copy-bold" className='iconify'></span> Link de Catálogo
        </button>
      </div>
      
      <div className="d-lg-flex align-items-center justify-content-between mt-4 mb-4">
        <div>
          <h1 className="c-garage-sale__title mb-lg-0">Artículos para venta</h1>
          <p>Recuerda completar todos los datos de artículo antes de publicarlo</p>
        </div>
        <button onClick={() => removeItemsFromCar()} className="c-button c-button--white-gray c-button--erase">
          <span data-icon="mdi:trash" className='iconify'></span> Eliminar
        </button>
      </div>
      <div className='c-horizontal-scroll'>
        <div>
          <div className="c-table">
            <div className="c-table__head c-table__row">
              <div className="c-table__item">
              <input
                className='c-checkbox'
                type="checkbox"
                id="selectAllCheckbox"
                checked={selectAllChecked}
                onChange={handleSelectAllChange}
              />
              </div>
              <div className="c-table__item">
                Artículo
              </div>
              <div className="c-table__item">
                Nombre
              </div>
              <div className="c-table__item">
                Descripción
              </div>
              <div className="c-table__item">
                Precio
              </div>
              <div className="c-table__item">
                Acciones
              </div>
            </div>

            {articulos !== null ? (
              <div className="c-table__body ">
                {articulosPublicados.map((articulo) => (
                  <RenderItemRow articulo={articulo} key={articulo.idArticulo}/>
                ))}
                {articulos.map((articulo) => (
                  <RenderItemRow articulo={articulo} key={articulo.idArticulo}/>
                ))}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>

    <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        onClose={handleCloseToast}
        message="Copiado al portapapeles"
        key={vertical + horizontal}
      />
  </div>)
}

export default GarageSale

