const EditCategoryCard = () => {
  // recibe las categorias actuales 
  // el id del prodcuto para agregar/eliminar categoria

  // consulta a la api de categorias del usuario 
  return(
    <div className='c-editCategoryCard c-floatselect position-absolute'>
      <ul className='c-floatselect__list d-flex align-items-center m-0'>
        <li className='c-floatselect__item'>
          <span>dsdfsdf</span>
          <button>
            <span data-icon="akar-icons:cross" className='iconify'></span>
          </button>
        </li>
        <li className='c-floatselect__item'>
          <span>dsdfsdf</span>
          <button>
            <span data-icon="akar-icons:cross" className='iconify'></span>
          </button>
        </li>
        <li className='c-floatselect__item'>
          <span>dsdfsdf</span>
          <button>
            <span data-icon="akar-icons:cross" className='iconify'></span>
          </button>
        </li>
      </ul>
      <form className="c-search-form" action="">
        <div className='c-search-group d-flex w-100'>
          <input type="text" />
          <button>
            <span data-icon="mingcute:check-fill" className='iconify'></span>
          </button>
        </div>
      </form>
      <ul className='m-0 p-0'>
        <li className='c-floatselect__option w-100 d-flex align-items-center justify-content-between'>
          <div className='c-floatselect__label d-flex align-items-center'>
            <span>Enviar</span>
          </div>
        </li>
      </ul>
    </div>
  )
}

export default EditCategoryCard 