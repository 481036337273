import React from 'react'
import { useState, useRef, useContext } from 'react'
import env from "react-dotenv";
import { GlobalContext } from '../../../context/GlobalStateContext'
import EditCategoryCard from '../EditCategoryCard/EditCategorycard';
import useClickOutside from '../../../hooks/useClickOutside';

const ItemCard = ({article, type, sendItemData, isListedToBuy, openGallery, horizontal}) => {
  const { accessToken } = useContext(GlobalContext)
  const [menuCard, setMenuCard] = useState(false);
  const [editCategoryItem, setEditCategoryItem] = useState(false);
  const menuCardRef = useRef(null);
  const editaCategoryCardRef = useRef(null);

  useClickOutside(menuCardRef, () => setMenuCard(false));
  useClickOutside(editaCategoryCardRef, () => setEditCategoryItem(false));

  const handleCarBtnClick = () => {
    setMenuCard(!menuCard);
  }

  const handleCategoriesEditClick = () => {
    setEditCategoryItem(!editCategoryItem);
  }

  const addToCar = (car) => {
    const carNumber = parseInt(car);

    if(article.estadoEnvio === 0 || article.estadoEnvio !== carNumber ) {
        enviarACarrito(car)
        article.estadoEnvio = carNumber;
      } else if(article.estadoEnvio === carNumber ) {
        enviarACarrito('0')
        article.estadoEnvio = 0;
      }
    setMenuCard(false);
  }

  const encodePetition = (parameters, formBody = []) => {
    for (let property in parameters) {
      let encodedKey = encodeURIComponent(property)
      let encodedValue = encodeURIComponent(parameters[property])
      formBody.push(encodedKey + "=" + encodedValue)
    }
    return formBody.join("&")
  }

  const enviarACarrito = (typeCart) => {
    let details = { idArticulo: article.idArticulo, typeCart }

    fetch(env.API_URL+"/updateCart", {
      method: "PATCH",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        "Authorization": "Bearer "+accessToken
      },
      body: encodePetition(details)
    })
      .then((res) => res.text())
      .then((res) => {
        console.log(res)
      })
  }

  const dataTobuyList = () => {
    sendItemData(article);
  };

  const handleGallery = () => {
    openGallery(article)
  }

  return(
    <div className={`c-card d-flex  h-100 ${ horizontal ? 'c-card--horizontal':'flex-column'}`}>
      <div ref={menuCardRef} className='position-relative'>
        {
          article.estadoArticulo === 'Almacenado' ? (
            <>
            {type === 'lista' && !horizontal && 
              <button onClick={dataTobuyList} 
              className='c-card__corner-btn position-absolute'>
                <span data-icon="material-symbols:delete" className='iconify'></span>
              </button>
            }
            {type === 'dashboard' && 
              <button onClick={handleCarBtnClick} 
              className={`c-card__corner-btn position-absolute ${article.estadoEnvio !== 0 ? 'active' : ''}`}>
                <span data-icon="ph:shopping-cart" className='iconify unchecked'></span>
                <span data-icon="mingcute:check-fill" className='iconify checked'></span>
              </button>
            }
            </>
          ) : null
        }

        { menuCard && <div className='c-floatselect round-btn position-absolute'>
          <ul className='m-0 p-0'>
            <li
              className='c-floatselect__option option-title w-100 d-flex align-items-center justify-content-between'
            >
              <div className='c-floatselect__label title d-flex align-items-center'>
                <span>Agregar al carrito de:</span>
              </div>
            </li>
            <li onClick={() => addToCar('1')} 
            className={`c-floatselect__option w-100 d-flex align-items-center justify-content-between ${article.estadoEnvio === 1 ? 'active' : ''}`}>
              <div className='c-floatselect__label d-flex align-items-center'>
                <span data-icon="mynaui:send" className='iconify'></span>
                <span>Envío</span> 
              </div>
              <button className='c-floatselect__add'>
                <span data-icon="tabler:plus" className='iconify unchecked'></span>
                <span data-icon="akar-icons:cross" className='iconify checked'></span>
              </button>
            </li>
            <li onClick={() => addToCar('2')} className={`c-floatselect__option w-100 d-flex align-items-center justify-content-between ${article.estadoEnvio === 2 || article.estadoEnvio === 4 ? 'active' : ''}`}>
              <div className='c-floatselect__label d-flex align-items-center'>
                <span data-icon="ph:shopping-cart" className='iconify'></span>
                <span>Venta</span> {article.estadoEnvio === 4 && <span>(P)</span>} 
              </div>
              <button className='c-floatselect__add'>
                <span data-icon="tabler:plus" className='iconify unchecked'></span>
                <span data-icon="akar-icons:cross" className='iconify checked'></span>
              </button>
            </li>
            <li onClick={() => addToCar('3')} className={`c-floatselect__option w-100 d-flex align-items-center justify-content-between ${article.estadoEnvio === 3 ? 'active' : ''}`}>
              <div className='c-floatselect__label d-flex align-items-center'>
                <span data-icon="mdi:trash-outline" className='iconify'></span>
                <span>Eliminación</span>
              </div>
              <button className='c-floatselect__add'>
                <span data-icon="tabler:plus" className='iconify unchecked'></span>
                <span data-icon="akar-icons:cross" className='iconify checked'></span>
              </button>
            </li>
          </ul>
        </div>
        }
      </div>
      <figure onClick={handleGallery} className='c-card__figure m-0'>
        <img src={article.imagenRecogida !== null
          ? `https://storange-images.s3.amazonaws.com/appsheet/data/StorangeGestionLogistica_5361150/${article.imagenRecogida}`
          : 'https://pad.minem.gob.pe/Proyecta_CMS/Recursos/ProyectoImg/SinImagen.png'} alt={article.titulo} className='w-100 h-100 object-fit-cover' />
      </figure>
      <div className='c-card__content d-flex flex-column justify-content-between'>
        <div>
          <h3 className='c-card__title m-0'>{article.titulo}</h3>
          <p className='c-card__description'>
            {article.descripcionPropietario}
          </p>
          <ul className='c-card__features d-flex flex-column m-0 p-0'>
            <li>
              <div className='c-card__feature d-flex align-items-center justify-content-start'>
                <div className='c-card__feature-label'>
                  <span data-icon="uil:estate" className='iconify'></span>
                  Estado:
                </div>
                <div className='c-card__feature-value'>
                  {article.estadoArticulo}
                </div>
              </div>
            </li>
            <li>
              <div className='c-card__feature d-flex align-items-center justify-content-start'>
                <div className='c-card__feature-label'>
                  <span data-icon="radix-icons:size" className='iconify'></span>
                  Volumen:
                </div>
                <div className='c-card__feature-value'>
                  {article.volumen} m<sup>3</sup>
                </div>
              </div>
            </li>
            <li>
              <div className='c-card__feature d-flex align-items-center justify-content-start'>
                <div className='c-card__feature-label'>
                  <span data-icon="radix-icons:size" className='iconify'></span>
                  Medidas:
                </div>
                <div className='c-card__feature-value'>
                  {`${article.medidaAltura} x ${article.medidaLargo} x ${article.medidaProfundidad}`} m
                </div>
              </div>
            </li>
            {/* temporal */}
            {/* {type === 'dashboard' && 
              <li>
                <div className='c-card__feature d-flex align-items-center justify-content-start'>
                  <div className='c-card__feature-label'>
                    <span data-icon="ic:outline-category" className='iconify'></span>
                    Categorías:
                  </div>
                  <div className='c-card__feature-value'>
                    Almacenado 
                    <div ref={editaCategoryCardRef} className='d-inline position-relative'>
                      <button onClick={handleCategoriesEditClick} className='c-edit-btn position-relative'> 
                        <span data-icon="material-symbols:edit-outline" className='iconify'></span>
                      </button> 
                      {editCategoryItem && <EditCategoryCard />}
                    </div>
                  </div>
                </div>
              </li>
            } */}
          </ul>
          {type === 'catalogo' &&
            <div className='c-card__precio mt-2'>
              S/ {article.precio}
            </div>
          }
        </div>
        { type === 'dashboard' &&
          <div>
            <a href={`/dashboard/item/${article.idArticulo}`} className='c-button c-button--primary-ghost w-100'>Ver</a>
          </div>
        }
        { type === 'catalogo' &&
          <div>
            <button onClick={dataTobuyList} className='c-button c-button--primary-ghost w-100'>
              {isListedToBuy ? 'Quitar' : 'Lo quiero'}
            </button>
          </div>
        }
      </div>
    </div>
  )
}

export default ItemCard