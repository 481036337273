import React from 'react'
import { useState, useContext, useEffect } from 'react'
import env from "react-dotenv";
import ImageCarousel from '../Dashboard/ImageCarousel/ImageCarousel'
import Dialog from '@mui/material/Dialog';
import ItemCard from '../Dashboard/ItemCard/ItemCard';
import { getExternalGallery, sendExternalSale } from '../../servicios/item';
// import { GlobalContext } from '../../context/GlobalStateContext';
// import PropietarioContext from '../../context/PropietarioContext';
import { useParams } from 'react-router-dom'


const Catalogo = () => {
  // const { accessToken } = useContext(GlobalContext)
  // const { propietario } = useContext(PropietarioContext)

  const [articulos, setArticulos] = useState([])
  const [buyList, setBuyList] = useState([]);
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    phone: '',
    message: ''
  });
  const [errorformData, setErrorFormData] = useState({
    fullName: '',
    email: '',
    phone: '',
    message: ''
  });
  const [loaded, setLoaded] = useState(false);
  const [stateGalleryModal, setStateGalleryModal] = useState(false);
  const [stateFormModal, setStateFormModal] = useState(false);
  const [stateSuccessModal, setStateSuccessModal] = useState(false);
  const [gallery, setGallery] = useState([])
  const [chosenItem, setChosenItem] = useState([])
  const { codigo } = useParams();

  const handleDataFromChild = (data) => {
    if(!buyList.includes(data)) {
      setBuyList([...buyList, data]);
    } else {
      setBuyList(buyList.filter(id => id !== data));
    }
  };

  const handleGallery = async (data) => {
    setChosenItem(data)
    setStateGalleryModal(true)
    try {
      const galleryData = await getExternalGallery(data.idArticulo);
      setGallery(galleryData);
    } catch (error) {
      console.error("Error handling gallery:", error);
    }
  };

  const toggleGalleryModal = (value) => () => {
    setStateGalleryModal(value);
  }

  const toggleFormModal = (value) => () => {
    if(value) {
      // llamar a la api que calcular el nuevo precio  
    }
    setStateFormModal(value);
  }

  const toggleSuccessModal = (value) => () => {
    setStateSuccessModal(value);
  }

  const getArticlesFromCart = (typeCart) => {
    // fetch(`${env.API_URL}/cart/articles/${propietario?.idPropietario}/${typeCart}`, {
    fetch(`${env.API_URL}/external/cart/articles/${codigo}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        // "Authorization": "Bearer "+accessToken
      },
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res)
        setArticulos(res)
      })
      .catch((err) =>{
        console.log('no autorizado', err)
      })
      .finally(() => console.log('Finalizado'))
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target

    setFormData({
      ...formData, 
      [name] : value
    })
  }

  const handleKeyDown = (types) => {
    const emailRegex = /^\S+@\S+\.\S+$/;
    const numberRegex = /[0-9]/;
    const errors = {};
    setLoaded(true)
  
    types.forEach(type => {
      switch(type) {
        case 'fullName':
          if(!formData.fullName) {
            errors.fullName = 'Nombre es requerido';
          } else if(formData.fullName.length < 2) {
            errors.fullName = 'Nombre debe tener al menos 2 caracteres';
          } else {
            errors.fullName = ''
          }
          break;
        case 'email':
          if(!formData.email) {
            errors.email = 'Email es requerido';
          } else if(!emailRegex.test(formData.email)) {
            errors.email = 'Email incorrecto';
          } else {
            errors.email = ''
          }
          break;
        case 'phone':
          if(!formData.phone) {
            errors.phone = 'Teléfono es requerido';
          } else if(!numberRegex.test(formData.phone)) {
            errors.phone = 'Teléfono incorrecto';
          } else if(formData.phone.length < 9) {
            errors.phone = 'Teléfono debe tener al menos 9 caracteres';
          } else {
            errors.phone = ''
          }
          break;
        case 'message':
          if(!formData.message) {
            errors.message = 'Mensaje es requerido';
          } else if(formData.message.length < 2) {
            errors.message = 'Mensaje debe tener al menos 2 caracteres';
          } else {
            errors.message = ''
          }
          break;
        default:
      }
    });
  
    setErrorFormData(prevErrors => ({
      ...prevErrors,
      ...errors
    }));

    const errorsVal = Object.values(errors).every(valor => valor === '');
    if(errorsVal) {
      return true
    } else {
      return false
    }
  };

  const handleForm = async () => {
    const isValidate = handleKeyDown(['fullName','email','phone','message']);

    if(isValidate) {
      const buyListFormatted = buyList.map(item => ({
        idArticulo: item.idArticulo,
        urlPhoto: `https://storange-images.s3.amazonaws.com/appsheet/data/StorangeGestionLogistica_5361150/${item.imagenRecogida}`,
        fullNameArticle: `${item.titulo}`,
        price: parseFloat(item.precio)
      }));
      const totalPay =  buyList.reduce((acumulador, item) => acumulador + Number(item.precio), 0)
      const data = {
        "listArticles" : buyListFormatted,
        "totalPay" : totalPay,
        "formData" : formData
      }

      try {
        // const saleData = await sendSale(accessToken, data);
        const saleData = await sendExternalSale(data);
        console.log(saleData)
        setStateFormModal(false)
        setStateSuccessModal(true)
        setBuyList([])
      } catch (error) {
        console.error("Error handling Form:", error);
      }
    } else {
      console.log('validacion fallida', formData, errorformData)
    }
  }

  // useEffect(()=>{
  //   getArticlesFromCart('4');
  // }, [propietario])
  useEffect(()=>{
    getArticlesFromCart('4');
  }, [])

  useEffect(() => {
    const toValidate = Object.keys(formData).filter(key => formData[key] !== '')
    if(loaded) {
      handleKeyDown(toValidate)
    }
  }, [loaded, formData])

  return(    
  <div className="c-catalogo">
    <div className="container">
      <div className="d-lg-flex align-items-center justify-content-between mt-4 mb-4">
        <div>
          <h4 className="c-catalogo__subtitle">Hola! Estoy vendiendo mis articulos por mudanza</h4>
          <h1 className="c-catalogo__title mb-lg-0">Artículos en venta</h1>
        </div>
        <div className='position-relative d-inline-block'>
          <button onClick={toggleFormModal(true)} className="c-btn-car d-flex justify-content-center align-items-center" disabled={!buyList.length}>
            <span data-icon="ph:shopping-cart" className='iconify'></span>
          </button>
          <div className='c-btn-car__number position-absolute d-flex justify-content-center align-items-center'>
            {buyList.length}
          </div>
        </div>
        </div>

        {articulos !== null ? (
            <div>
              <div className='row gy-4'>
                {articulos.map((article, index) => (
                  <div key={index} className='col-12 col-md-6 col-lg-4 col-xl-3'>
                    <ItemCard 
                      article={article} 
                      type="catalogo" 
                      sendItemData={handleDataFromChild} 
                      isListedToBuy={buyList.some(item => item.idArticulo === article.idArticulo)}
                      openGallery={handleGallery}
                    />
                  </div>
                ))}
              </div>
              {/* <div className='c-pagination d-flex justify-content-center align-items-center w-100 mt-5'>
                <button onClick={cargarMasProductos} disabled={centinelPage ? false : true} className='c-button c-button--primary'>
                  Ver más
                </button>

                <div onClick={toggleModal(true)}  className='c-chip'>
                  <span>{articulos.length}</span> / <span>{totalArticles}</span>
                </div>

              </div> */}
            </div>
          ): null}
    </div>

    <Dialog
        open={stateGalleryModal}
        onClose={toggleGalleryModal (false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className='c-modal'>
          <div className='c-modal__content scrool'>
          <div className='c-modal__title mb-3'>
              {chosenItem.titulo}
            </div>
            <ImageCarousel fotos={gallery.map(el => {
              return {
                enlaceRef: el.enlaceRef.replaceAll(" ", "")
              }
            })} fotoRecogida={chosenItem.imagenRecogida} />
          </div>
          <div className='c-modal__footer d-flex align-items-center justify-content-end'>
            <button className='c-button c-button--white-gray' onClick={toggleGalleryModal (false)} >Cerrar</button>
          </div>
        </div>
      </Dialog>
  
    <Dialog
        open={stateFormModal}
        onClose={toggleFormModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className='c-modal'>
          <div className='c-modal__content scrool'>
            <div className='c-modal__title mb-3'>
              Carrito de compras
            </div>

            <div className='c-stepList d-flex flex-column'>
              <div className='c-step'>
                <div className='d-flex align-items-center justify-content-start'>
                  <div className='c-step__number me-2'>
                    1
                  </div>
                  <span className='c-step__label'>Artículos seleccionados</span>
                </div>
                <div className='c-step__description'>
                  Tiene <strong>{buyList.length} artículo(s)</strong> seleccionados: 
                </div>
                <div className='c-detailList'>
                {buyList.map((item, index) => (
                  <div key={index} className='c-detailList__item d-flex align-items-center justify-content-center'>
                  <figure className='c-detailList__figure m-0'>
                    <img src={item.imagenRecogida !== null
                      ? `https://storange-images.s3.amazonaws.com/appsheet/data/StorangeGestionLogistica_5361150/${item.imagenRecogida}`
                      : 'https://pad.minem.gob.pe/Proyecta_CMS/Recursos/ProyectoImg/SinImagen.png'} alt={item.titulo} className='w-100 h-100 object-fit-contain' />
                  </figure>
                  <div className='c-detailList__data d-flex align-items-center justify-content-between'>
                    <span>{item.titulo}</span>
                    <span>S/ {item.precio}</span>
                  </div>
                  </div>
                ))}

                </div>
                <div className='c-detailTotal d-flex align-items-center justify-content-between'>
                  <span>
                    <span data-icon="ep:success-filled" className='iconify'></span>
                    <span className='ms-2'>Total</span>
                  </span>
                  <span>S/ {buyList.reduce((acumulador, item) => acumulador + Number(item.precio), 0)}</span>
                </div>
              </div>

              <div className='c-step'>
                <div className='d-flex align-items-center justify-content-start'>
                  <div className='c-step__number  me-2'>
                    2
                  </div>
                  <span className='c-step__label'>Datos de contacto</span>
                </div>
                <div className='c-step__description'>
                  Llena tus datos y ponte en contacto directo con el  vendedor
                </div>

                <div className='c-form'>
                  <div className='row gy-2'>
                    <div className='col-12 col-lg-6'>
                      <div className={`c-input-group ${errorformData.fullName ? 'error' : ''}`}>
                        <label>Nombre</label>
                        <input 
                          name="fullName"
                          value={formData.fullName} 
                          onChange={handleInputChange}
                          onBlur={handleInputChange}
                          type="text" 
                          placeholder='Nombre'/>
                        <span className='c-error'>{errorformData.fullName}</span>
                      </div>
                    </div>
                    <div className='col-12 col-lg-6'>
                      <div className={`c-input-group ${errorformData.email ? 'error' : ''}`}>
                        <label>Email</label>
                        <input
                          name="email"
                          value={formData.email} 
                          onChange={handleInputChange}
                          onBlur={handleInputChange}
                          type="email" 
                          placeholder='Email'/>
                        <span className='c-error'>{errorformData.email}</span>
                      </div>
                    </div>
                    <div className='col-12'>
                      <div className={`c-input-group ${errorformData.phone ? 'error' : ''}`}>
                        <label>Telefono</label>
                        <input 
                          name="phone"
                          value={formData.phone} 
                          onChange={handleInputChange}
                          onBlur={handleInputChange}
                          type="tel" 
                          placeholder='Teléfono'/>
                        <span className='c-error'>{errorformData.phone}</span>
                      </div>
                    </div>
                    <div className='col-12'>
                      <div className={`c-input-group ${errorformData.message ? 'error' : ''}`}>
                        <label>Mensaje</label>
                        <textarea 
                          name="message"
                          value={formData.message}
                          onChange={handleInputChange}
                          onBlur={handleInputChange}
                          placeholder='Mensaje'></textarea>
                        <span className='c-error'>{errorformData.message}</span>
                      </div>
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>

          </div>
          <div className='c-modal__footer d-flex align-items-center justify-content-end'>
            <button className='c-button c-button--white-gray' onClick={toggleFormModal(false)} >Cerrar</button>
            <button className='c-button c-button--primary ms-3' onClick={handleForm}>Finalizar</button>
          </div>
        </div>
      </Dialog>

    <Dialog
      open={stateSuccessModal}
      onClose={toggleSuccessModal(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <div className='c-modal c-modal--alert'>
        <div className='c-modal__content'>
          <div className='c-modal__icon c-modal__icon--success'>
            <span data-icon="ep:success-filled" className='iconify'></span>
          </div>
          <div className='c-modal__title mb-3'>
            Tus datos han sido enviados!
          </div>
          <p className='c-modal__description'>
            La información a sido enviado al venderdor, muy pronto se comunicará contigo!
          </p>
        </div>
        <div className='c-modal__footer d-flex align-items-center justify-content-end'>
          <button className='c-button c-button--white-gray' onClick={toggleSuccessModal(false)} >Cerrar</button>
        </div>
      </div>
    </Dialog>
  </div>)
}

export default Catalogo

