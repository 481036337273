import React, { createContext, useContext, useState } from 'react'
import env from "react-dotenv";
import { GlobalContext } from "./GlobalStateContext";
import ArticleContext from './ArticleContext';

const OrdenContext = createContext('');

const OrdenProvider = ({ children, props }) => {

  const { idPropietario, setFormEnvioPage, accessToken } = useContext(GlobalContext)
  const { carrito, buscarArticulos } = useContext(ArticleContext)
    
  const { encodePetition } = useContext(GlobalContext)

  const [ordenesEnProgreso, setOrdenesEnProgreso] = useState(null)
  const [direccionSelect, setDireccionSelect] = useState("default")
  const [formEnvioPage] = useState(2)

  
  const [oe, setOe] = useState({
    m3: '',
    direccion: '',
    direccionName: '',
    tipoServicio: 'normal',
    fecha: new Date(Date.now()).toISOString().slice(0,10),
    total: '',
    fechaServicio: new Date(Date.now()).toISOString().slice(0,10)
  })

  const buscarOrdenes = () => {
    let details = { idPropietario }

    fetch(env.API_URL+"/obtenerOGL", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        "Authorization": "Bearer "+accessToken
      },
      body: encodePetition(details)
    })
    .then(res => res.json())
    .then(data => {
      setOrdenesEnProgreso(data)
    })
  }

  const actualizarOrdenes = () => {
    let details = { idPropietario }
    fetch(env.API_URL+"/actualizarOrdenes", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        "Authorization": "Bearer "+accessToken
      },
      body: encodePetition(details)
    })
      .then(() => {
        setFormEnvioPage(-1)
        buscarArticulos()
        buscarOrdenes()
        setOe({
          m3: '',
          direccion: '',
          direccionName: '',
          tipoServicio: 'normal',
          fecha: new Date(Date.now()).toISOString().slice(0,10),
          total: '',
          fechaServicio: new Date(Date.now()).toISOString().slice(0,10)
        })
      })
  }

  const crearDetalleOGL = (idOGL, idArticulo) =>{
    let details = {idOGL, idArticulo}

    return fetch(env.API_URL+"/crearDetalleOGL", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        "Authorization": "Bearer "+accessToken
      },
      body: encodePetition(details)
    })
  }

  const crearOGL = (idServicio) =>{
    let details = {
      idPropietario: idPropietario,
      idServicio,
      direccion: oe.direccion,
      volumen: carrito.map(articulo => articulo.volumen).reduce((a, b) => parseFloat((a*1 + b*1).toPrecision(2)))
    }

    fetch(env.API_URL+"/crearOGL", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        "Authorization": "Bearer "+accessToken
      },
      body: encodePetition(details)
    })
    .then(res => res.json())
    .then(data => {
      let promises = []
      carrito.forEach(art => {
        promises.push(crearDetalleOGL(data.idOGL, art.idArticulo))
      })
      Promise.all(promises).then(() => {
        fetch(env.API_URL+"/ogl/mail", {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
            "Authorization": "Bearer "+accessToken
          },
          body: encodePetition({idOGL:data.idOGL})
        })
      }
      )
    })
  }

  const obtenerServicio = () =>{
    let details = {
      fechaServicio: oe.fecha
    }
    fetch(env.API_URL+"/obtenerServicio", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        "Authorization": "Bearer "+accessToken
      },
      body: encodePetition(details)
    })
    .then(res => res.json())
    .then(data => {
      if(data.length > 0) crearOGL(data[0].idServicio)
      else crearServicio()
    })
  }

  const crearServicio = () =>{
    let details = {
      fechaServicio: oe.fecha
    }
    fetch(env.API_URL+"/crearServicio", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        "Authorization": "Bearer "+accessToken
      },
      body: encodePetition(details)
    })
    .then(() => obtenerServicio())
  }
  

  const object = {
    oe,
    setOe,
    ordenesEnProgreso,
    actualizarOrdenes,
    formEnvioPage,
    setFormEnvioPage,
    obtenerServicio,
    buscarOrdenes,
    direccionSelect,
    setDireccionSelect
  };


  return (
      <OrdenContext.Provider value={object}>{children}</OrdenContext.Provider>
  );
};

export { OrdenProvider };
export default OrdenContext;
