import React, { createContext, useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import env from "react-dotenv";
import { GlobalContext } from "./GlobalStateContext";

const AuthContext = createContext('');

const AuthProvider = ({ children, props }) => {

  const { encodePetition, setUserIsTrusted, setIdPropietario, setAccessToken, setSwalCambio } = useContext(GlobalContext)

  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(false)

  const [email, setEmail] = useState('')
  const [emailAction, setEmailAction] = useState(false)

  const [password, setPassword] = useState('')
  const [passwordAction, setPasswordAction] = useState(false)

  const [credentialsError, setCredentialsError] = useState(false)

  const [error, setError] = useState('Usuario incorrecto, revise el email o contraseña ingresada')

  const handleChange = (evt) => {
    const { name, value } = evt.target

    if (name === 'email') {
      setEmail(value)
      setEmailAction(true)
    } else if (name === 'password') {
      setPassword(value)
      setPasswordAction(true)
    }
  }

  const verifyUser = () => {
    let details = { email, password }

    setIsLoading(true)

    fetch(env.API_URL+"/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
      body: encodePetition(details)
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.idPropietario) {
          setUserIsTrusted(true)
          setSwalCambio(true)
          navigate('/dashboard')
          setIdPropietario(res.idPropietario)
          localStorage.setItem('trustedUser', res.idPropietario)
          setAccessToken(res.accessToken)
          localStorage.setItem('t', res.accessToken)
        }
        else {
          setError('Usuario incorrecto, revise el email o contraseña ingresada')
          setCredentialsError(true)
          setUserIsTrusted(false)
        }
      })
      .catch(() =>{
        Swal.fire({
          title: 'Ups! Ocurrió un error con el servidor',
          text: 'Intente nuevamente en unos momentos',
          icon: 'error'
        })
      })
      .finally(() => setIsLoading(false))
  }

  const createUser = (newUser) =>{
    fetch(env.API_URL+"/crearUsuario", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
      body: encodePetition(newUser)
    }).then( () => verifyUser())
  }

  const verifyExistence = () =>{
    let details = { email }

    fetch(env.API_URL+"/verificarUsuarioExistente", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
      body: encodePetition(details)
    }).then( res => res.json())
    .then(data => {
      if(data.length > 0){
        data[0].uIdPropietario === null
        ? createUser({
          idPropietario: data[0].pIdPropietario,
          email: data[0].email,
          documento: data[0].documento
        })
        : verifyUser()
      }else{
        setError('El correo ingresado no se encuentra entre nuestros registros')
        setCredentialsError(true)
      }
    })
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      verifyExistence();
    }
  };


  const object = {
      email,
      emailAction,
      handleChange,
      handleKeyDown,
      credentialsError,
      error,
      passwordAction,
      password,
      verifyExistence,
      isLoading
  };


  return (
      <AuthContext.Provider value={object}>{children}</AuthContext.Provider>
  );
};

export { AuthProvider };
export default AuthContext;
