import React, {useState, useContext, useEffect, useRef} from "react"
import env from "react-dotenv";
import { GlobalContext } from "../../context/GlobalStateContext";
import PropietarioContext from '../../context/PropietarioContext';

import Dialog from '@mui/material/Dialog';
import MenuItem from '@mui/material/MenuItem';

import { LocalizationProvider, StaticDatePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { TextField } from '@mui/material'

import Select from '@mui/material/Select';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { getArticlesFromCart, enviarACarrito, getAdressList, addAdress, getMoreArticles } from "../../servicios/item";
import ItemCard from "../Dashboard/ItemCard/ItemCard"

// import PlacesAutocomplete from "../PlacesAutocomplete/PlacesAutocomplete";
// import { GoogleMap, MarkerF } from "@react-google-maps/api";
import AddAddressForm from "../AddAddressForm/AddAddressForm";


const CarritoEnvio = () => {
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 180,
      },
    },
  };
  const isWeekend = (date) => {
    const day = date.day()
    return day === 0 || day === 6 || date.$d < new Date(Date.now())
  }

  const { accessToken } = useContext(GlobalContext)
  const { propietario } = useContext(PropietarioContext)

  const [articulos, setArticulos] = useState([])

  const [addressList, setAddressList] = useState([])
  const [addressObject, setAddressObject] = useState({})
  // const [loaded, setLoaded] = useState(false);
  const [newAddressData, setNewAddressData] = useState({
    address: '',
    reference: '',
    coordinates: {
      lat: '',
      lng: ''
    }
  })

  const [addressModal, setAddressModal ] = useState(false)
  const [moreItemsModal, setMoreItemsModal ] = useState(false)
  const [responseModal, setResponseModal ] = useState(false)
  const [responseDataModal, setResponseDataModal ] = useState({
    type: '',
    title: '',
    message: ''
  })

  const [selectedItems, setSelectedItems] = useState([]);
  const [moreItems, setMoreItems] = useState([])

  const [sections, setSections] = useState({
    addressSection: false,
    dateSection: false,
    formSection: false
  });

  const [date, setDate] = useState(null)
  const [paymentMethod, setPaymentMethod] = useState('later')
  const [finalBtn, setFinalBtn] = useState(true)
  const culqiContainer = useRef(null)
  
  const getCarData = async () => {
    try {
      const data = await getArticlesFromCart('1', propietario?.idPropietario, accessToken);
      const almacenados = data.filter(article => article.estadoArticulo === 'Almacenado');
      setArticulos(almacenados)
    } catch (error) {
      console.error("Error handling data:", error);
    }
  }

  const getMoreItemsData = async () => {
    try {
      const data = await getMoreArticles('1', propietario?.idPropietario, accessToken);
      const almacenados = data.filter(article => article.estadoArticulo === 'Almacenado');
      setMoreItems(almacenados)
    } catch (error) {
      console.error("Error handling data:", error);
    }
  }

  const handleGetAdressList = async () => {
    try {
      const data = await getAdressList(propietario?.idPropietario, accessToken);
      setAddressList(data)
      if(newAddressData.address) {
        setAddressObject(data.find(objeto => objeto.direccion === newAddressData.address))
        setNewAddressData({
          address: '',
          reference: '',
          coordinates: {
            lat: '',
            lng: ''
          }
        })
      }
    } catch (error) {
      console.error("Error handling data:", error);
    }
  }

  const deleteItemFromCar = (data) => {
    setPaymentMethod('')
    enviarACarrito(data.idArticulo, 0, accessToken)
    setArticulos(articulos.filter(item => item.idArticulo !== data.idArticulo))
  }

  const toggleMoreItemsModal = (value) => () => {
    setMoreItemsModal(value);
    if(!value) {
      setSelectedItems([])
    }
  }

  const toggleAddressModal = (value) => () => {
    setAddressModal(value);
  }

  const toggleResponseDataModal = (type, title, message) => {
    setResponseDataModal({
      type: type,
      title: title,
      message: message
    });
  }

  const selectAllItems = () => {
    const allCheckboxIds = moreItems.map((item) => String(item.idArticulo));
    setSelectedItems(allCheckboxIds);
  }

  const handleCheckboxChange = (event) => {
    const checkboxId = event.target.id;
    const isChecked = event.target.checked;

    if (isChecked) {
      // Add
      setSelectedItems((prevIds) => [...prevIds, checkboxId]);
    } else {
      // Remove
      setSelectedItems((prevIds) => prevIds.filter((id) => id !== checkboxId));
    }
  };

  const isCheckboxSelected = (itemId) => {
    return selectedItems.includes(String(itemId));
  };

  const addItemsToCar = () => {
    enviarACarrito(selectedItems, '1', accessToken)
    const itemsToAdd = moreItems.filter(item => selectedItems.includes(item.idArticulo));
    const filterMoreItems = moreItems.filter(item => !selectedItems.includes(item.idArticulo))
    setArticulos([...articulos, ...itemsToAdd ]) 
    setMoreItems(filterMoreItems) 
    setSelectedItems([])
    setMoreItemsModal(false);
  }

  const handleAddressChange = (event) => {
    const { target: { value }, } = event;
    setAddressObject(value)
  }

  const showSection = (type) => {
    setSections(prevSections => ({
      ...prevSections,
      [type]: true
    }));
  }

  const loadScripts = () => {
    const culqiScript = document.createElement("script");

    culqiScript.setAttribute("src", "https://js.culqi.com/checkout-js");

    document.head.appendChild(culqiScript);
  };

  const startCulqi = (orderNumber) => {
    const overlay = document.getElementById('c-loading')
    overlay.classList.remove('d-none')
    window.setTimeout(() => {
      overlay.classList.add('d-none')
      window.scrollTo({
        top: window.scrollY + 400,
        behavior: 'smooth'
      });
      const settings = {
          title: 'Culqi  store 2',
          currency: 'PEN',
          amount: articulos.reduce((total, item) => total + item.monto, 0) * 100,
      }

      const paymentMethods = {
            tarjeta: true,
        }

      const options = {
        lang: 'auto',
        installments: true,
        modal: false,
        container: "#culqi-container",
        paymentMethods: paymentMethods,
        paymentMethodsSort: Object.keys(paymentMethods),
      }

      const client = {
        email: propietario.email,
      }

      const appearance = {
          theme: "default",
          hiddenCulqiLogo: false,
          hiddenBannerContent: true,
          hiddenBanner: false,
          hiddenToolBarAmount: false,
          menuType: "sidebar",
          buttonCardPayText: "Pagar",
          logo: 'https://sapiens.innovdevelopers.com/wp-content/themes/edtech/app/static/public/assets/logo-footer-4d7f56d8.png',
          defaultStyle: {
          bannerColor: "blue",
          buttonBackground: "yellow",
          menuColor: "pink",
          linksColor: "green",
          buttonTextColor: "blue",
          priceColor: "red",
          },
          variables: {
          fontFamily: "monospace",
          fontWeightNormal: "500",
          borderRadius: "8px",
          colorBackground: "#0A2540",
          colorPrimary: "#EFC078",
          colorPrimaryText: "#1A1B25",
          colorText: "white",
          colorTextSecondary: "white",
          colorTextPlaceholder: "#727F96",
          colorIconTab: "white",
          colorLogo: "dark",
          soyUnaVariable: "blue",
          },
          rules: {
            ".Culqi-Main-Container": {
                background: "#fff",
                fontFamily: "var(--fontFamily)",
            },
            ".Culqi-ToolBanner": {
                background: "#F94700",
                fontFamily: "var(--fontFamily)",
                color: "white",
            },
            ".Culqi-Toolbar-Price": {
                color: "#494949",
                fontFamily: "var(--fontFamily)",
            },
            ".Culqi-Toolbar-Price .Culqi-Icon": {
                color: "blue",
            },
            ".Culqi-Main-Method": {
                background: "#fff",
                padding: "10px 20px",
                color: "#494949",
            },
            ".Culqi-Text-Link": {
                color: "red",
            },
            ".Culqi-Text-Link .Culqi-Icon": {
                color: "blue",
            },
            ".Culqi-message": {
                color: "#494949",
            },
            ".Culqi-message .Culqi-Icon": {
                color: "#494949",
            },
            ".Culqi-message-warning": {
                background: "yellow",
                color: "red",
            },
            ".Culqi-message-info": {
                background: "white",
                color: "black",
            },
            ".Culqi-message-error": {
                background: "black",
                color: "pink",
            },
            ".Culqi-message-error .Culqi-Icon": {
                color: "yellow",
            },
            ".Culqi-Label": {
                color: "black",
                fontWeight: "700",
                marginBottom: "20px",
            },
            ".Culqi-Input": {
                border: "1px solid black",
                color: "black",
            },
            ".Culqi-Input:focus": {
                border: "2px solid black",
            },
            ".Culqi-Input.input-valid": {
                background: "#e2e2e2",
            },
            ".Culqi-Input-Icon-Spinner": {
                color: "red",
            },
            ".Culqi-Input-Select": {
                border: "1px solid black",
                color: "black",
            },
            ".Culqi-Input-Select-Options-Hover": {
                color: "red",
                background: "black",
            },
            ".Culqi-Input-Select-Selected":{
                color: "green",
            },
            ".Culqi-Input-Select.active": {
                border: "1px solid red",
                background: "pink",
            },
            ".Culqi-Input-Select-Options": {
                background: "gray",
            },
            ".Culqi-Button": {
                background: "red",
            },
            ".Culqi-Menu": {
                color: "blue",
            },
            ".Culqi-Menu .Culqi-Icon": {
                color: "green",
            },
            ".Culqi-Menu-Selected": {
            },
            ".Culqi-Menu-Selected .Culqi-Icon": {
                color: "red",
            },
            ".Culqi-Menu-Options": {
                background: "orange",
            },
            ".Culqi-Menu-Options-Hover": {
                background: "green",
                color: "red",
            },
            ".Culqi-Menu-Options-Hover .Culqi-Icon": {
                color: "blue",
            },
          },
      };

      const handleCulqiAction = () => {
        if (Culqi.token) {
          handCheckout(Culqi.token.id, orderNumber)
        } else {
          const errorMessage = Culqi.error
        }
      }

      const config = {
        settings,
        client,
        options,
        appearance,
      };

      const Culqi = new CulqiCheckout('pk_test_07e6c53c37ad8044', config);
      Culqi.culqi = handleCulqiAction;
      Culqi.open()
    }, 2000);
  };

  const handCheckout = (token, orderNumber) => {
    const overlay = document.getElementById('c-overlay')
    overlay.classList.remove('d-none')
    let message

    if(token) {
      try {
        const request = env.API_URL+"/payments";
        fetch(request, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: "Bearer "+accessToken,
          },
          body: JSON.stringify({
            // items: articulos, //falta
            // address: addressObject, //falta
            // data : date, //falta

            token ,
            amount: (articulos.reduce((total, item) => total + item.monto, 0))*100, //no se deberia mandar por fEnd
            currencyCode : "PEN",
            email: propietario.email,
            description : "Camiseta prueba 2 culqui", // ??
            idOGL : orderNumber 
          }),
        })
          .then((response) => {
            // if (response.status === 201 || response.status < 300) {
            if (response.status === 200) {
              return response.json();
            }
            return response.text().then((text) => { throw new Error(text); });
          })
          .then((data) => {
            console.log(data, 'data')
            if (data.status) {
              overlay.classList.add('d-none')
              window.location.href = `/envio/${orderNumber}/success`
            } else {
              overlay.classList.add('d-none')
              message = `${data.message} (status: ${data.code})`
              toggleResponseDataModal('error', 'Error al generar la orden', message)
              setResponseModal(true)
            }
          })
          .catch((error) => {
            overlay.classList.add('d-none')
            try {
              message = JSON.parse(error.message)?.message;
            } catch (err) {
              message = 'Error al procesar la compra, intentelo nuevamente';
            }
            toggleResponseDataModal('error', 'Error al generar la orden', message)
            setResponseModal(true)
          });
      } catch (err) {
        overlay.classList.add('d-none')
        if (err) {
          message = 'No se logró continuar con la compra, vuelva a intentarlo por favor.'
          toggleResponseDataModal('error', 'Error al generar la orden', message)
          setResponseModal(true)
        }
      }
    } else {
      console.log('no token')
    }
  }

  const obtenerServicio = () =>{
    const request = env.API_URL+"/obtenerServicio"
    fetch(request, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        "Authorization": "Bearer "+accessToken
      },
      body: JSON.stringify({
        fechaServicio: date
      }),
    })
    .then(res => res.json())
    .then(data => {
      if(data.length > 0) createOrder(data[0].idServicio)
      else crearServicio()
    })
  }

  const crearServicio = () =>{
    const request = env.API_URL+"/crearServicio"
    fetch(request, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        "Authorization": "Bearer "+accessToken
      },
      body: JSON.stringify({
        fechaServicio: date
      }),
    })
    .then(() => obtenerServicio())
  }

  const createOrder = (idServicio) => {
    const overlay = document.getElementById('c-loading')
    overlay.classList.remove('d-none')
    let message;

    try {
      const request = env.API_URL_V2+"/crearOGL";
      // const request = "http://localhost:4000/api/v2"+"/crearOGL";
      fetch(request, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: "Bearer "+accessToken,
        },
        body: JSON.stringify({
          idPropietario: propietario.idPropietario, 
          idServicio: idServicio, // ?
          idArticulos: articulos.map(articulo => articulo.idArticulo), 
          direccion: addressObject.direccion, 
          volumen: articulos.reduce((total, item) => total + parseFloat(item.volumen), 0),
          fecha : new Date(Date.now()).toISOString().slice(0,10),
          tipo : "EAR" //carrito de envio
        }),
      })
        .then((response) => {
          if (response.status === 201 || response.status < 300) {
            return response.json();
          }
          return response.text().then((text) => { throw new Error(text); });
        })
        .then((data) => {
          if (data.idOGL) {
              let order = data.idOGL

              if(paymentMethod === 'now') {
                overlay.classList.add('d-none')
                setFinalBtn(false)
                culqiContainer.current.classList.remove('d-none')
                startCulqi(order)
              } else {
                  setTimeout(()=> {
                    overlay.classList.add('d-none')
                    window.location.href = `/envio/${order}/success`
                  },2000)
                }
            
          } else {
            overlay.classList.add('d-none')
            message = `${data.message} (status: ${data.code})`
            toggleResponseDataModal('error', 'Error al generar la orden', message)
            setResponseModal(true)
          }
        })
        .catch((error) => {
          overlay.classList.add('d-none')
          try {
            message = JSON.parse(error.message)?.message;
          } catch (err) {
            message = 'Error al procesar la compra, intentelo nuevamente';
          }
          toggleResponseDataModal('error', 'Error al generar la orden', message)
          setResponseModal(true)
        });
    } catch (err) {
      overlay.classList.add('d-none')
      if (err) {
        message = 'No se logró continuar con la compra, vuelva a intentarlo por favor.'
        toggleResponseDataModal('error', 'Error al generar la orden', message)
        setResponseModal(true)
      }
    }
  }

  useEffect(()=> {
    loadScripts()
  }, [])

  useEffect(() => {
    getCarData()
    handleGetAdressList()
  }, [propietario])

  useEffect(()=>{
    if(moreItemsModal) {
      getMoreItemsData()
    } else {
      setMoreItems([])
    }
  }, [moreItemsModal])

  useEffect(()=>{
    if(!addressModal) {
      handleGetAdressList();
    }
  }, [addressModal])

  useEffect(()=>{
    setFinalBtn(true)
  }, [paymentMethod])

  return(
  <div className="c-sendcar c-catalogo">
    <div className="container">
      <div className="d-lg-flex align-items-center justify-content-between mt-4 mb-4">
        <div>
          <h4 className="c-catalogo__subtitle">Preslistado de articulos para una orden de envio</h4>
          <h1 className="c-catalogo__title mb-lg-0">Artículos para envio</h1>
        </div>
      </div>
      <div className='c-step'>
        <div className="d-lg-flex align-items-center justify-content-between  mb-1">
          <div className='d-flex align-items-center justify-content-start mb-3 mb-lg-0'>
            <div className='c-step__number me-2'>
              1
            </div>
            <span className='c-step__label'>Selecciona los artículos para enviar </span>
          </div>

          <button onClick={toggleMoreItemsModal(true)} className="c-button c-button--primary-ghost">
            Agregar más artículos
          </button>
        </div>
      </div>
      {articulos.length > 0 &&
        <div className='c-stepList d-flex flex-column'>
          <div className='c-step'>
            {articulos !== null ? (
              <div className={`${articulos.length > 8 ? 'c-scrool' : ''}`}>
                <div className='row gy-4'>
                  {articulos.map((article, index) => (
                    <div key={index} className='col-12 col-md-6 col-lg-4 col-xl-3'>
                      <ItemCard 
                        article={article} 
                        sendItemData={deleteItemFromCar}
                        type="lista" 
                      />
                    </div>
                  ))}
                </div>
              </div>
            ): null}

            <div className="text-end mt-3">
              <button onClick={()=> showSection('addressSection')} className="c-button c-button--primary">
                Siguiente
              </button>
            </div>
          </div>
    
          {sections.addressSection &&
            <div className='c-step'>
              <div className='d-flex align-items-center justify-content-start mb-1'>
                <div className='c-step__number me-2'>
                  2
                </div>
                <span className='c-step__label'> Direccion de envio  </span>
              </div>
              <div className='c-step__description'>
                Selecciona la dirección donde se enviaran los articulos
              </div>
              <div className="c-select my-3">
                <Select
                  displayEmpty
                  value={addressObject}
                  onChange={(event) => handleAddressChange(event)}
                  MenuProps={MenuProps}
                  IconComponent={ExpandMoreIcon}
                  renderValue={(selected) => {
                    if (!selected || Object.keys(selected).length === 0) {
                      return <span>Direcciones</span>;
                    }

                    return selected.direccion;
                  }}
                  inputProps={{ 'aria-label': 'Without label' }}
                >
                  {addressList.map((state) => (
                    <MenuItem
                      key={state.idDireccion}
                      value={state}
                    >
                      {state.direccion}
                    </MenuItem>
                  ))}
                </Select>
              </div>
              <div>
                <button onClick={toggleAddressModal(true)} className="c-button c-button--primary-ghost c-add-btn">
                  Agregar nueva dirección
                </button>
              </div>
              <div className="text-end mt-3">
                <button onClick={()=> showSection('dateSection')} disabled={addressObject?.direccion ? false  : true} className="c-button c-button--primary">
                  Siguiente
                </button>
              </div>
            </div>
          }

          {sections.dateSection &&
            <div className='c-step'>
              <div className='d-flex align-items-center justify-content-start mb-1'>
                <div className='c-step__number me-2'>
                  3
                </div>
                <span className='c-step__label'> Fecha de envio  </span>
              </div>
              <div className='c-step__description'>
                Selecciona la fecha donde se enviaran los articulos
              </div>
              <div className="c-calendar">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <StaticDatePicker
                      // openTo="day"
                      value={date}
                      shouldDisableDate={isWeekend}
                      renderInput={(params) => <TextField {...params} />}
                      onChange={(newValue) => {
                        setDate(newValue.format('YYYY-MM-DD'))
                      }}
                  />
                </LocalizationProvider>
              </div>
              <div className="text-end mt-3">
                <button onClick={()=> showSection('formSection')} disabled={date ? false : true }className="c-button c-button--primary">
                  Siguiente
                </button>
              </div>
            </div>
          }

          {sections.formSection &&
            <div className='c-step'>
              <div className='d-flex align-items-center justify-content-start mb-1'>
                <div className='c-step__number me-2'>
                  4
                </div>
                <span className='c-step__label'> Selecciona la forma de pago </span>
              </div>
              <div className='c-step__description mb-3'>
                Verifica los datos  y selecciona el metodo de pago
              </div>
              <div className="c-resume mb-3">
                <div className='c-step__description mb-3'>
                  Tiene <strong>{articulos.length} artículo(s)</strong> seleccionados: 
                </div>
                {articulos !== null ? (
                  <div className='c-detailList mb-3'>

                    {articulos.map((article, index) => (
                      <div key={index} className='c-detailList__item d-flex align-items-center justify-content-center'>
                        <figure className='c-detailList__figure m-0'>
                          <img src={article.imagenRecogida !== null
                            ? `https://storange-images.s3.amazonaws.com/appsheet/data/StorangeGestionLogistica_5361150/${article.imagenRecogida}`
                            : 'https://pad.minem.gob.pe/Proyecta_CMS/Recursos/ProyectoImg/SinImagen.png'} alt={article.titulo} className='w-100 h-100 object-fit-cover' />
                        </figure>
                        <div className='c-detailList__data d-flex align-items-center justify-content-between'>
                          <span>{article.titulo}</span>
                          {/* <span>S/ {article.monto}</span> */}
                        </div>
                      </div>
                    ))}
                  </div>
                ): null}
                <div className="d-flex flex-column gap-3">
                  <div className='c-detailTotal d-flex align-items-center justify-content-between'>
                    <span>
                      <span data-icon="ep:success-filled" className='iconify'></span>
                      <span className='ms-2'>m<sup>3</sup> totales:</span>
                    </span>
                    <span>{parseFloat(articulos.reduce((total, item) => total + parseFloat(item.volumen), 0).toFixed(2))} m<sup>3</sup></span>
                  </div>
                  <div className='c-detailTotal d-flex align-items-center justify-content-between'>
                    <span>
                      <span data-icon="ep:success-filled" className='iconify'></span>
                      <span className='ms-2'>Fecha de envio:</span>
                    </span>
                    <span>{date}</span>
                  </div>
                  <div className='c-detailTotal d-flex align-items-center justify-content-between'>
                    <span>
                      <span data-icon="ep:success-filled" className='iconify'></span>
                      <span className='ms-2'>Dirección de envio:</span>
                    </span>
                    <span>{addressObject.direccion}</span>
                  </div>
                  <div className='c-detailTotal d-flex align-items-center justify-content-between'>
                    <span>
                      <span data-icon="ep:success-filled" className='iconify'></span>
                      <span className='ms-2'>Total:</span>
                    </span>
                    <span>S/ {articulos.reduce((total, item) => total + parseFloat(item.volumen), 0) < 1 ? 40 : (articulos.reduce((total, item) => total + parseFloat(item.volumen), 0) * 40).toFixed(2) }</span>
                  </div>
                </div>
              </div>
              <div className="c-payOptions d-flex flex-column mt-3">
                <div className="c-payOptions__title text-center">
                  Selecciona la forma de pago:
                </div>
                {
                  false ??
                    <button onClick={()=> setPaymentMethod('now')} className={`c-payOption d-flex align-items-center justify-content-start ${paymentMethod === 'now' ? 'active' : ''}`}>
                      <div className="c-payOption__icon">
                        <span data-icon="mingcute:card-pay-fill" className='iconify'></span>
                      </div>
                      <div className="c-payOption__resume">
                        <h4 className="c-payOption__title">
                          Pagalo ahora
                        </h4>
                        <div className="c-payOption__description">
                          Puedes pagar con Visa, Mastercard, etc
                        </div>
                      </div>
                    </button>
                }
                <button  onClick={()=> setPaymentMethod('later')} className={`c-payOption d-flex align-items-center justify-content-start ${paymentMethod === 'later' ? 'active' : ''}`}>
                  <div className="c-payOption__icon">
                    <span data-icon="mingcute:time-fill" className='iconify'></span>
                  </div>
                  <div className="c-payOption__resume">
                    <h4 className="c-payOption__title">
                      Pagalo después
                    </h4>
                    <div className="c-payOption__description">
                      El monto se cargará en tu siguiente factura
                    </div>
                  </div>
                </button>
              </div>
              {paymentMethod === 'now' ? 
                <div id="culqi-container" ref={culqiContainer} className={`culqi-container mt-4 d-none`}>
                </div> 
              : null}
              
              {finalBtn && 
                <div className={`text-end mt-3`}>
                  <button className="c-button c-button--primary" onClick={() => obtenerServicio()} disabled={!paymentMethod}>
                    {paymentMethod === 'later' ? 'Finalizar' : 'Continuar'}
                  </button>
                </div>
              }
            </div>
          }
        </div>}
    </div>

    <div id="c-overlay" className="c-overlay d-none">
      Enviando...
    </div>

    <div id="c-loading" className="c-overlay d-none">
      Cargando...
    </div>

    <Dialog
        open={moreItemsModal}
        onClose={toggleMoreItemsModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className='c-modal'>
          <div className='c-modal__content'>
            <div className="d-flex align-items-center justify-content-between mb-3">
              <div className='c-modal__title me-3'>
                Selecciona más articulos
              </div>
              <button onClick={selectAllItems} className="c-button c-button--primary-ghost">
                Todos
              </button>
            </div>
            <div className="c-vertical-scroll px-2">
              <div className="w-100">
                { moreItems.map((item, i) => (
                  <div key={i} className="w-100 d-flex align-items-center justify-content-center mb-3 gap-2">
                    <input
                      className='c-checkbox'
                      type="checkbox"
                      id={item.idArticulo} 
                      onChange={handleCheckboxChange}
                      checked={isCheckboxSelected(item.idArticulo)} 
                      />
                    <ItemCard article={item} type="lista" horizontal={true}></ItemCard>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className='c-modal__footer d-flex align-items-center justify-content-end'>
            <button className='c-button c-button--white-gray' onClick={toggleMoreItemsModal(false)} >Cerrar</button>
            <button onClick={() => addItemsToCar()} className='c-button c-button--primary ms-3' >Agregar</button>
          </div>
        </div>
    </Dialog>

    <Dialog
        open={addressModal}
        onClose={toggleAddressModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <AddAddressForm setAddressModal={setAddressModal} setNewAddressDataPerfil={setNewAddressData} />
    </Dialog>

    <Dialog
      open={responseModal}
      onClose={() => setResponseModal(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <div className='c-modal c-modal--alert'>
        <div className='c-modal__content'>

        {responseDataModal.type === 'success' ? (
          <div className='c-modal__icon c-modal__icon--success'>
          <span data-icon="ep:success-filled" className='iconify'></span>
        </div>
        ) : (
          <div className='c-modal__icon c-modal__icon--alert'>
            <span data-icon="mingcute:alert-fill" className='iconify'></span>
          </div>
        )}
          
          <div className='c-modal__title mb-3'>
            {responseDataModal.title}
          </div>
          <p className='c-modal__description'>
            {responseDataModal.message}
          </p>
        </div>
        <div className='c-modal__footer d-flex align-items-center justify-content-end'>
          <button className='c-button c-button--white-gray' onClick={() => setResponseModal(false)} >Cerrar</button>
        </div>
      </div>
    </Dialog>
  </div>)
}

export default CarritoEnvio