import React from 'react'
import OrderRecojo from '../components/OrdenRecojo/OrdenRecojo'

const OrderRecojoView = () => {

  return (
    <div className='articleView'>
        <OrderRecojo/>
    </div>
  )
}

export default OrderRecojoView