import React, { createContext, useContext, useState } from 'react'
import Swal from 'sweetalert2'
import env from "react-dotenv";
import { GlobalContext } from "./GlobalStateContext";
import { toast } from "react-toastify"

const PropietarioContext = createContext('');

const PropietarioProvider = ({ children, props }) => {
  const { setUserIsTrusted, idPropietario, setIdPropietario, setAccessToken, setIsLoading, encodePetition, accessToken } = useContext(GlobalContext)

  const [propietario, setPropietario] = useState(null)
  const [volumen, setVolumen] = useState(null)
  const [direcciones, setDirecciones] = useState(null)
  const [activeDireccionModal, setActiveDireccionModal] = useState(false)

  const [name, setName] = useState('')
  const [nameAction, setNameAction] = useState(false)

  const [apellido, setApellido] = useState('')
  const [apellidoAction, setApellidoAction] = useState(false)

  const [email, setEmail] = useState('')
  const [emailAction, setEmailAction] = useState(false)

  const [phone, setPhone] = useState('')
  const [phoneAction, setPhoneAction] = useState(false)

  const [pass, setPass] = useState('')

  const [newPass, setNewPass] = useState('')
  const [newPassAction, setNewPassAction] = useState(false)

  const [newPassConfirm, setNewPassConfirm] = useState('')

  const handleChange = (evt) => {
    const { name, value } = evt.target

    if (name === 'name') {
      setName(value)
      setNameAction(true)
    } else if (name === 'apellido') {
      setApellido(value)
      setApellidoAction(true)
    } else if (name === 'email') {
      setEmail(value)
      setEmailAction(true)
    } else if (name === 'phone') {
      setPhone(value)
      setPhoneAction(true)
    } else if (name === 'pass') {
      setPass(value)
    } else if (name === 'newPass') {
      setNewPass(value)
      setNewPassAction(true)
    } else {
      setNewPassConfirm(value)
    }
  }

  const actualizarInfo = () => {
    let details = {
      idPropietario: propietario.idPropietario,
      name: name === '' ? propietario.nombre : name,
      apellido: apellido === '' ? propietario.apellido : apellido,
      email: email === '' ? propietario.email : email,
      phone: phone === '' ? propietario.telefono : phone
    }

    if (
      ((propietario !== null && !nameAction) || name !== '') &&
      ((propietario !== null && !apellidoAction) || apellido !== '') &&
      ((propietario !== null && !emailAction) || email !== '') &&
      ((propietario !== null && !phoneAction) || phone !== '')
    ) {
      setIsLoading(true)
      fetch(env.API_URL+"/actualizarPropietario", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
          "Authorization": "Bearer "+accessToken
        },
        body: encodePetition(details)
      })
        .then(() => {
          setIsLoading(false)
          Swal.fire({
            title: 'Actualización exitosa',
            text: 'Los datos fueron actualizados',
            icon: 'success'
          })
        })
    } else {
      Swal.fire({
        title: 'Todos los campos son obligatorios',
        text: 'Intente nuevamente',
        icon: 'info'
      })
    }

  }

  const actualizarContraseña = (at) => {
    if (newPass === newPassConfirm && newPass.length > 6) {
      let details = { idPropietario: propietario.idPropietario, pass: newPass }

      fetch(env.API_URL+"/actualizarContrasena", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
          "Authorization": "Bearer "+at
        },
        body: encodePetition(details)
      })
        .then(() => {
          setIsLoading(false)
          Swal.fire({
            title: 'Actualización exitosa',
            text: 'Los datos fueron actualizados',
            icon: 'success'
          })
          setPass('')
          setNewPass('')
          setNewPassConfirm('')
          setNewPassAction(false)
        })
    } else {
      Swal.fire({
        title: 'Confirmación',
        text: 'Las contraseñas deben coincidir y tener entre 8 a 16 carácteres alfanuméricos',
        icon: 'info'
      })
    }
  }

  const verificarContrasena = () => {
    let details = { email: propietario.email, password: pass }

    setIsLoading(true)
    fetch(env.API_URL+"/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        "Authorization": "Bearer "+accessToken
      },
      body: encodePetition(details)
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.idPropietario) {
          setUserIsTrusted(true)
          setIdPropietario(res.idPropietario)
          localStorage.setItem('trustedUser', res.idPropietario)
          setAccessToken(res.accessToken)
          localStorage.setItem('t', res.accessToken)
          actualizarContraseña(res.accessToken)
          if (propietario.primerCambio === 0) {
            fetch(env.API_URL+"/actualizarPrimerCambio", {
              method: "POST",
              headers: {
                "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
                "Authorization": "Bearer "+res.accessToken
              },
              body: encodePetition({idPropietario: propietario.idPropietario})
            })
          }
        }
        else {
          setIsLoading(false)
          Swal.fire({
            title: 'Contraseña incorrecta',
            text: 'Intente nuevamente',
            icon: 'error'
          })
        }
      })
  }

  
  const buscarPropietario = () => {
    let details = { idPropietario }

    fetch(env.API_URL+"/propietarioPorId", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        "Authorization": "Bearer "+accessToken
      },
      body: encodePetition(details)
    })
      .then((res) => res.json())
      .then((res) => {
        setPropietario(res)
      })

    fetch(env.API_URL+"/propietario/"+idPropietario+"/volumen", {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        "Authorization": "Bearer "+accessToken
      }
    })
      .then((res) => res.json())
      .then((res) => {
        setVolumen(res.volTotal)
      })
  }

  const buscarDireccion = () => {
    let details = { idPropietario }

    fetch(env.API_URL+"/direcciones", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        "Authorization": "Bearer "+accessToken
      },
      body: encodePetition(details)
    })
      .then((res) => res.json())
      .then((res) => {
        setDirecciones(res)
      })
  }

  const borrarDireccion = async (idDireccion) => {
    let details = { idDireccion }

    fetch(env.API_URL+"/borrarDireccion", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        "Authorization": "Bearer "+accessToken
      },
      body: encodePetition(details)
    })
      .then(() => {
        buscarDireccion()
        Swal.fire({
          title: 'Dirección borrada',
          text: 'La dirección se borró correctamente',
          icon: 'success'
        })
      })
  }

  const agregarDireccion = (lat, lng, directionMain, direction, distrito, referencia) => {
    let details = { lat, lng, directionMain, direction, distrito, referencia, idPropietario }
    setIsLoading(true)

    fetch(env.API_URL+"/agregarDireccion", {
      method: "PUT",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        "Authorization": "Bearer "+accessToken
      },
      body: encodePetition(details)
    })
      .then(() => {
        setIsLoading(false)
        setActiveDireccionModal(false)
        buscarDireccion()
        toast("Dirección agregada correctamente")
      })
  }

  const object = {
    volumen,
    direcciones,
    buscarDireccion,
    buscarPropietario,
    handleChange,
    propietario,
    setPropietario,
    name,
    nameAction,
    apellido,
    apellidoAction,
    email,
    emailAction,
    phone,
    phoneAction,
    actualizarInfo,
    verificarContrasena,
    pass,
    newPass,
    newPassAction,
    newPassConfirm,
    activeDireccionModal,
    setActiveDireccionModal,
    borrarDireccion,
    agregarDireccion
  };


  return (
      <PropietarioContext.Provider value={object}>{children}</PropietarioContext.Provider>
  );
};

export { PropietarioProvider };
export default PropietarioContext;
