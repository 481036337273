import { useState, useEffect } from "react";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from "@reach/combobox";
import "./Places.css";
import { TextField, Button, FormControl, FormHelperText, InputLabel, MenuItem } from '@mui/material'
import Select from '@mui/material/Select';
import { withStyles, makeStyles } from '@mui/styles'
import { useContext } from "react";
import PropietarioContext from "../../context/PropietarioContext";

const useStyles = makeStyles({
  button: {
    '&.Mui-disabled': {
      backgroundColor: '#E5E5E5 !important',
      color: '#ABABAB !important'
    },
    backgroundColor: '#f94700 !important',
    textTransform: 'none !important',
    fontWeight: 'bold !important',
    color: '#fff !important',
    width: '300px !important',
    margin: '20px 0 !important',
    height: 'fit-content !important'
  }
})

const CssTextField = withStyles({
  root: {
      '&': {
          margin: '10px 0 0 !important'
      },
      '& p': {
          textAlign: 'left',
          fontSize: '12px',
          color: '#ff5252 !important'
      },
      '& label': {
          fontSize: '16px'
      },
      '& label.Mui-focused': {
          color: '#ff5252 !important',
      },
      '& .MuiInput-underline:before': {
          borderBottom: '1px solid #000 !important'
      },
      '& .MuiInput-underline:after': {
          borderBottom: '1px solid #ff5252 !important',
      },
      '& .MuiOutlinedInput-root': {
          '& fieldset': {
              borderColor: 'black'
          },
          '&:hover fieldset': {
              borderColor: 'black'
          },
          '&.Mui-focused fieldset': {
              borderColor: '#ff5252 !important'
          },
      },
  },
})(TextField);

const CssFormControl = withStyles({
  root: {
      '&': {
          margin: '10px 0 0 !important'
      },
      '& p': {
          textAlign: 'left',
          fontSize: '12px',
          color: '#ff5252 !important'
      },
      '& label': {
          fontSize: '16px'
      },
      '& label.Mui-focused': {
          color: '#ff5252 !important',
      },
      '& .MuiInput-underline:before': {
          borderBottom: '1px solid #000 !important'
      },
      '& .MuiInput-underline:after': {
          borderBottom: '1px solid #ff5252 !important',
      },
      '& .MuiOutlinedInput-root': {
          '& fieldset': {
              borderColor: 'black'
          },
          '&:hover fieldset': {
              borderColor: 'black'
          },
          '&.Mui-focused fieldset': {
              borderColor: '#ff5252 !important'
          },
      },
  },
})(FormControl);


const options = {
  googleMapsApiKey: 'AIzaSyDoOT7LtQLIIuG8E9W1OJLj7G4rmRbHFz4',
  libraries: ["places"],
  id: 'ChIJ9RRZwFDIBZERSAYheRIBnvI',
  region: 'PE'
}

const Places = () => {
  const [selected, setSelected] = useState(null)
  const [direction, setDirection] = useState(null)
  const [directionMain, setDirectionMain] = useState(null)
  const [directionMainAction, setDirectionMainAction] = useState(null)
  const [distrito, setDistrito] = useState('')
  const [distritoAction, setDistritoAction] = useState(null)
  const [referencia, setReferencia] = useState(null)
  

  const { isLoaded } = useLoadScript(options)
  const { direcciones, agregarDireccion } = useContext(PropietarioContext)
  const classes = useStyles()
  

  const handleChange = (evt) => {
    const { name, value } = evt.target

    if (name === 'directionMain') {
      setDirectionMain(value)
      setDirectionMainAction(true)
    } else if (name === 'referencia') {
      setReferencia(value)
    } else if (name === 'distrito') {
      setDistrito(value)
      setDistritoAction(true)
    }
  }

  useEffect(() => {
    
  }, [selected, direcciones])

  return (
    <>
      {isLoaded && direcciones !== null ? (
        <>
          <div className="places-container">
            <CssTextField
              type={'text'}
              label="Direccion"
              variant="outlined"
              name="directionMain"
              size='small'
              onChange={handleChange}
              onBlur={handleChange}
              error={directionMain === '' && directionMainAction}
              helperText={directionMain === '' && directionMainAction ? 'Direccion requerida' : ' '}
              value={directionMain}
              style={{ marginBottom: '16px !important', width: '100%' }}
            />
            <CssFormControl fullWidth>
              <InputLabel id="distrito-select-label"
              size="small"
              error={directionMain === '' && directionMainAction}>Distrito</InputLabel>
              <Select
                labelId="distrito-select-label"
                id="distrito-select"
                value={distrito}
                label="Distrito"
                variant="outlined"
                size="small"
                name="distrito"
                error={directionMain === '' && directionMainAction}
                onChange={handleChange}
                onBlur={handleChange}
                style={{ marginBottom: '16px !important', width: '100%' }}
              >
                <MenuItem value={'Ancón'}>Ancón </MenuItem>
                <MenuItem value={'Ate'}>Ate </MenuItem>
                <MenuItem value={'Barranco'}>Barranco </MenuItem>
                <MenuItem value={'Breña'}>Breña </MenuItem>
                <MenuItem value={'Carabayllo'}>Carabayllo </MenuItem>
                <MenuItem value={'Cercado de Lima'}>Cercado de Lima </MenuItem>
                <MenuItem value={'Chaclacayo'}>Chaclacayo </MenuItem>
                <MenuItem value={'Chorrillos'}>Chorrillos </MenuItem>
                <MenuItem value={'Cieneguilla'}>Cieneguilla </MenuItem>
                <MenuItem value={'Comas'}>Comas </MenuItem>
                <MenuItem value={'El agustino'}>El agustino </MenuItem>
                <MenuItem value={'Independencia'}>Independencia </MenuItem>
                <MenuItem value={'Jesús maría'}>Jesús maría </MenuItem>
                <MenuItem value={'La molina'}>La molina </MenuItem>
                <MenuItem value={'La victoria'}>La victoria </MenuItem>
                <MenuItem value={'Lince'}>Lince </MenuItem>
                <MenuItem value={'Los olivos'}>Los olivos </MenuItem>
                <MenuItem value={'Lurigancho'}>Lurigancho </MenuItem>
                <MenuItem value={'Lurín'}>Lurín </MenuItem>
                <MenuItem value={'Magdalena del mar'}>Magdalena del mar </MenuItem>
                <MenuItem value={'Miraflores'}>Miraflores </MenuItem>
                <MenuItem value={'Pachacámac'}>Pachacámac </MenuItem>
                <MenuItem value={'Pucusana'}>Pucusana </MenuItem>
                <MenuItem value={'Pueblo libre'}>Pueblo libre </MenuItem>
                <MenuItem value={'Puente piedra'}>Puente piedra </MenuItem>
                <MenuItem value={'Punta hermosa'}>Punta hermosa </MenuItem>
                <MenuItem value={'Punta negra'}>Punta negra </MenuItem>
                <MenuItem value={'Rímac'}>Rímac </MenuItem>
                <MenuItem value={'San bartolo'}>San bartolo </MenuItem>
                <MenuItem value={'San borja'}>San borja </MenuItem>
                <MenuItem value={'San isidro'}>San isidro </MenuItem>
                <MenuItem value={'San Juan de Lurigancho'}>San Juan de Lurigancho </MenuItem>
                <MenuItem value={'San Juan de Miraflores'}>San Juan de Miraflores </MenuItem>
                <MenuItem value={'San Luis'}>San Luis </MenuItem>
                <MenuItem value={'San Martin de Porres'}>San Martin de Porres </MenuItem>
                <MenuItem value={'San Miguel'}>San Miguel </MenuItem>
                <MenuItem value={'Santa Anita'}>Santa Anita </MenuItem>
                <MenuItem value={'Santa María del Mar'}>Santa María del Mar </MenuItem>
                <MenuItem value={'Santa Rosa'}>Santa Rosa </MenuItem>
                <MenuItem value={'Santiago de Surco'}>Santiago de Surco </MenuItem>
                <MenuItem value={'Surquillo'}>Surquillo </MenuItem>
                <MenuItem value={'Villa el Salvador'}>Villa el Salvador </MenuItem>
                <MenuItem value={'Villa Maria del Triunfo'}>Villa Maria del Triunfo </MenuItem>
              </Select>
              <FormHelperText>{distrito === '' && distritoAction ? 'Distrito requerido' : ' '}</FormHelperText>
            </CssFormControl>
            {/* <CssTextField
              type={'text'}
              label="Distrito"
              variant="outlined"
              name="distrito"
              size='small'
              onChange={handleChange}
              onBlur={handleChange}
              error={distrito === '' && distritoAction}
              helperText={distrito === '' && distritoAction ? 'Distrito requerido' : ' '}
              value={distrito}
              style={{ marginBottom: '16px !important', width: '100%' }}
            /> */}
            <CssTextField
              type={'text'}
              label="Referencia"
              variant="outlined"
              name="referencia"
              size='small'
              onChange={handleChange}
              onBlur={handleChange}
              value={referencia}
              style={{ marginBottom: '16px', width: '100%' }}
            />
            <h3 style={
              {fontSize: 12, marginTop: 24, marginBottom: 16}
              }>Busque su dirección en el mapa (opcional)</h3>
            <PlacesAutocomplete setSelected={setSelected} setDirection={setDirection} />
          </div>

          <div style={{ height: '100%', width: '100%' }}>
            <GoogleMap
              mapContainerStyle={{ height: '100%' }}
              zoom={14}
              center={selected === null ? { lat: parseFloat(direcciones[direcciones.length - 1]?.lat), lng: parseFloat(direcciones[direcciones.length - 1]?.lng) } : selected}
              mapContainerClassName="map-container"
            >
              {selected && <Marker position={selected} />}
            </GoogleMap>

            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                className={classes.button}
                disabled={directionMain === '' || distrito === ''}
                onClick={() => {
                  document.body.style.overflowY = "visible"
                  let latitude = null;
                  let longitude = null;
                  if(selected) {
                    latitude = selected.lat;
                    longitude = selected.lng;
                  }
                  agregarDireccion(latitude, longitude, directionMain, direction, distrito, referencia)
                  setSelected(null)
                  setDirection(null)
                  setDirectionMain(null)
                  setReferencia(null)
                  setDistrito(null)
                }}>
                Agregar dirección seleccionada
              </Button>
            </div>
          </div>
        </>
      ) : (
        <div>Loading...</div>
      )}
    </>
  )
}

const PlacesAutocomplete = ({ setSelected, setDirection }) => {
  const {
    ready,
    value,
    setValue,
    suggestions: { status, data },
    clearSuggestions
  } = usePlacesAutocomplete({
    requestOptions: {
      componentRestrictions: {
        country: "pe",
      }
    }
  })

  const handleSelect = async (address) => {
    setValue(address, false)
    setDirection(address)
    clearSuggestions()

    const results = await getGeocode({ address })
    const { lat, lng } = await getLatLng(results[0])
    setSelected({ lat, lng })
  };

  return (
    <Combobox className="combobox" onSelect={handleSelect}>
      <ComboboxInput
        value={value}
        onChange={(e) => {
          sessionStorage.removeItem('upa')
          setSelected(null)
          setDirection(null)
          setValue(e.target.value)
        }}
        disabled={!ready}
        className="comboboxInput"
        placeholder="Ingrese una dirección"
      />
      <ComboboxPopover style={{ zIndex: 100 }}>
        <ComboboxList>
          {status === "OK" &&
            data.map(({ place_id, description }) => (
              <ComboboxOption key={place_id} value={description} />
            ))}
        </ComboboxList>
      </ComboboxPopover>
    </Combobox>
  )
}

export default Places