import React from 'react'
import CarritoSuccess from '../components/CarritoSuccess/CarritoSuccess'

const CarritoSuccessView = () => {

  return (
    <div className='articleView'>
        <CarritoSuccess/>
    </div>
  )
}

export default CarritoSuccessView