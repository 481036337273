import React, { createContext, useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import env from "react-dotenv";
import { GlobalContext } from "./GlobalStateContext";

const RecoveryContext = createContext('');

const RecoveryProvider = ({ children, props }) => {

    
  const { encodePetition } = useContext(GlobalContext)

  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(false)
  const [email, setEmail] = useState('')
  const [emailAction, setEmailAction] = useState(false)
  const [credentialsError, setCredentialsError] = useState(false)
  const [error, setError] = useState('')

  
  const handleChange = (evt) => {
    const { value } = evt.target
    setEmail(value)
    setEmailAction(true)
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      sendMessage();
    }
  };
  const sendMessage = () => {
    let details = { email }
    setIsLoading(true);
    fetch(env.API_URL+"/recovery/mail", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
      body: encodePetition(details)
    }).then( () => {
      Swal.fire({
        title: 'Exito',
        text: 'Se le ha enviado su nueva clave',
        icon: 'success'
      })
      navigate('/');
    }).catch( () => {
      setError('No se encontró usuario');
      setCredentialsError(true);
    }).finally( () => {
      setIsLoading(false);
    })
  }


  const object = {
    credentialsError,
    error,
    email,
    emailAction,
    isLoading,
    handleChange,
    handleKeyDown,
    sendMessage,
  };


  return (
      <RecoveryContext.Provider value={object}>{children}</RecoveryContext.Provider>
  );
};

export { RecoveryProvider };
export default RecoveryContext;
