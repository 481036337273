import { useState, useEffect, useContext } from "react"
import { GlobalContext } from "../../context/GlobalStateContext";
import PropietarioContext from '../../context/PropietarioContext';
import PlacesAutocomplete from "../PlacesAutocomplete/PlacesAutocomplete";
import { GoogleMap, MarkerF } from "@react-google-maps/api";
import { addAdress } from "../../servicios/item";


const AddAddressForm = ({setAddressModal, setNewAddressDataPerfil}) => {
  const { accessToken } = useContext(GlobalContext)
  const { propietario } = useContext(PropietarioContext)
  const [map, setMap] = useState(/** @type google.maps.Map */ (null))
  const [newAddressData, setNewAddressData] = useState({
    address: '',
    reference: '',
    coordinates: {
      lat: '',
      lng: ''
    }
  })
  const [errorNewAddressData, setErrorNewAddressData] = useState({
    address: '',
    reference: ''
  })
  const [loaded, setLoaded] = useState(false);

  const handleChangeAddressForm = (e) => {
    const { name, value } = e.target

    setNewAddressData({
      ...newAddressData, 
      [name] : value
    })

    setNewAddressDataPerfil({
      ...newAddressData, 
      [name] : value
    })
  }

  const validateForm = (types) => {
    const errors = {};
    setLoaded(true)
  
    types.forEach(type => {
      switch(type) {
        case 'address':
          if(!newAddressData.address) {
            errors.address = 'Dirección es requerida';
          } else {
            errors.address = ''
          }
          break;
        case 'reference':
          if(!newAddressData.reference) {
            errors.reference = 'Referencia es requerida';
          } else {
            errors.reference = ''
          }
          break;
        default:
      }
    });
  
    setErrorNewAddressData(prevErrors => ({
      ...prevErrors,
      ...errors
    }));

    const errorsVal = Object.values(errors).every(valor => valor === '');
    if(errorsVal) {
      return true
    } else {
      return false
    }
  };

  const handleAddAddress = async () => {
    if(newAddressData) {
      try {
        await addAdress(propietario?.idPropietario, accessToken, newAddressData.coordinates.lat, newAddressData.coordinates.lng, newAddressData.address, newAddressData.address,  newAddressData.reference);
        setAddressModal(false)
      } catch (error) {
        console.error("Error handling data:", error);
      }
    }
  }

  const handleNewAddressForm = () => {
    const isValidate = validateForm(['address','reference']);
    if(isValidate) {
      handleAddAddress()
    }
  }

  useEffect(() => {
    const toValidate = Object.keys(newAddressData).filter(key => newAddressData[key] !== '')
    if(loaded) {
      validateForm(toValidate)
    }
  }, [loaded, newAddressData])

  return(

    <div className='c-modal'>
      <div className='c-modal__content address'>
        <div className="d-flex align-items-center justify-content-between mb-3">
          <div className='c-modal__title me-3'>
            Agregar Nueva Dirección
          </div>
        </div>
        <div >
        <div className='c-form w-100'>
          <div className='row gy-3'>
            <div className='col-12'>
              <div className={`c-input-group ${errorNewAddressData.address ? 'error' : ''}`}>
                <label>Dirección</label>
                  <PlacesAutocomplete setNewAddressData={setNewAddressData} 
                  newAddressData={newAddressData} />
                <span className='c-error'>{errorNewAddressData.address}</span>
              </div>
            </div>
            <div className="col-12">
              <div className={`c-input-group ${errorNewAddressData.reference ? 'error' : ''}`}>
                <label>Referencia</label>
                <input 
                  name="reference"
                  value={newAddressData.reference} 
                  onChange={handleChangeAddressForm}
                  onBlur={handleChangeAddressForm}
                  type="text"  
                  placeholder='Referencia'/>
                <span className='c-error'>{errorNewAddressData.reference}</span>
              </div>
            </div>
              {/* {addressList && 
              } */}
                <div className="c-map">
                  <GoogleMap
                    mapContainerStyle={{ height: '100%' }}
                    zoom={14}
                    center={newAddressData.coordinates.lat ? newAddressData.coordinates : { lat: parseFloat(-12.0466888), lng: parseFloat(-77.04308859999999)} }
                    mapContainerClassName="map-container"
                    onLoad={map => setMap(map)}
                  >
                    {newAddressData.coordinates.lat && <MarkerF position={newAddressData.coordinates} />}
                  </GoogleMap>
              </div>
          </div>
        </div>
        </div>
      </div>
      <div className='c-modal__footer d-flex align-items-center justify-content-end'>
        <button className='c-button c-button--white-gray' onClick={() => setAddressModal(false)}>Cerrar</button>
        <button onClick={() => handleNewAddressForm()} className='c-button c-button--primary ms-3' >Agregar</button>
      </div>
    </div>
  )
}

export default AddAddressForm