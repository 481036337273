// import { ToastContainer } from 'react-toastify'
// import 'react-toastify/dist/ReactToastify.css'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faPen, faPlus, faArrowLeft } from '@fortawesome/free-solid-svg-icons'
// import { Button, CircularProgress, TextField } from '@mui/material'
// import React, { useContext, useEffect } from 'react'
// import { withStyles, makeStyles } from '@mui/styles'
// import { useNavigate } from 'react-router-dom'
// import { GlobalContext } from '../../../context/GlobalStateContext'
// import ImageCarousel from '../ImageCarousel/ImageCarousel'
// import { ClimbingBoxLoader } from 'react-spinners'
// import './ArticleContainer.css'
// import ArticleContext from '../../../context/ArticleContext'

// const CssTextField = withStyles({
//   root: {
//     '&.MuiFormControl-root': {
//       width: '100%'
//     },
//     '& label.Mui-focused': {
//       color: '#F94700',
//     },
//     '& .MuiInput-underline:after': {
//       borderBottomColor: '#F94700',
//     },
//     '& .MuiOutlinedInput-root': {
//       '& fieldset': {
//         borderColor: 'black'
//       },
//       '&:hover fieldset': {
//         borderColor: 'black'
//       },
//       '&.Mui-focused fieldset': {
//         borderColor: '#F94700',
//       },
//     },
//   },
// })(TextField);

// const useStyles = makeStyles({
//   button: {
//     backgroundColor: '#f94700 !important',
//     textTransform: 'none !important',
//     fontWeight: 'bold !important',
//     color: '#fff !important',
//     width: '150px',
//     margin: '15px 0 !important'
//   },
//   buttonWhite: {
//     backgroundColor: '#fff !important',
//     border: '1px solid #f94700 !important',
//     textTransform: 'none !important',
//     fontWeight: 'bold !important',
//     color: '#f94700 !important',
//     width: '150px',
//     margin: '15px 0 !important'
//   },
//   buttonSecondary: {
//     border: '1px solid #f94700 !important',
//     textTransform: 'none !important',
//     color: '#f94700 !important',
//     width: '100%'
//   },
//   buttonAction: {
//     backgroundColor: '#f94700 !important',
//     textTransform: 'none !important',
//     fontWeight: 'bold !important',
//     color: '#fff !important',
//     borderRadius: '4px',
//     display: 'flex',
//     justifyContent: 'center',
//     alignItems: 'center',
//     cursor: 'pointer'
//   }
// })

// const ArticleContainer = () => {

//   const navigate = useNavigate()
//   const classes = useStyles()

//   const { obtenerArticulo, id, articulo, handleCancelSell, handleDelete, handlePriceChange, handleSell, fotos, editTitle, setEditTitle, editDescription, setEditDescription, newTitle, setNewTitle, newDescription, setNewDescription, actualizarTitulo, actualizarDescripcion, isLoading, actualizarEstadoEnvio } = useContext(ArticleContext);
//   const { formatStrings, setLoaderState, change, setChange } = useContext(GlobalContext);

//   useEffect(() => {
//     obtenerArticulo(id)
//   }, [])

//   useEffect(() => {
//   }, [articulo])

//   return (
//     <div className='articleContainer'>
//       <div className='homeButton'>
//         <div
//           onClick={() => {
//             setChange(!change)
//             setLoaderState(0)
//             setTimeout(() => navigate(`/dashboard`), 500)
//           }}
//           className='volver'>
//           <FontAwesomeIcon className='volverIcon' icon={faArrowLeft} />
//           Volver
//         </div>
//       </div>

//       <div className='article'>
//         {fotos !== null && articulo !== null ? (
//           <>
//             <div className='images'>
//               <ImageCarousel fotos={fotos.map(el => {
//                 return {
//                   enlaceRef: el.enlaceRef.replaceAll(" ", "")
//                 }
//               })} fotoRecogida={articulo.ImagenRecogida} />
//             </div>
//             <div className="info">
//               <div className='titleContainer'>
//                 {editTitle ? (
//                   <div className='titleEdit'>
//                     <CssTextField
//                       id="outlined-basic"
//                       label="Nombre del artículo"
//                       variant="outlined"
//                       value={newTitle}
//                       onChange={(e) => setNewTitle(e.target.value)}
//                     />
//                     <div className='buttonsEdit'>
//                       <Button
//                         className={classes.buttonWhite}
//                         onClick={() => setEditTitle(false)}>
//                         Cancelar
//                       </Button>
//                       <Button
//                         className={classes.button}
//                         onClick={() => {
//                           if (!isLoading) actualizarTitulo(newTitle, setEditTitle)
//                         }}>
//                         {isLoading ? <CircularProgress style={{ color: '#fff', width: '20px', height: '20px' }} /> : 'Guardar'}
//                       </Button>
//                     </div>
//                   </div>
//                 ) : (
//                   <div className='title'>
//                     <h1>{formatStrings(articulo.Titulo)}</h1>
//                     <FontAwesomeIcon
//                       className='editIcon'
//                       onClick={() => {
//                         setNewTitle(formatStrings(articulo.Titulo))
//                         setEditTitle(true)
//                       }}
//                       icon={faPen} />
//                   </div>
//                 )}

//               </div>
//               <div className='caracteristicas'>
//                 <h3>Características</h3>
//                 <p>ID: {articulo.QRCode}</p>
//                 <p>Estado: {articulo.Conservacion !== null ? formatStrings(articulo.Conservacion) : false}</p>
//                 <p>Medidas: {`${articulo.MedidaLargo} x ${articulo.MedidaProfundidad} x ${articulo.MedidaAltura} metros`}</p>
//                 <p>Volumen: {articulo.Volumen}</p>
//                 <p>Materiales: {articulo.Material !== null ? formatStrings(articulo.Material) : false}</p>
//                 <p>Color: {articulo.Color !== null ? formatStrings(articulo.Color) : false}</p>
//               </div>
//               <div className='descripcion'>
//                 <h3>Descripción Storange:</h3>
//                 <p>{articulo.Descripcion !== null ? formatStrings(articulo.Descripcion) : false}</p>
//               </div>
//               <div className='propietario'>
//                 <h3>Descripcion propietario:</h3>
//                 {articulo.descripcionPropietario === null || editDescription ? (
//                   <>
//                     {editDescription ? (
//                       <div>
//                         <CssTextField
//                           id="outlined-basic"
//                           label="Añadir una descripción"
//                           variant="outlined"
//                           value={newDescription}
//                           onChange={(e) => setNewDescription(e.target.value)}
//                         />
//                         <div className='buttonsEdit'>
//                           <Button
//                             className={classes.buttonWhite}
//                             onClick={() => setEditDescription(false)}>
//                             Cancelar
//                           </Button>
//                           <Button
//                             className={classes.button}
//                             onClick={() => {
//                               if (!isLoading) actualizarDescripcion(newDescription, setEditDescription)
//                             }}>
//                             {isLoading ? <CircularProgress style={{ color: '#fff', width: '20px', height: '20px' }} /> : 'Guardar'}
//                           </Button>
//                         </div>
//                       </div>
//                     ) : (
//                       <Button
//                         className={classes.buttonSecondary}
//                         onClick={() => {
//                           setNewDescription(articulo.descripcionPropietario === null ? '' : articulo.descripcionPropietario)
//                           setEditDescription(true)
//                         }}>
//                         <FontAwesomeIcon className='plusIcon' icon={faPlus} />
//                         Añadir una descripción
//                       </Button>
//                     )}
//                   </>
//                 ) : (
//                   <div className='descripcionPropietario'>
//                     <p>{articulo.descripcionPropietario}</p>
//                     <FontAwesomeIcon
//                       className='editIcon'
//                       onClick={() => {
//                         setNewDescription(articulo.descripcionPropietario === null ? '' : articulo.descripcionPropietario)
//                         setEditDescription(true)
//                       }}
//                       icon={faPen} />
//                   </div>
//                 )}
//               </div>
//               <div className='gestion'>
//                 <h3>Gestión del artículo</h3>
//                 <div className='estado'>
//                   {articulo.idEstadoArticulo === 2 ? `${articulo.estadoArticulo}: S/${articulo.precio}` : articulo.estadoArticulo}
//                 </div>
//               </div>
//               <div className='acciones'>
//                 <h3>Acciones</h3>
//                 {articulo.idEstadoArticulo === 3 ? (
//                   <>El artículo se encuentra en proceso de envío</>
//                 ) : (
//                   <div className='buttons'>
//                     {articulo.estadoEnvio === 0 ? (
//                       <Button
//                         onClick={() => {
//                           if (!isLoading) actualizarEstadoEnvio(1, articulo.idArticulo)
//                         }}
//                         className={classes.buttonAction}>
//                         {isLoading ? <CircularProgress style={{ color: '#fff', width: '20px', height: '20px' }} /> : 'Seleccionar para envío'}
//                       </Button>
//                     ) : (
//                       <div className='secondaryButtons secondaryFix'>
//                         <Button
//                           onClick={() => {
//                             if (!isLoading) actualizarEstadoEnvio(0, articulo.idArticulo)
//                           }}
//                           className={classes.buttonAction}>
//                           {isLoading ? <CircularProgress style={{ color: '#fff', width: '20px', height: '20px' }} /> : 'Quitar del carrito'}
//                         </Button>
//                         <div
//                           onClick={() => {
//                             window.scrollTo(0, 0)
//                             setChange(!change)
//                             setLoaderState(0)
//                             setTimeout(() => navigate('/pickup_send'), 500)
//                           }}
//                           className={classes.buttonAction}>
//                           Ver carrito
//                         </div>
//                       </div>
//                     )}
//                     <div className='secondaryButtons'>
//                       {articulo.idEstadoArticulo === 2 ? (
//                         <Button
//                           onClick={handlePriceChange}
//                           className={classes.buttonAction}>
//                           Cambiar precio de venta
//                         </Button>
//                       ) : (
//                         <Button
//                           onClick={handleSell}
//                           className={classes.buttonAction}>
//                           Vender
//                         </Button>
//                       )}
//                       <Button
//                         onClick={handleDelete}
//                         className={classes.buttonAction}>
//                         {isLoading ? <CircularProgress style={{ color: '#fff', width: '20px', height: '20px' }} /> : 'Eliminar'}
//                       </Button>
//                     </div>
//                     {articulo.idEstadoArticulo === 2 ? (
//                       <div className='secondaryButtons'>
//                         <Button
//                           onClick={handleCancelSell}
//                           className={classes.buttonAction}>
//                           Cancelar venta
//                         </Button>
//                       </div>
//                     ) : false}
//                   </div>
//                 )}
//               </div>
//             </div>
//           </>
//         ) : (
//           <div className='loadingSpinner'>
//             <ClimbingBoxLoader size={20} color={'#F94700'} />
//             <h3>Cargando...</h3>
//           </div>
//         )}
//       </div>
//       <ToastContainer
//         position="bottom-right"
//         autoClose={1000}
//         hideProgressBar
//         theme='light'
//         newestOnTop={false}
//         closeOnClick
//         rtl={false}
//         pauseOnFocusLoss
//         draggable
//         pauseOnHover />
//     </div>
//   )
// }

// export default ArticleContainer

import React from 'react'
import { useState, useRef, useContext, useEffect } from 'react'
import env from "react-dotenv";
import ImageCarousel from '../ImageCarousel/ImageCarousel'
import EditCategoryCard from '../EditCategoryCard/EditCategorycard';
import useClickOutside from '../../../hooks/useClickOutside';
import { GlobalContext } from '../../../context/GlobalStateContext'


const ArticleContainer = () => {
  const { accessToken } = useContext(GlobalContext)
  const [editCategoryItem, setEditCategoryItem] = useState(false);
  const editaCategoryCardRef = useRef(null);
  const [isEditing, setIsEditing] = useState({ title: false, price: false, description: false });
  const [article, setArticle] = useState(null)
  const [newTitle, setNewTitle] = useState('')
  const [newDescription, setNewDescription] = useState('')
  const [newPrice, setNewPrice] = useState('')
  const [fotos, setFotos] = useState(
    [])

  useClickOutside(editaCategoryCardRef, () => setEditCategoryItem(false));

  const handleCategoriesEditClick = () => {
    setEditCategoryItem(!editCategoryItem);
  }

  const handleEditFormClick = ({ type, value, action }) => {
    setIsEditing({ ...isEditing, [type]: value });

    if( action === 'edit') {
      switch(type) {
        case 'title': 
          if(newTitle) {
            actualizarTitulo(newTitle);
          }
          break;
        case 'description':
          if(newDescription) {
            actualizarDescripcion(newDescription);
          }
          break;
        case 'price':
          if(newPrice) {
            actualizarPrecio(newPrice);
          }
          break;
        default:
      }
    } else {
      setNewTitle(article.Titulo)
      setNewDescription(article.descripcionPropietario)
      setNewPrice(article.precio)
    }
  };

  const encodePetition = (parameters, formBody = []) => {
    for (let property in parameters) {
      let encodedKey = encodeURIComponent(property)
      let encodedValue = encodeURIComponent(parameters[property])
      formBody.push(encodedKey + "=" + encodedValue)
    }
    return formBody.join("&")
  }

  const getArticle = (idArticulo) => {
    let details = { idArticulo }

    fetch(env.API_URL+"/fotos", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        "Authorization": "Bearer "+accessToken
      },
      body: encodePetition(details)
    })
      .then((res) => res.json())
      .then((res) => {
        setFotos(res)
      })

    fetch(env.API_URL+"/articuloPorId", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        "Authorization": "Bearer "+accessToken
      },
      body: encodePetition(details)
    })
      .then((res) => res.json())
      .then((res) => {
        setArticle(res)
        setNewTitle(res.Titulo)
        setNewDescription(res.descripcionPropietario)
        setNewPrice(res.precio ? res.precio : '0' )
      })
  }

  const actualizarTitulo = (newtitle) => {
    let details = { titulo: newtitle, idArticulo: article.idArticulo }

    fetch(env.API_URL+"/actualizarTitulo", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        "Authorization": "Bearer "+accessToken
      },
      body: encodePetition(details)
    })
      .then(() => {
        setArticle({ ...article, Titulo: newtitle })
      })
  }

  const actualizarDescripcion = (newDescription) => {
    let details = { descripcion: newDescription, idArticulo: article.idArticulo }

    fetch(env.API_URL+"/actualizarDescripcion", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        "Authorization": "Bearer "+accessToken
      },
      body: encodePetition(details)
    })
      .then(() => {
        setArticle({ ...article, descripcionPropietario: newDescription })
      })
  }

  const actualizarPrecio = (newPrice) => {
    let details = { precio: newPrice, idArticulo: article.idArticulo }

    fetch(env.API_URL+"/actualizarPrecio", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        "Authorization": "Bearer "+accessToken
      },
      body: encodePetition(details)
    })
      .then(() => {
        setArticle({ ...article, precio: newPrice })
      })
  }
  const addToCar = (car) => {
    const carNumber = parseInt(car);

    if(article.estadoEnvio === 0 || article.estadoEnvio !== carNumber ) {
        enviarACarrito(car)
        setArticle({ ...article, estadoEnvio: carNumber })
      } else if(article.estadoEnvio === carNumber ) {
        enviarACarrito('0')
        setArticle({ ...article, estadoEnvio: 0 })
      }
  }

  const enviarACarrito = (typeCart) => {
    let details = { idArticulo: article.idArticulo, typeCart }

    fetch(env.API_URL+"/updateCart", {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer "+accessToken
      },
      body: JSON.stringify(details)
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res)
      })
  }

  useEffect(() => {
    const idArticulo = window.location.pathname.split('/').filter(Boolean).pop();
    getArticle(idArticulo)
  }, []);

  return(
    <div className="c-article-single">
      <div className="container">
        {article && 
          <div className="row gy-4">
            <div className="col-12">
              <a href="/dashboard" className="c-return">
              <span data-icon="ep:back" className='iconify'></span> Regresar
              </a>
            </div>
            <div className="col-12 col-lg-6">
              <div className="c-article-single__gallery">
                <ImageCarousel fotos={fotos.map(el => {
                    return {
                      enlaceRef: el.enlaceRef.replaceAll(" ", "")
                    }
                  })} fotoRecogida={article.ImagenRecogida} />
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <div className="c-article-single__content d-flex flex-column">
                <div className="c-article-single__id ">
                  <span>
                    {article.QRCode}
                  </span>
                </div>
                <h1 className="c-article-single__title m-0">
                  {isEditing.title ? (<form className='c-edit-feature'>
                      <input 
                        value={newTitle}
                        onChange={(e) => setNewTitle(e.target.value)} 
                        type="text" 
                        className='w-100' />
                      <div className='c-edit-feature__button-group d-flex align-items-start mt-3'>
                        <button onClick={() => handleEditFormClick({ type: 'title', value: false, action: 'edit'  })}  className='c-button c-button--primary' type="button">Editar</button>
                        <button onClick={() => handleEditFormClick({ type: 'title', value: false,action: 'cancel' })} className='c-button c-button--primary-ghost'>Cancelar</button>
                      </div>
                    </form> 
                  ) : (
                    <div className='d-flex align-items-start justify-content-between'>
                      <span>
                        {article.Titulo}
                      </span> 
                      <button onClick={() => handleEditFormClick({ type: 'title', value: true })} className="c-edit">
                        <span data-icon="material-symbols:edit-outline" className='iconify'></span>
                      </button>
                    </div>
                  ) }
                </h1>
                <div className='c-line'>
                </div>

                {article &&
                  <div className="c-article-single__price">
                    {isEditing.price ? (<form className='c-edit-feature'>
                          <input 
                            type="number" 
                            className='w-100'
                            value={newPrice}
                            onChange={(e) => setNewPrice(e.target.value)}
                          />
                          <div className='c-edit-feature__button-group d-flex align-items-start mt-3'>
                            <button onClick={() => handleEditFormClick({ type: 'price', value: false, action :'edit' })}  className='c-button c-button--primary'>Editar</button>
                            <button onClick={() => handleEditFormClick({ type: 'price', value: false,action :'cancel'})} className='c-button c-button--primary-ghost'>Cancelar</button>
                          </div>
                        </form> 
                      ) : (
                        <div className='d-flex align-items-start justify-content-between'>
                          <span>
                            S/ {article.precio ? article.precio : 0 }
                          </span> 
                          <button onClick={() => handleEditFormClick({ type: 'price', value: true })} className="c-edit">
                            <span data-icon="material-symbols:edit-outline" className='iconify'></span>
                          </button>
                        </div>
                      )}
                    </div>
                }

                <div className='c-article-single__description'>
                  {isEditing.description ? (<form className='c-edit-feature'>
                        <input  
                          type="text" 
                          className='w-100'
                          value={newDescription}
                          onChange={(e) => setNewDescription(e.target.value)} 
                        />
                        <div className='c-edit-feature__button-group d-flex align-items-start mt-3'>
                          <button onClick={() => handleEditFormClick({ type: 'description', value: false,  action: 'edit' })}  className='c-button c-button--primary'>Editar</button>
                          <button onClick={() => handleEditFormClick({ type: 'description', value: false,  action: 'cancel' })} className='c-button c-button--primary-ghost'>Cancelar</button>
                        </div>
                      </form> 
                    ) : (
                    <div className='d-flex align-items-start justify-content-between'>
                      <p className='m-0'>{article.descripcionPropietario ? article.descripcionPropietario : 'Articulo sin descripción'}</p>

                      <button onClick={() => handleEditFormClick({ type: 'description', value: true })} className="c-edit">
                        <span data-icon="material-symbols:edit-outline" className='iconify'></span>
                      </button>
                    </div>
                  )}
                </div>

                <ul className='c-article-single__features d-flex flex-column m-0 mt-2 p-0'>
                  <li>
                    <div className='c-article-single__feature d-flex align-items-center justify-content-start'>
                      <div className='c-article-single__feature-label'>
                        <span data-icon="uil:estate" className='iconify'></span>
                        Estado:
                      </div>
                      <div className='c-article-single__feature-value'>
                        {article.Estado}
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className='c-article-single__feature d-flex align-items-center justify-content-start'>
                      <div className='c-article-single__feature-label'>
                        <span data-icon="radix-icons:size" className='iconify'></span>
                        Volumen:
                      </div>
                      <div className='c-article-single__feature-value'>
                        {article.Volumen}m<sup>3</sup>
                      </div>
                    </div>
                  </li>
                  {/* <li>
                    <div className='c-article-single__feature d-flex align-items-center justify-content-start'>
                      <div className='c-article-single__feature-label'>
                        <span data-icon="ic:outline-category" className='iconify'></span>
                        Categorias:
                      </div>
                      <div className='c-article-single__feature-value'>
                        {article.Categoria} 
                        <div ref={editaCategoryCardRef} className='d-inline position-relative'>
                          <button onClick={handleCategoriesEditClick} className='c-edit-btn position-relative'> 
                            <span data-icon="material-symbols:edit-outline" className='iconify'></span>
                          </button> 
                          {editCategoryItem && <EditCategoryCard />}
                        </div>
                      </div>
                    </div>
                  </li> */}
                </ul>
                {
                  article.estadoArticulo === 'Almacenado' ? (
                    <div className='c-article-single__button-group mt-3'>
                      <div className='row gy-3 gx-4'>
                      <div className='col-6'>
                          <button onClick={() => addToCar('1')} className={`c-button c-button--primary-ghost w-100  d-flex align-items-center justify-content-between ${article.estadoEnvio === 1 ? 'active' : ''}`}>
                            <div>
                              <span data-icon="mynaui:send" className='iconify'></span>
                              <span className='ms-2 d-inline-block'>Enviar</span> 
                            </div>
                            <div>
                              <span data-icon="tabler:plus" className='iconify unchecked'></span>
                              <span data-icon="maki:cross" className='iconify checked'></span>
                            </div>
                          </button>
                        </div>
                        <div className='col-6'>
                          <button onClick={() => addToCar('2')} className={`c-button c-button--primary-ghost w-100  d-flex align-items-center justify-content-between ${article.estadoEnvio === 2 ? 'active' : ''}`}>
                            <div>
                              <span data-icon="ph:shopping-cart" className='iconify'></span>
                              <span className='ms-2 d-inline-block'>Vender</span>  
                            </div>

                            <div>
                              <span data-icon="tabler:plus" className='iconify unchecked'></span>
                              <span data-icon="maki:cross" className='iconify checked'></span>
                            </div>
                          </button>
                        </div>
                        <div className='col-6'>
                          <button onClick={() => addToCar('3')} className={`c-button c-button--primary-ghost w-100  d-flex align-items-center justify-content-between ${article.estadoEnvio === 3 ? 'active' : ''}`}>
                            <div>
                              <span data-icon="mdi:trash-outline" className='iconify'></span>
                              <span className='ms-2 d-inline-block'>Eliminar</span> 
                            </div>
                            <div>
                              <span data-icon="tabler:plus" className='iconify unchecked'></span>
                              <span data-icon="maki:cross" className='iconify checked'></span>
                            </div>
                          </button>
                        </div>
                      </div>
                    </div>
                  ) : null}
              </div>
            </div>
          </div>
          }
      </div>
    </div>
  )
}

export default ArticleContainer

