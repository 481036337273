import React from 'react'
import { Button, CircularProgress } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useContext } from 'react'
import { GlobalContext } from '../../context/GlobalStateContext'
import CarritoDeEnvio from '../CarritoDeEnvio/CarritoDeEnvio'
import FormDireccion from '../FormDireccion/FormDireccion'
import Swal from 'sweetalert2'
import FormFecha from '../FormFecha/FormFecha'
import './FormEnvio.css'
import ArticleContext from '../../context/ArticleContext'
import OrdenContext from '../../context/OrdenContext'

const useStyles = makeStyles({
  button: {
    backgroundColor: '#f94700 !important',
    textTransform: 'none !important',
    fontWeight: 'bold !important',
    color: '#fff !important',
    width: '150px !important',
    height: 'fit-content !important'
  },
  buttonWhite: {
    backgroundColor: '#fff !important',
    border: '1px solid #f94700 !important',
    textTransform: 'none !important',
    fontWeight: 'bold !important',
    color: '#f94700 !important',
    width: '150px !important',
  }
})

const FormEnvio = () => {

  const classes = useStyles()

  const { carrito } = useContext(ArticleContext)

  const { oe, actualizarOrdenes, obtenerServicio, buscarOrdenes } = useContext(OrdenContext)

  const { webkitBrowser, browser, formEnvioPage, setFormEnvioPage, isLoading, setIsLoading } = useContext(GlobalContext)

  const sendData = async () => {
    setIsLoading(true)

    Swal.fire({
      title: 'Se realizo la orden de envío',
      icon: 'success'
    })

    obtenerServicio()
    actualizarOrdenes()
    buscarOrdenes()
  }

  return (
    <div className={`section fluxSection ${browser === webkitBrowser ? 'safari' : ''}`}>
      { formEnvioPage === 2
          ? <CarritoDeEnvio />
          : formEnvioPage === 1
            ? <FormDireccion />
            : formEnvioPage === 0
              ? <FormFecha />
              : (
                <div className={browser === webkitBrowser ? 'test safari' : 'test'}>
                  <div className="resumen">
                    <div className='formButtons'>
                      <Button onClick={() => setFormEnvioPage(0)} className={classes.buttonWhite}>
                        Atrás
                      </Button>
                    </div>
                    <div>
                      {carrito !== null && carrito.length > 0 ? (
                        <>
                          <p><span>m³ Totales: </span><span>{carrito.map(articulo => articulo.volumen).reduce((a, b) => parseFloat((a*1 + b*1).toFixed(4)))} m³</span></p>
                          <p><span>Dirección de envio: </span><span>{oe.direccionName}</span></p>
                          <p><span>Tipo de servicio: </span><span>Normal</span></p>
                          <p><span>Fecha de envio: </span><span>{oe.fecha}</span></p>
                          <p><span>Total a pagar: </span><span>S/.{carrito.map(articulo => articulo.volumen).reduce((a, b) => parseFloat((a*1 + b*1).toFixed(4))) < 1 ? 40 : carrito.map(articulo => articulo.volumen).reduce((a, b) => parseFloat((a*1 + b*1).toFixed(2))) * 40}</span></p>
                        </>
                      ) : (
                        <h1>Cargando</h1>
                      )}
                    </div>
                    <div>
                      <Button className={classes.button} onClick={sendData}>
                        {isLoading ? <CircularProgress style={{ width: '20px', height: '20px', color: '#fff' }} /> : 'Finalizar'}
                      </Button>
                    </div>
                  </div>
                </div>
              )
      }
    </div>
  )
}

export default FormEnvio