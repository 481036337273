import React, { useState, useContext, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import { GlobalContext } from '../../context/GlobalStateContext';
import PropietarioContext from '../../context/PropietarioContext';

import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { getAdressList, pickupOrder } from "../../servicios/item";

import { LocalizationProvider, StaticDatePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { TextField } from '@mui/material'


const OrderRecojo = () => {
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 180,
      },
    },
  };

  const isWeekend = (date) => {
    const day = date.day()
    return day === 0 || day === 6 || date.$d < new Date(Date.now())
  }
  const { accessToken } = useContext(GlobalContext);
  const { propietario } = useContext(PropietarioContext);

  const [addressList, setAddressList] = useState([])
  const [addressObject, setAddressObject] = useState({})

  const [formData, setFormData] = useState({
    message: '',
  });
  const [errorFormData, setErrorFormData] = useState({
    date: '',
    address: '',
    hour: '',
    message: '',
  });
  const [date, setDate] = useState(null)
  const [hour, setHour] = useState({})
  const [rangoList, setRangoList] = useState([
    {
      label: '9am - 12pm',
      value: '9am - 12pm'
    },
    {
      label: '12pm - 4pm',
      value: '12pm - 4pm'
    },
    {
      label: '4pm - 6pm',
      value: '4pm - 6pm'
    }
  ])
  const [loaded, setLoaded] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [finalButton, setFinalButton] = useState(true);

  const handleGetAdressList = async () => {
    try {
      const data = await getAdressList(propietario?.idPropietario, accessToken);
      setAddressList(data)
    } catch (error) {
      console.error("Error handling data:", error);
    }
  }

  const handlePickUpOrder = async () => {
    try {
      const data = await pickupOrder(accessToken, {
        idPropietario: propietario.idPropietario,
        fecha: date,
        direccion: addressObject.direccion,
        hora: hour.value,
        comentarios : formData.message
      });

      if(data === 'Orden de recojo creada exitosamente.') {
        setSuccessModal(true)
        clearAll()
        setFinalButton(true)
      } else {
        setErrorModal(true)
        setFinalButton(true)
      }
    } catch (error) {
      console.error("Error handling data:", error);
    }
  }

  const handleAddressChange = (event) => {
    const { target: { value }, } = event;
    setAddressObject(value)
  }

  const handleHourChange = (event) => {
    const { target: { value }, } = event;
    setHour(value)
  }

  const handleFormChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateForm = (fields) => {
    const errors = {};
    setLoaded(true);
    fields.forEach((field) => {
      switch (field) {
        case 'date':
          if (!date) {
            errors.date = 'Fecha es requerida';
          } else {
            errors.date = '';
          }
          break;
        case 'address':
          if (!(addressObject && Object.keys(addressObject).length > 0)) {
            errors.address = 'Dirección es requerida';
          } else {
            errors.address = '';
          }
          break;
        case 'hour':
          if (!(hour && Object.keys(hour).length > 0)) {
            errors.hour = 'La hora es requerida';
          } else {
            errors.hour = '';
          }
          break;
        case 'message':
          if (!formData.message) {
            errors.message = 'Mensaje es requerido';
          } else {
            errors.message = '';
          }
          break;
        default:
          break;
      }
    });

    setErrorFormData((prevErrors) => ({
      ...prevErrors,
      ...errors,
    }));
    return Object.values(errors).every((value) => value === '');
  };

  const handleForm = () => {
    const isValidate = validateForm(['date', 'address', 'hour', 'message']);
    if (isValidate) {
      console.log('Formulario válido', {
        date: date,
        address: addressObject,
        hour: hour,
        message: formData.message
      });
      try {
        setFinalButton(false)
        handlePickUpOrder()
      } catch {
        console.log(Error)
      }
    }
  };

  const clearAll = () => {
    setDate(null)
    setAddressObject({})
    setHour({})
    setFormData({
      message: '',
    })
    setErrorFormData({
      date: '',
      address: '',
      hour: '',
      message: '',
    })
  }

  useEffect(() => {
    handleGetAdressList()
  }, [propietario])

  useEffect(() => {
    if (loaded) {
      validateForm(['date', 'address', 'hour', 'message']);
    }
  }, [loaded, formData, date, addressObject, hour]);

  return (
    <div className="c-catalogo">
      <div className="container">
        <div className="d-lg-flex align-items-center justify-content-between mt-4 mb-4">
          <div>
            <h4 className="c-catalogo__subtitle">
              Recuerda que también puedes solicitar el recojo de tus artículos llamando al XXXX - XXXXX
            </h4>
            <h1 className="c-catalogo__title mb-lg-0">Orden de recojo</h1>
          </div>
        </div>
        <div className="c-stepList d-flex flex-column">
          <div className="c-step">
            <div className="d-flex align-items-center justify-content-start mb-1">
              <div className="c-step__number me-2">1</div>
              <span className="c-step__label">Completa el formulario</span>
            </div>
            <div className="c-step__description">Llena los campos del formulario par generar la orden</div>
            <div className="c-form w-100">
              <div className="row gy-3">
                <div className="col-12">
                  <div className={`c-input-group ${errorFormData.date ? 'error' : ''}`}>
                    <label>Fecha</label>
                    <div className="c-calendar">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <StaticDatePicker
                            // openTo="day"
                            value={date}
                            shouldDisableDate={isWeekend}
                            renderInput={(params) => <TextField {...params} />}
                            onChange={(newValue) => {
                              setDate(newValue.format('YYYY-MM-DD'))
                            }}
                        />
                      </LocalizationProvider>
                    </div>
                    <span className="c-error">{errorFormData.date}</span>
                  </div>
                </div>
                <div className="col-12">
                  <div className={`c-input-group ${errorFormData.address ? 'error' : ''}`}>
                    <label>Dirección</label>
                    <div className="c-select my-3">
                      <Select
                        displayEmpty
                        value={addressObject}
                        onChange={(event) => handleAddressChange(event)}
                        MenuProps={MenuProps}
                        IconComponent={ExpandMoreIcon}
                        renderValue={(selected) => {
                          if (!selected || Object.keys(selected).length === 0) {
                            return <span>Direcciones</span>;
                          }

                          return selected.direccion;
                        }}
                        inputProps={{ 'aria-label': 'Without label' }}
                      >
                        {addressList.map((state) => (
                          <MenuItem
                            key={state.idDireccion}
                            value={state}
                          >
                            {state.direccion}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                    <span className="c-error">{errorFormData.address}</span>
                  </div>
                </div>
                <div className="col-12">
                  <div className={`c-input-group ${errorFormData.hour ? 'error' : ''}`}>
                    <label>Hora</label>
                    <div className="c-select my-3">
                      <Select
                        displayEmpty
                        value={hour}
                        onChange={(event) => handleHourChange(event)}
                        MenuProps={MenuProps}
                        IconComponent={ExpandMoreIcon}
                        renderValue={(selected) => {
                          if (!selected || Object.keys(selected).length === 0) {
                            return <span>Selecciona una opción</span>;
                          }

                          return selected.value;
                        }}
                        inputProps={{ 'aria-label': 'Without label' }}
                      >
                        {rangoList.map((state, i) => (
                          <MenuItem
                            key={i}
                            value={state}
                          >
                            {state.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                    <span className="c-error">{errorFormData.hour}</span>
                  </div>
                </div>
                <div className="col-12">
                  <div className={`c-input-group ${errorFormData.message ? 'error' : ''}`}>
                    <label className='mb-3'>Mensaje</label>
                    <textarea
                      name="message"
                      value={formData.message}
                      onChange={handleFormChange}
                      onBlur={handleFormChange}
                      type="text"
                      placeholder="Escribe aquí"
                    ></textarea>
                    <span className="c-error">{errorFormData.message}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-end mt-3">
              <button
                disabled={!(finalButton && date && Object.keys(addressObject).length > 0 && Object.keys(hour).length > 0 && formData.message)}
                onClick={handleForm}
                className="c-button c-button--primary"
              >
                Programar recogida
              </button>
            </div>
          </div>
        </div>
      </div>

      <Dialog
        open={successModal}
        onClose={() => setSuccessModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className="c-modal c-modal--alert">
          <div className="c-modal__content">
            <div className="c-modal__icon c-modal__icon--success">
              <span data-icon="ep:success-filled" className="iconify"></span>
            </div>
            <div className="c-modal__title mb-3">Programación exitosa</div>
            <p className="c-modal__description">
              Tu recogida está programada, si tenemos alguna consulta te llamaremos.
            </p>
          </div>
          <div className="c-modal__footer d-flex align-items-center justify-content-end">
            <button className="c-button c-button--white-gray" onClick={() => setSuccessModal(false)}>
              Cerrar
            </button>
          </div>
        </div>
      </Dialog>
      <Dialog
        open={errorModal}
        onClose={() => setErrorModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className="c-modal c-modal--alert">
          <div className="c-modal__content">
            <div className="c-modal__icon c-modal__icon--error">
              <span data-icon="mingcute:alert-fill" className="iconify"></span>
            </div>
            <div className="c-modal__title mb-3">Error</div>
            <p className="c-modal__description">
              Hubo un error al programar la operación. Intentalo otra vez.
            </p>
          </div>
          <div className="c-modal__footer d-flex align-items-center justify-content-end">
            <button className="c-button c-button--white-gray" onClick={() => setErrorModal(false)}>
              Cerrar
            </button>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default OrderRecojo;