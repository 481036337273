import { Button } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons'
import React, { useState } from 'react'
import { makeStyles } from '@mui/styles'
import { useEffect } from 'react'
import { ToastContainer } from 'react-toastify'
import FormEnvio from '../FormEnvio/FormEnvio'
import { useContext } from 'react'
import { GlobalContext } from '../../context/GlobalStateContext'
import './RecogerEnviar.css'
import OrdenesEnProgreso from '../OrdenesEnProgreso/OrdenesEnProgreso'
import ArticleContext from '../../context/ArticleContext'
import OrdenContext from '../../context/OrdenContext'
import PropietarioContext from '../../context/PropietarioContext'

const useStyles = makeStyles({
    button: {
        backgroundColor: '#f94700 !important',
        textTransform: 'none !important',
        fontWeight: 'bold !important',
        color: '#fff !important',
        width: '200px !important',
        margin: '20px 0 !important',
        height: 'fit-content !important'
    },
    buttonWhite: {
        backgroundColor: '#fff !important',
        border: '1px solid #f94700 !important',
        textTransform: 'none !important',
        fontWeight: 'bold !important',
        color: '#f94700 !important',
        width: '150px !important',
        margin: '30px 20px !important'
    },
    buttonSecondary: {
        border: '1px solid #f94700 !important',
        textTransform: 'none !important',
        color: '#f94700 !important',
        width: '100% !important'
    },
    buttonBorder: {
        textTransform: 'none !important',
        color: '#383838 !important',
        width: 'calc(100% / 3)',
        backgroundColor: '#F1F1F1 !important',
        fontWeight: 'bold !important',
        borderRadius: '4px 4px 0 0 !important'
    },
    buttonBorderActive: {
        color: '#fff !important',
        fontWeight: 'bold !important',
        backgroundColor: '#f94700 !important',
        borderRadius: '4px 4px 0 0 !important'
    },
    root: {
        '&': {
            fontFamily: "'Poppins', sans-serif !important",
            color: '#757575 !important',
            fontSize: '14px !important'
        },
        '& .MuiSelect-select': {
            paddingLeft: '0 !important'
        },
        "&.Mui-focused": {
            border: "2px solid #f94700 !important"
        },
        "& .MuiOutlinedInput-notchedOutline": {
            border: "1px solid black !important"
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: "none !important"
        }
    }
})

/* const isWeekend = (date) => {
    const day = date.day()
    return day === 0 || day === 6
} */

const RecogerEnviarContainer = () => {

    const classes = useStyles()

    const { articulos, carrito, buscarArticulos } = useContext(ArticleContext)
    const { ordenesEnProgreso, buscarOrdenes } = useContext(OrdenContext)
    const { direcciones, buscarDireccion, buscarPropietario, propietario } = useContext(PropietarioContext)
    const { setActiveModal } = useContext(GlobalContext)

    const [buttonState, setButtonState] = useState(0)

    useEffect(() => {
        if (articulos === null) buscarArticulos()
    },[])

    useEffect(() => {
        if (direcciones === null) buscarDireccion()
    }, [])
    
    useEffect(() => {
        if (propietario === null || localStorage.getItem('trustedUser')) buscarPropietario()
    }, [])

    useEffect(() => {
        if (ordenesEnProgreso === null) buscarOrdenes()
    }, [])

    return (
        <div className='recogerEnviarContainer'>
            <div className='navbarRecoger'>
                <Button onClick={() => setButtonState(0)} className={buttonState === 0 ? `${classes.buttonBorder} ${classes.buttonBorderActive}` : classes.buttonBorder}>Carrito de envío</Button>
                <Button onClick={() => setButtonState(1)} className={buttonState === 1 ? `${classes.buttonBorder} ${classes.buttonBorderActive}` : classes.buttonBorder}>Ordenes en progreso</Button>
                <Button onClick={() => setButtonState(2)} className={buttonState === 2? `${classes.buttonBorder} ${classes.buttonBorderActive}` : classes.buttonBorder}>Solicitar recogida</Button>
            </div>
            <div className='sectionRecoger'>
                {
                    buttonState === 0 && (
                        carrito !== null && carrito.length > 0 ? (
                            <FormEnvio />
                        ) : (
                            <div className="section">
                                <div className='alert carritoDeEnvio'>
                                    <div className='border'></div>
                                    <div className='icon'>
                                        <FontAwesomeIcon className='alertIcon' icon={faCircleExclamation} />
                                    </div>
                                    <div className='text'>No hay artículos agregados al carrito</div>
                                    <Button onClick={() => {
                                        document.querySelector('.carritoModal').style.top = `${window.scrollY}px`
                                        document.body.style.overflowY = "hidden"
                                        setActiveModal(true)
                                    }} className={classes.button}>
                                        Agregar
                                    </Button>
                                </div>
                            </div>
                        )
                    )
                }
                {
                    buttonState === 1 && (
                        <OrdenesEnProgreso />
                    )
                }
                {
                    buttonState === 2 && (
                        <div className="section" style={{justifyContent: 'center'}}>
                            <div className='alert'>
                                <div className='border'></div>
                                <div className='icon'>
                                    <FontAwesomeIcon className='alertIcon' icon={faCircleExclamation} />
                                </div>
                                <div className='text'>Para solicitar una recogida de artículos, comunicarse por WhatsApp con el número 951612957</div>
                            </div>
                        </div>
                    )
                }
            </div>
            <div className='footerRecoger'>
                Cualquier duda o consulta por favor comuníquese con nuestra área de atención al cliente por WhatsApp al teléfono 951612957
            </div>
            <ToastContainer
                position="bottom-right"
                autoClose={1000}
                hideProgressBar
                theme='light'
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover />
        </div >
    )
}

export default RecogerEnviarContainer